import { useRef } from "react";
import toast from "react-hot-toast";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";

const useRecording = () => {
	const mediaRecorderRef = useRef(null);
	const gumStream = useRef(null);
	const audioContextRef = useRef(null); // Use a ref for audio context

	const startRecording = async (setFromOpen, setLoading) => {
		try {
			gumStream.current = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});

			mediaRecorderRef.current = new RecordRTC(gumStream.current, {
				type: "audio",
				mimeType: "audio/wav",
				recorderType: StereoAudioRecorder,
				numberOfAudioChannels: 1,
				desiredSampRate: 34100,
				bufferSize: 2048,
				disableLogs: true,
			});

			mediaRecorderRef.current.startRecording();


			setFromOpen(true); 
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setFromOpen(false);

			stopRecording();
			toast.error(
				"Microphone access denied. Go to settings and enable microphone permissions."
			);
		}
	};

	const stopRecording = () => {
		mediaRecorderRef?.current?.stopRecording(async () => {
			gumStream.current.getAudioTracks().forEach((track) => track.stop());

			if (audioContextRef.current) {
				audioContextRef.current.close(); // Ensure audio context is closed
				audioContextRef.current = null;
			}
		});
	};

	const setFormData = (formData) => {
		// clearMicTestInterval(audioMonitoringIntervalRef.current);

		return new Promise((resolve, reject) => {
			mediaRecorderRef?.current?.stopRecording(async () => {
				gumStream.current.getAudioTracks().forEach((track) => track.stop());

				const audioBlob = mediaRecorderRef.current.getBlob();

				try {
					const reader = new FileReader();
					reader.readAsDataURL(audioBlob);

					reader.onloadend = () => {
						const audioDataURL = reader.result;
						formData.append("recording", audioDataURL);
						resolve(formData);
					};

					reader.onerror = () => {
						reject(new Error("An error occurred while reading the audio blob"));
					};
				} catch (error) {
					toast.error("An error occurred while recording");
					reject(error);
				}
			});
		});
	};

	return {
		startRecording,
		stopRecording,
		setFormData,
	};
};

export default useRecording;
