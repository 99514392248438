/* eslint-disable no-mixed-spaces-and-tabs */
import { mandals } from "./mandals";
// import { villages } from "./villages";
import { statesAndDistricts } from "../data/stateAndDistrict";

export const surveyForms = {
	130: [
		{
			type: "TextField",
			id: "name",
			question: "Respondent Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
		},

		{
			type: "NumField",
			id: "contact",
			question: "Respondent Phone Number",
			errorMsg: "Please enter phone Number",
			placeholder: "Enter contact number",
			isPhoneNumber: true,
		},

		{
			type: "NumField",
			id: "boothNumber",
			question: "Booth Number",
			placeholder: "Enter Booth number",
			errorMsg: "Please Enter a booth number",
			isPhoneNumber: false,
			max: 300,
		},

		{
			type: "FreeField",
			id: "village",
			question: "Village/Ward",
			errorMsg: "Please Enter Village/Ward",
			placeholder: "Enter village/Ward",
		},

		{
			type: "AutoComplete",
			id: "mandal",
			question: "Mandal",
			options: ["Select", ...mandals],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "gender",
			question: "Gender",
			options: ["Male", "Female", "Prefer not to say", "other"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "ageGroup",
			question: "Age group",
			options: ["18-30", "31-40", "41-50", "51-60", "60+"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "caste",
			question: "Caste",
			options: [
				"B.C",
				"O.C",
				"S.T",
				"S.C",
				"Christian",
				"Muslim",
				"Kurni",
				"Boya",
				"Other",
				"Not answered",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "subCaste",
			question: "Sub Caste",
			options: [
				"Reddy",
				"Kamma",
				"Velama",
				"Vysya / Komati",
				"Brahmin",
				"Mudiraju",
				"Yadav / Golla",
				"Setti Balija",
				"Goud",
				"Padmashali",
				"SC Mala",
				"SC Madhiga",
				"ST Lambadi",
				"ST(Others)",
				"Christian",
				"Muslim",
				"BC - B",
				"Other",
				"Not answered",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "eduQualification",
			question: "Education Qualification",
			options: ["10th Class", "Intermediate", "Degree", "PG", "Uneducated"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "occupation",
			question: "Occupation",
			options: [
				"Farmer",
				"Shop Owner",
				"Student",
				"Tenant Farmer",
				"Daily wage worker",
				"Political Worker",
				"Private Employee",
				"Goverment Employee",
				"Doctor",
				"Journalist",
				"Unemployed",
				"Teacher",
				"Private Teacher",
				"SHG Worker",
				"Anganwadi Woker",
				"House wife / House husband",
				"Auto / Taxi Driver",
				"Priest",
				"Retired",
				"Others",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "DependentSelect",
			id: "support",
			question: "Which party do you support?",
			options: ["TDP", "YSRCP", "JSP", "BJP", "Not decided yet"],
			errorMsg: "Please select an option",
			subQuestions: [
				{
					id: "reason",
					question: "Reason for voting TDP Party",
					options: [
						"TDP party",
						"Looking at the MLA candidate",
						"Seeing Chandrababu Naidu",
						"An earlier development",
						"Government welfare schemes",
						"Opposition to Vice - RCP MLA",
						"Other reasons",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "Reason for voting YSRCP Party",
					options: [
						"YSRCP Party",
						"Looking at the MLA candidate",
						"Seeing CM Jagan",
						"Development programs",
						"Government welfare schemes",
						"Other reasons",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote for JSP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote for BJP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
			],
		},

		{
			type: "Select",
			id: "MLAperfomance",
			question: "How is present MLA",
			options: ["Very good", "Good", "Average", "Bad", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "whichCandidateWillWin",
			question: "Which candidate will win in the upcoming elections?",
			options: [
				"Putha Narasimha Reddy",
				"⁠Pochimareddy Ravindranath Reddy",
				"Don't know",
				"Others",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "whoWillWin",
			question:
				"In the upcoming elections which party do you think will win in this Kamalapuram constituency",
			options: ["TDP", "YSRCP", "JSP", "The BJP", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "FreeField",
			id: "demand",
			question: "What is your one wish or demand from the government?",
			placeholder: "Enter demand",
			errorMsg: 'Type "none" if voter has no demand',
		},

		{
			type: "Select",
			id: "whichPartyWillWin",
			question:
				"Which party do you think will come to power in the state in the upcoming elections?",
			options: ["TDP + JSP", "YSRCP", "BJP", "Tough Fight", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "voted2019",
			question: "Which party did you vote for in the last 2019 elections?",
			options: ["TDP", "YSRCP", "JSP", "BJP", "NOTA", "Did not vote"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "typeOfPerson",
			question: "Type of person?",
			options: ["Political", "Non-political"],
			errorMsg: "Please select an option",
		},
	],
	144: [
		{
			type: "TextField",
			id: "name",
			question: "Respondent Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
		},

		{
			type: "NumField",
			id: "contact",
			question: "Respondent Phone Number",
			errorMsg: "Please enter phone Number",
			placeholder: "Enter contact number",
			isPhoneNumber: true,
			maxLength: 10,
		},

		{
			type: "NumField",
			id: "boothNumber",
			question: "Booth Number",
			placeholder: "Enter Booth number",
			errorMsg: "Please Enter a booth number",
			isPhoneNumber: false,
			max: 300,
		},

		{
			type: "FreeField",
			id: "village",
			question: "Village/Ward",
			errorMsg: "Please Enter Village/Ward",
			placeholder: "Enter village/Ward",
		},

		{
			type: "AutoComplete",
			id: "mandal",
			question: "Mandal",
			options: ["Select", ...mandals],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "gender",
			question: "Gender",
			options: ["Male", "Female", "Prefer not to say", "other"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "ageGroup",
			question: "Age group",
			options: ["18-30", "31-40", "41-50", "51-60", "60+"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "caste",
			question: "Caste",
			options: [
				"B.C",
				"O.C",
				"S.T",
				"S.C",
				"Christian",
				"Muslim",
				"Kurni",
				"Boya",
				"Other",
				"Not answered",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "subCaste",
			question: "Sub Caste",
			options: [
				"Reddy",
				"Kamma",
				"Velama",
				"Vysya / Komati",
				"Brahmin",
				"Mudiraju",
				"Yadav / Golla",
				"Setti Balija",
				"Goud",
				"Padmashali",
				"SC Mala",
				"SC Madhiga",
				"ST Lambadi",
				"ST(Others)",
				"Christian",
				"Muslim",
				"BC - B",
				"Other",
				"Not answered",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "eduQualification",
			question: "Education Qualification",
			options: ["10th Class", "Intermediate", "Degree", "PG", "Uneducated"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "occupation",
			question: "Occupation",
			options: [
				"Farmer",
				"Shop Owner",
				"Student",
				"Tenant Farmer",
				"Daily wage worker",
				"Political Worker",
				"Private Employee",
				"Goverment Employee",
				"Doctor",
				"Journalist",
				"Unemployed",
				"Teacher",
				"Private Teacher",
				"SHG Worker",
				"Anganwadi Woker",
				"House wife / House husband",
				"Auto / Taxi Driver",
				"Priest",
				"Retired",
				"Others",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "DependentSelect",
			id: "support",
			question: "Which party do you support?",
			options: ["TDP", "YSRCP", "JSP", "BJP", "Not decided yet"],
			errorMsg: "Please select an option",
			subQuestions: [
				{
					id: "reason",
					question: "Reason for voting TDP Party",
					options: [
						"TDP party",
						"Looking at the MLA candidate",
						"Seeing Chandrababu Naidu",
						"An earlier development",
						"Government welfare schemes",
						"Opposition to Vice - RCP MLA",
						"Other reasons",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "Reason for voting YSRCP Party",
					options: [
						"YSRCP Party",
						"Looking at the MLA candidate",
						"Seeing CM Jagan",
						"Development programs",
						"Government welfare schemes",
						"Other reasons",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote for JSP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote for BJP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
			],
		},

		{
			type: "Select",
			id: "MLAperfomance",
			question: "How is present MLA",
			options: ["Very good", "Good", "Average", "Bad", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "whichCandidateWillWin",
			question: "Which candidate will win in the upcoming elections?",
			options: ["Butta Renuka", "B V Nageshwar Reddy", "Don't know", "Others"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "whoWillWin",
			question:
				"In the upcoming elections which party do you think will win in this Yemmiganur constituency",
			options: ["TDP", "YSRCP", "JSP", "The BJP", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "FreeField",
			id: "demand",
			question: "What is your one wish or demand from the government?",
			placeholder: "Enter demand",
			errorMsg: 'Type "none" if he/she has no demand',
		},

		{
			type: "Select",
			id: "whichPartyWillWin",
			question:
				"Which party do you think will come to power in the state in the upcoming elections?",
			options: ["TDP + JSP", "YSRCP", "BJP", "Tough Fight", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "voted2019",
			question: "Which party did you vote for in the last 2019 elections?",
			options: ["TDP", "YSRCP", "JSP", "BJP", "NOTA", "Did not vote"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "typeOfPerson",
			question: "Type of person?",
			options: ["Political", "Non-political"],
			errorMsg: "Please select an option",
		},
	],
	136: [
		{
			type: "TextField",
			id: "name",
			question: "Respondent Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
		},

		{
			type: "NumField",
			id: "contact",
			question: "Respondent Phone Number",
			errorMsg: "Please enter phone Number",
			placeholder: "Enter contact number",
			isPhoneNumber: true,
		},

		{
			type: "NumField",
			id: "boothNumber",
			question: "Booth Number",
			placeholder: "Enter Booth number",
			errorMsg: "Please Enter a booth number",
			isPhoneNumber: false,
			max: 300,
		},

		{
			type: "FreeField",
			id: "village",
			question: "Village/Ward",
			errorMsg: "Please Enter Village/Ward",
			placeholder: "Enter village/Ward",
		},

		{
			type: "AutoComplete",
			id: "mandal",
			question: "Mandal",
			options: ["Select", ...mandals],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "gender",
			question: "Gender",
			options: ["Male", "Female", "Prefer not to say", "other"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "ageGroup",
			question: "Age group",
			options: ["18-30", "31-40", "41-50", "51-60", "60+"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "caste",
			question: "Caste",
			options: [
				"B.C",
				"O.C",
				"S.T",
				"S.C",
				"Christian",
				"Muslim",
				"Kurni",
				"Boya",
				"Other",
				"Not answered",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "subCaste",
			question: "Sub Caste",
			options: [
				"Reddy",
				"Kamma",
				"Velama",
				"Vysya / Komati",
				"Brahmin",
				"Mudiraju",
				"Yadav / Golla",
				"Setti Balija",
				"Goud",
				"Padmashali",
				"SC Mala",
				"SC Madhiga",
				"ST Lambadi",
				"ST(Others)",
				"Christian",
				"Muslim",
				"BC - B",
				"Other",
				"Not answered",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "eduQualification",
			question: "Education Qualification",
			options: ["10th Class", "Intermediate", "Degree", "PG", "Uneducated"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "occupation",
			question: "Occupation",
			options: [
				"Farmer",
				"Shop Owner",
				"Student",
				"Tenant Farmer",
				"Daily wage worker",
				"Political Worker",
				"Private Employee",
				"Goverment Employee",
				"Doctor",
				"Journalist",
				"Unemployed",
				"Teacher",
				"Private Teacher",
				"SHG Worker",
				"Anganwadi Woker",
				"House wife / House husband",
				"Auto / Taxi Driver",
				"Priest",
				"Retired",
				"Others",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "DependentSelect",
			id: "support",
			question: "Which party do you support?",
			options: ["TDP", "YSRCP", "JSP", "BJP", "Not decided yet"],
			errorMsg: "Please select an option",
			subQuestions: [
				{
					id: "reason",
					question: "Reason for voting TDP Party",
					options: [
						"TDP party",
						"Looking at the MLA candidate",
						"Seeing Chandrababu Naidu",
						"An earlier development",
						"Government welfare schemes",
						"Opposition to Vice - RCP MLA",
						"Other reasons",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "Reason for voting YSRCP Party",
					options: [
						"YSRCP Party",
						"Looking at the MLA candidate",
						"Seeing CM Jagan",
						"Development programs",
						"Government welfare schemes",
						"Other reasons",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote for JSP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote for BJP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
				{
					id: "reason",
					question: "What factors do you consider when you vote?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					errorMsg: "Please select an option",
				},
			],
		},

		{
			type: "Select",
			id: "MLAperfomance",
			question: "How is present MLA",
			options: ["Very good", "Good", "Average", "Bad", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "whichCandidateWillWin",
			question: "Which candidate will win in the upcoming elections?",
			options: ["Jaya Surya", "Sudheer Babu", "Don't know", "Others"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "whoWillWin",
			question:
				"In the upcoming elections which party do you think will win in this Nandikotkur constituency",
			options: ["TDP", "YSRCP", "JSP", "The BJP", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "FreeField",
			id: "demand",
			question: "What is your one wish or demand from the government?",
			placeholder: "Enter demand",
			errorMsg: 'Type "none" if he/she has no demand',
		},

		{
			type: "Select",
			id: "whichPartyWillWin",
			question:
				"Which party do you think will come to power in the state in the upcoming elections?",
			options: ["TDP + JSP", "YSRCP", "BJP", "Tough Fight", "Don't know"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "voted2019",
			question: "Which party did you vote for in the last 2019 elections?",
			options: ["TDP", "YSRCP", "JSP", "BJP", "NOTA", "Did not vote"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "typeOfPerson",
			question: "Type of person?",
			options: ["Political", "Non-political"],
			errorMsg: "Please select an option",
		},
	],
	140: [
		{
			type: "AutoComplete",
			id: "boothNumber",
			question: "Booth Number",
			options: ["Select", ...Array.from(Array(277), (_, i) => String(i + 1))],
			errorMsg: "Please select an option",
			isNum: true,
		},

		{
			type: "TextField",
			id: "name",
			question: "Name (ఓటర్ యొక్క పేరు)",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
		},

		{
			type: "NumField",
			id: "contact",
			question: "Mobile Number (ఫోన్ నెంబరు)",
			errorMsg: "Please enter phone Number",
			placeholder: "Enter contact number",
			isPhoneNumber: true,
		},

		{
			type: "FreeField",
			id: "epic",
			question: "EPIC Serial number (EPIC క్రమ సంఖ్య)",
			placeholder: "Enter EPIC Serial Number",
			errorMsg: "Please Enter a EPIC number",
		},

		{
			type: "AutoComplete",
			id: "mandal",
			question: "Mandal/Municipality",
			options: ["Select", ...mandals],
			errorMsg: "Please select an option",
			// isAutoComplete: true,
			// subQuestions: [
			// 	{
			// 		id: "village",
			// 		question: "Village",
			// 		options: villages,
			// 		errorMsg: "Please select a village",
			// 		isAutoComplete: true,
			// 	},
			// ],
		},

		{
			type: "Select",
			id: "gender",
			question: "Gender (లింగం)",
			options: [
				"Male (పురుషుడు)",
				"Female (స్త్రీ)",
				"Dont want to Answer (సమాధానం చెప్పాలనుకోవడం లేదు)",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "ageGroup",
			question: "Age group (వయసు)",
			options: ["18-30", "31-45", "46-60", "60-85", "Above 85 (85 పైన)"],
			errorMsg: "Please select an option",
		},

		{
			type: "Select",
			id: "support",
			question:
				"Which party do you support?  (మీరు ఏ పార్టీకి మద్దతు ఇస్తారు?)",
			options: [
				"TDP (టీడీపీ)",
				"YSRCP (వైస్సార్సీపీ)",
				"JSP (జనసేన)",
				"BJP (బీజేపీ)",
				"Not Decided Yet (ఇంకా నిర్ణయించలేదు)",
			],
			errorMsg: "Please select an option",
		},

		{
			type: "DependentSelect",
			id: "isVoterLocal",
			question:
				"Is the voter available locally?  (ఓటరు స్థానికంగా అందుబాటులో ఉన్నారా?)",
			options: ["Yes (అవును)", "No (కాదు)"],
			errorMsg: "Please select an option",
			subQuestions: [
				{
					type: null,
				},
				{
					type: "FreeField",
					id: "stayingIn",
					question:
						"If voter is not available locally, where is the voter at present?  (స్థానికంగా ఓటరు అందుబాటులో లేకుంటే ప్రస్తుతం ఓటరు ఎక్కడున్నారు?)",
					errorMsg: "Field can not be empty",
					placeholder: "Enter the area respondent in",
				},
			],
		},
	],
};

export const voterForms = {
	144: [
		{
			type: "TextField",
			id: "name",
			question: "Respondent Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
			disabled: true,
		},

		{
			type: "TextField",
			id: "voterId",
			question: "Respondent Voter ID",
			errorMsg: "Please enter a Voter ID",
			placeholder: "Enter Voter ID",
			disabled: true,
		},

		{
			type: "TextField",
			id: "fathersName",
			question: "Respondent Fathers Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter fathers name",
			disabled: true,
		},

		{
			type: "TextField",
			id: "mothersName",
			question: "Respondent Mothers Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter mothers name",
			disabled: true,
		},

		{
			type: "TextField",
			id: "mothersName",
			question: "Respondent Mothers Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter mothers name",
			disabled: true,
		},

		{
			type: "TextField",
			id: "husbandsName",
			question: "Respondent Husbands Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter husbands name",
			disabled: true,
		},

		{
			type: "TextField",
			id: "othersName",
			question: "Respondent Others Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
			disabled: true,
		},

		{
			type: "TextField",
			id: "othersName",
			question: "Respondent Others Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter name",
			disabled: true,
		},

		{
			type: "NumField",
			id: "contact",
			question: "Respondent Phone Number",
			errorMsg: "Please enter phone Number",
			placeholder: "Enter contact number",
			isPhoneNumber: true,
		},
	],
};

export const adminForm = (data) => {
	return [
		{
			type: "TextField",
			id: "name",
			question: "Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter a name",
			defaultValue: data?.user?.name,
		},
		{
			type: "NumField",
			id: "mobile",
			question: "Mobile Number",
			errorMsg: "Please enter a mobile number",
			placeholder: "Enter a mobile number",
			isPhoneNumber: true,
			maxLength: 10,
			defaultValue: data?.user?.mobile,
		},
		{
			type: "ChipSelect",
			id: "constituencies",
			question: "Accessible Constituencies",
			options: data?.constituencies || [],
			errorMsg: "Please select an option",
			defaultValue: data?.user?.assigned.constituencies,
		},
	];
};

export const agentForm = (data) => {
	return [
		{
			type: "TextField",
			id: "name",
			question: "Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter a name",
			defaultValue: data?.user?.name,
		},
		{
			type: "NumField",
			id: "mobile",
			question: "Mobile Number",
			errorMsg: "Please enter a mobile number",
			placeholder: "Enter a mobile number",
			isPhoneNumber: true,
			maxLength: 10,
			defaultValue: data?.user?.mobile,
		},
		{
			type: "DependentSelect",
			subType: "ChipSelect",
			id: "pollingStations",
			question: "Accessible Polling Station",
			errorMsg: "Please select an option",
			options: data.pollingStations,
			defaultValue: data?.user?.assigned.pollingStations,
			max: 1,
			subQuestions: [
				{
					type: "ChipSelect",
					id: "sections",
					question: "Accesssible Section",
					options: data?.sections || [],
					defaultValue: data?.user?.assigned.sections,
				},
			],
		},
	];
};

export const basicSurveyAgentForm = (data) => {
	return [
		{
			type: "TextField",
			id: "name",
			question: "Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter a name",
			defaultValue: data?.user?.name,
		},
		{
			type: "NumField",
			id: "mobile",
			question: "Mobile Number",
			errorMsg: "Please enter a mobile number",
			placeholder: "Enter a mobile number",
			isPhoneNumber: true,
			maxLength: 10,
			defaultValue: data?.user?.mobile,
		},
		{
			type: "ChipSelect",
			id: "pollingStations",
			question: "Accessible Polling Stations",
			options: data?.pollingStations || [],
			errorMsg: "Please select an option",
			defaultValue: data?.user?.assigned.pollingStations,
		},
	];
};

export const voterSurveyForm = (data, ans) => {
	const fixedQuestions = [
		{
			type: "DependentSelect",
			subType: "Select",
			id: "fieldsCorrect",
			question: "Are the above voter details correct?",
			errorMsg: "Please select an option",
			options: ["Yes", "No"],
			subQuestions: {
				No: {
					type: "DependentChipSelect",
					id: "incorrectFields",
					question: "Which of the above fields are incorrect?",
					options: [
						"Name",
						"Voter ID",
						(data.fathersName && "Fathers Name") ||
							(data.mothersName && "Mothers Name") ||
							(data.husbandsName && "Husbands Name") ||
							(data.others && "Others"),
						"Age",
						"Gender",
						"House Number",
					],
					errorMsg: "Please select an option",
					subQuestions: {
						Name: {
							type: "TextField",
							id: "corrected.Name",
							question: "What is the correct name?",
							placeholder: "Enter correct name",
							errorMsg: "Please enter the correct name",
						},
						"Voter ID": {
							type: "FreeField",
							id: "corrected.Voter ID",
							question: "What is the correct voter ID?",
							placeholder: "Enter a voter ID",
							errorMsg: "Please enter the correct voter ID",
						},
						[(data.fathersName && "Fathers Name") ||
						(data.mothersName && "Mothers Name") ||
						(data.husbandsName && "Husbands Name") ||
						(data.others && "Others")]: {
							type: "TextField",
							id: `corrected.${
								(data.fathersName && "Fathers Name") ||
								(data.mothersName && "Mothers Name") ||
								(data.husbandsName && "Husbands Name") ||
								(data.others && "Others")
							}`,
							question: `What is the correct ${
								(data.fathersName && "fathers") ||
								(data.mothersName && "mothers") ||
								(data.husbandsName && "husbands") ||
								(data.others && "others")
							} name?`,
							placeholder: "Enter a name",
							errorMsg: "Please enter the correct name",
						},
						Age: {
							type: "NumField",
							id: "corrected.Age",
							question: "What is the correct age?",
							placeholder: "Enter a age",
							errorMsg: "Please enter the correct age",
						},
						Gender: {
							type: "Select",
							id: "corrected.Gender",
							question: "What is the correct gender?",
							options: ["MALE", "FEMALE", "THIRD GENDER"],
							errorMsg: "Please select an option",
						},
						"House Number": {
							type: "FreeField",
							id: "corrected.House Number",
							question: "What is the correct house number?",
							placeholder: "Enter a house number",
							errorMsg: "Please select an option",
						},
					},
				},
			},
		},
		{
			type: "AutoComplete",
			id: "Voters in House",
			question: "How many voters are in your house?",
			errorMsg: "Please select an option",
			options: Array.from({ length: 100 }, (_, i) => (i + 1).toString()),
		},
		{
			type: "NumField",
			id: "Mobile Number",
			question: "What is your Phone Number?",
			errorMsg: "Please enter a mobile number",
			placeholder: "Enter a mobile number",
			isPhoneNumber: true,
			maxLength: 10,
		},
		{
			type: "DependentSelect",
			subType: "Select",
			id: "residentType",
			question: "Are you a Local or Non-local resident?",
			errorMsg: "Please select an option",
			options: ["Local", "Non-local"],
			subQuestions: {
				"Non-local": {
					type: "DependentSelect",
					subType: "AutoComplete",
					id: "Native State",
					question: "What is your Native State?",
					errorMsg: "Please select an option",
					options: statesAndDistricts.map((obj) => obj.state),
					subQuestions: statesAndDistricts.reduce((acc, obj) => {
						acc[obj.state] = {
							type: "AutoComplete",
							id: "Native District",
							question: "What is your Native District?",
							options: obj.districts,
							errorMsg: "Please Select an Option",
						};
						return acc;
					}, {}),
				},
			},
		},
		{
			type: "DependentSelect",
			subType: "Select",
			id: "May I know what is your caste",
			question: "May I know what is your caste?",
			errorMsg: "Please select an option",
			options: ["Yes", "No"],
			subQuestions: {
				Yes: {
					type: "DependentSelect",
					subType: "AutoComplete",
					id: "Caste",
					question: "What is your caste?",
					errorMsg: "Please select an option",
					options: [
						"Jat",
						"Baghel",
						"Bramhin",
						"Baniya",
						"Yadav",
						"Valmiki",
						"Katik",
						"Rathore",
						"Harijan",
						"Punjabi",
						"Sindhi",
						"Sardar",
						"Pal",
						"Muslim",
						"Saini",
						"Odia",
						"Jatav",
						"Other",
					],
					subQuestions: {
						Other: {
							type: "TextField",
							id: "OtherCaste",
							question: "Other Caste",
							errorMsg: "Please enter a Caste",
							placeholder: "Enter a Caste",
						},
					},
				},
			},
		},
		// ...(ans?.Caste === "Other" ? [] : []),
		{
			type: "Select",
			id: "Which party do you support",
			question: "Which party do you support?",
			errorMsg: "Please select a party",
			options: ["Congress", "BJP", "Aam Aadmi Party", "Neutral"],
		},
	];

	return [
		{
			type: "Select",
			id: "Voter Present Status",
			question: "Voter's Present Status",
			errorMsg: "Please select a Status",
			options: [
				"Present",
				"Moved to another Booth",
				"Moved outside Assembly",
				"Died",
				"Unable to find",
			],
		},
		...(ans?.["Voter Present Status"] === "Moved to another Booth"
			? [
					{
						type: "FreeField",
						id: "New Booth",
						question: "New Booth",
						// errorMsg: "Please enter a Booth",
						placeholder: "Enter new Booth",
					},
			  ]
			: []),
		...(ans?.["Voter Present Status"] !== "Died" &&
		ans?.["Voter Present Status"] !== "Moved to another Booth" &&
		ans?.["Voter Present Status"] !== "Moved outside Assembly"
			? fixedQuestions
			: []),
	];
};

export const addNewVoterForm = (user, voterRel) => {
	return [
		{
			type: "DependentSelect",
			subType: "Select",
			id: "Category",
			question: "Category",
			errorMsg: "Please select an option",
			options: [
				"First Time Voter",
				"Vote has been removed",
				"Voter Booth Changed",
				"Voter Assembly Changed",
				"Other",
			],
			subQuestions: {
				"Voter Booth Changed": [
					{
						type: "FreeField",
						id: "Old Booth",
						question: "Old Booth",
						errorMsg: "Please enter a Booth",
						placeholder: "Enter old Booth",
					},
					{
						type: "FreeField",
						id: "New Booth",
						question: "New Booth",
						errorMsg: "Please enter a Booth",
						placeholder: "Enter new Booth",
					},
				],
				"Voter Assembly Changed": {
					type: "FreeField",
					id: "Old Assembly",
					question: "Old Assembly",
					errorMsg: "Please enter a Assembly",
					placeholder: "Enter old Assembly",
				},
				Other: {
					type: "FreeField",
					id: "Other Category",
					question: "Other Category",
					errorMsg: "Please enter a Category",
					placeholder: "Enter a Category",
				},
			},
		},
		{
			type: "TextField",
			id: "name",
			question: "Name",
			errorMsg: "Please enter a name",
			placeholder: "Enter a name",
		},
		{
			type: "NumField",
			id: "mobile",
			question: "Mobile Number",
			errorMsg: "Please enter a mobile number",
			placeholder: "Enter a mobile number",
			isPhoneNumber: true,
			maxLength: 10,
		},
		{
			type: "DependentSelect",
			subType: "Select",
			id: "residentType",
			question: "Are you a Local or Non-local resident?",
			errorMsg: "Please select an option",
			options: ["Local", "Non-local"],
			subQuestions: {
				"Non-local": {
					type: "DependentSelect",
					subType: "AutoComplete",
					id: "Native State",
					question: "What is your Native State?",
					errorMsg: "Please select an option",
					options: statesAndDistricts.map((obj) => obj.state),
					subQuestions: statesAndDistricts.reduce((acc, obj) => {
						acc[obj.state] = {
							type: "AutoComplete",
							id: "Native District",
							question: "What is your Native District?",
							options: obj.districts,
							errorMsg: "Please Select an Option",
						};
						return acc;
					}, {}),
				},
			},
		},
		{
			type: "DependentSelect",
			subType: "Select",
			id: "Has voter ID",
			question: "Do you have voter ID card",
			errorMsg: "Please select an option",
			options: ["Yes", "No"],
			subQuestions: {
				Yes: {
					type: "FreeField",
					id: "voterId",
					question: "Voter ID EPIC Number",
					placeholder: "Enter a EPIC Number",
				},
			},
		},
		{
			type: "NumField",
			id: "age",
			question: "Age",
			errorMsg: "Please enter a Age",
			placeholder: "Enter a Age",
		},
		{
			type: "Select",
			id: "gender",
			question: "Gender",
			options: ["Male", "Female", "Third Gender"],
			errorMsg: "Please select an option",
		},
		{
			type: "Select",
			id: "pollingStation",
			question: "Polling Station",
			options: user.assigned?.pollingStations || [],
			errorMsg: "Please select an option",
			defaultValue: user?.assigned?.pollingStations[0],
			disabled: true,
		},
		{
			type: "Select",
			id: "section",
			question: "Section",
			options: user.assigned?.sections || [],
			errorMsg: "Please select an option",
		},
		{
			type: "FreeField",
			id: "houseNumber",
			question: "House Number",
			errorMsg: "Please enter a House Number",
			placeholder: "Enter a House Number",
			defaultValue: voterRel?.houseNumber,
			disabled: voterRel ? true : false,
		},
	];
};
