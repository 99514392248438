import { createSlice } from "@reduxjs/toolkit";
import { getConstituencies } from "../extraReducers";

const constituencySlice = createSlice({
	name: "constituency",
	initialState: {
		constituencies: [],
		status: "idle",
		error: null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getConstituencies.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getConstituencies.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.constituencies = action.payload.data;
			})
			.addCase(getConstituencies.rejected, (state, action) => {
				state.status = "error";
				state.error = action.error.message;
			});
	},
});

export default constituencySlice.reducer;
