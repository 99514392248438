// Importing necessary React components for the Dashboard page

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVoters } from "../../../../store/extraReducers";
import toast from "react-hot-toast";
import Header from "../../../../components/global/Header";
import VoterAgentSkl from "../../../../components/skeletons/VoterAgentSkl";
import AgentAnalytics from "../completedSurveys/common/AgentAnalytics";
import VotersList from "./components/VotersList";


const VoterAgent = () => {
    let timeoutId;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [keyword, setKeyword] = useState('')

    const { userDetails } = useSelector((store) => {
        return store.user;
    });

    const { voters, data } = useSelector((state) => state.voters);

    const scrollableDivRef = useRef(null);

    const getVotersFunc = (page, word) => {
        dispatch(getVoters({ page, keyword: word || keyword })).then(action => {

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }

    const handleScroll = (event) => {
        if (voters.length < data.totalDocs) {
            const { target } = event;
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                if (target.scrollHeight - target.scrollTop - 200 < target.clientHeight) {
                    getVotersFunc(page + 1, keyword)
                    setPage((prevPage) => prevPage + 1);
                    // setQuery(`page=${page + 1}&q=${keyword}`)
                }
            }, 50);
        }
    };

    useEffect(() => {
        scrollableDivRef.current.scrollTop = 0;
        setLoading(true)

        dispatch(getVoters({ page: 1 })).then(action => {
            setLoading(false)

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }, [dispatch, userDetails])

    return (
        <section className='flex flex-col h-screen'>
            <Header />

            <section className="flex flex-col p-4 sm:p-8 flex-1 h-full overflow-y-scroll" onScroll={(e) => handleScroll(e)} ref={scrollableDivRef}>
                {loading ?
                    <VoterAgentSkl />
                    :
                    <>
                        <AgentAnalytics />
                        <VotersList setKeyword={setKeyword} getVotersFunc={getVotersFunc} />
                    </>
                }
            </section>
        </section>
    );
};

export default VoterAgent;
