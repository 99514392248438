import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getAgents } from '../../../../store/extraReducers';
import Header from '../../../../components/global/Header';
import AgentList from './components/AgentList';
import AgentListSkl from '../../../../components/skeletons/AgentListSkl';

const SurveyAgentManager = () => {
    const { userDetails } = useSelector((store) => store.user);

    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)

        dispatch(getAgents()).then(action => {
            setLoading(false)

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }, [userDetails, dispatch]);

    return (
        <section className='flex flex-col max-h-[100dvh]'>
            <Header />

            {
                loading ? <AgentListSkl /> : <AgentList />
            }
        </section>
    )
}

export default SurveyAgentManager