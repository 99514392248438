import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { processSurveyData } from '../../Utils/processSurveyData';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarGraph = ({ labels, data, type }) => {
    const options = {
        maintainAspectRatio: type ? false : true,
        responsive: true,
        indexAxis: type ?? 'y',
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    // color: 'rgba(255,255,255,0.1)',
                },
                ticks: {
                    color: 'white'
                }
            },
            y: {
                grid: {
                    color: 'rgba(255,255,255,0.08)',
                },
                ticks: {
                    color: 'white'
                }
            }
        },
    };

    console.log(labels);
    

    const processedData = processSurveyData(labels, data);
    console.log(processedData);
    

    const barData = {
        labels,
        datasets: [
            {
                data: processedData.map((obj) => obj.count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(255, 159, 64)',
                    'rgb(75, 192, 192)',
                    'rgb(255, 205, 86)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)',
                    'rgb(201, 203, 207)'
                ],
                borderWidth: 1,
                minBarLength: 5
            },
        ],
    };

    const chartHeight = labels.length * 40 + labels.length;

    return (
        <div className='p-4 bg-slate-900 rounded-xl shadow-md w-full' style={type === "x" ? { minHeight: "40dvh" } :
            { minHeight: chartHeight + 'px' }}>
            <Bar options={options} data={barData} />
        </div>
    );
}

export default BarGraph