import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import voterAdmin from "../../assets/images/svg/voterAdmin.svg";
import voterAgent from "../../assets/images/svg/voterAgent.svg";
import surveyAdmin from "../../assets/images/svg/surveyAdmin.svg";
import surveyAgent from "../../assets/images/jpg/surveyAgent.jpg";
import superAdmin from "../../assets/images/jpg/superAdmin.jpg";
import logo from "../../assets/images/png/logo.png";
import user from "../../assets/images/png/user.png";
import menu from "../../assets/images/png/menu.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import { logout } from "../../store/slices/userSlice";
import { clearAnalytics } from "../../store/slices/analyticsSlice";
import { setSidebarActive } from "../../store/slices/sidebarSlice";
import { clearList } from "../../store/slices/voterSlice";
import Translator from "./Translator";
import Marquee from "react-fast-marquee";

const Header = () => {
    const { userDetails } = useSelector((store) => store.user);
    const { active: sidebarActive } = useSelector((state) => state.sidebar);
    const dispatch = useDispatch();

    const [profileMenu, setProfileMenu] = useState(null);
    const open = Boolean(profileMenu);

    const handleClick = (event) => {
        setProfileMenu(event.currentTarget);
    };

    const handleClose = () => {
        setProfileMenu(null);
    };

    const handleLogout = () => {
        dispatch(logout());
        dispatch(clearAnalytics());
        dispatch(clearList());
    };

    const getPfp = () => {
        switch (userDetails.role) {
            case "voterAdmin":
                return voterAdmin;
            case "voterAgent":
                return voterAgent;
            case "surveyAdmin":
            case "guestAdmin":
                return surveyAdmin;
            case "surveyAgent":
                return surveyAgent;
            case "superAdmin":
                return superAdmin;
            default:
                return user;
        }
    };

    const [shouldScroll, setShouldScroll] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        // Check if the content is long enough to scroll
        if (contentRef.current && contentRef.current.scrollWidth > contentRef.current.clientWidth) {
            setShouldScroll(true);
        } else {
            setShouldScroll(false);
        }
    }, [open]);

    return (
        <header className="w-full flex flex-col py-3 lg:flex-row lg:items-center border-b-2 border-b-slate-200 px-5 bg-white rounded-b-3xl sm:rounded-b-xl shadow-lg z-10">
            <div className="flex w-full items-center gap-1">
                <NavLink
                    onClick={() => dispatch(setSidebarActive(!sidebarActive))}
                    className="text-slate-900 rounded-full xl:hidden"
                >
                    <div className='w-6'>
                        <img className="w-full h-full" src={menu} alt="logo" />
                    </div>
                </NavLink>

                <NavLink
                    className="text-slate-900 rounded-full hidden xl:block"
                >
                    <div className='w-6'>
                        <img className="w-full h-full" src={menu} alt="logo" />
                    </div>
                </NavLink>

                <div className="flex divide-x-4 divide-slate-300 items-center">
                    <div className='w-28'>
                        <img className="w-full h-full" src={logo} alt="logo" />
                    </div>
                </div>

                <div className="ml-auto flex items-center gap-4">
                    <button
                        onClick={handleClick}
                        className="flex w-12 h-12 ring-2 ring-gray-300 rounded-full overflow-hidden"
                    >
                        <img
                            src={getPfp()}
                            className="w-full h-full object-cover border-2 border-white rounded-full"
                            alt="profile"
                        />
                    </button>

                    <Menu
                        anchorEl={profileMenu}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                width: "18rem",
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                borderRadius: "1rem",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <MenuItem
                            sx={{
                                borderRadius: "1rem",
                                margin: "0 0.5rem",
                                padding: "0.5rem",
                                "&:hover": {
                                    background: "none",
                                    cursor: "default",
                                },
                                "&:focus": {
                                    background: "none",
                                    cursor: "default",
                                },
                            }}
                        >
                            <div className="flex items-center gap-4 myfont w-full">
                                <div className="p-2 m-1 bg-slate-100 rounded-full text-xl ring-2 ring-slate-300 text-slate-500">
                                    <BiUser />
                                </div>

                                <div className="mulish w-full overflow-hidden">
                                    <h5 ref={contentRef} className="font-bold w-full">
                                        <Marquee play={shouldScroll} delay={1}>
                                            {userDetails.name}
                                        </Marquee>
                                    </h5>

                                    <h5 ref={contentRef} className="w-full invisible absolute">
                                        {userDetails.name}
                                    </h5>

                                    <p className="capitalize text-sm">
                                        <span className="font-bold">Contact:</span> +91 {userDetails.mobile}
                                    </p>
                                </div>
                            </div>
                        </MenuItem>

                        <Divider />

                        {
                            userDetails.role === "voterAgent" &&
                            <>
                                <MenuItem
                                    sx={{
                                        borderRadius: "1rem",
                                        margin: "0 0.5rem",
                                        padding: "0.5rem",
                                        "&:hover": {
                                            background: "none",
                                            cursor: "default",
                                        },
                                        "&:focus": {
                                            background: "none",
                                            cursor: "default",
                                        },
                                    }}
                                >
                                    <div className="flex m-1 items-center gap-4 myfont">
                                        <div className="p-2 bg-sky-100 rounded-full ring-2 ring-sky-100 text-xl text-sky-500">
                                            <AiOutlineInfoCircle />
                                        </div>
                                        <div>
                                            <p className="capitalize text-sm">
                                                <span className="font-bold">Constituency Code:</span> {userDetails.constituencyCode}
                                            </p>

                                            <p className="capitalize text-sm">
                                                <span className="font-bold">Booth: </span>
                                                {userDetails.assigned.pollingStations[0]}
                                            </p>
                                        </div>
                                    </div>
                                </MenuItem>
                                <Divider />
                            </>
                        }


                        <MenuItem
                            onClick={handleLogout}
                            sx={{
                                borderRadius: "1rem",
                                margin: "0 0.5rem",
                                padding: "0.5rem",
                                "&:hover": {
                                    background: "#f5f5f5",
                                },
                            }}
                        >
                            <div className="flex m-1 items-center gap-4">
                                <div className="p-2 bg-rose-100 rounded-full ring-2 ring-rose-100 text-xl text-red-500">
                                    <BiLogOut />
                                </div>
                                <div>
                                    <p className="mulish text-sm font-bold">Logout</p>
                                </div>
                            </div>
                        </MenuItem>

                        <Divider />

                        <MenuItem sx={{
                            "&:hover": {
                                background: "none",
                                cursor: "default",
                            },
                            "&:focus": {
                                background: "none",
                                cursor: "default",
                            },
                        }}>
                            <Translator />
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </header>
    );
};

export default Header;
