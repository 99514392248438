import { createSlice } from "@reduxjs/toolkit";
import { addAgent, editAgent, getAgentForm, getAgents, removeAgent } from "../extraReducers";

const agentSlice = createSlice({
	name: "agentSlice",
	initialState: {
		agents: [],
		formData: {},
		status: "idle",
		error: "",
	},
	reducers: {
		clearAgents(state) {
			state.agents = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAgents.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getAgents.fulfilled, (state, action) => {
				state.status = action.payload.success;

				state.agents = action.payload.agents;
			})
			.addCase(getAgents.rejected, (state, action) => {
				state.status = "Failed to fetch agents";
				state.error = action.error.message;
			})

			.addCase(addAgent.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAgent.fulfilled, (state, action) => {
				state.status = action.payload.success;
				// state.agents = [action.payload.agent, ...state.agents];
			})
			.addCase(addAgent.rejected, (state, action) => {
				state.status = "Failed to add admins";
				state.error = action.error.message;
			})

			.addCase(editAgent.pending, (state) => {
				state.status = "loading";
			})
			.addCase(editAgent.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.agents = action.payload.agents;
			})
			.addCase(editAgent.rejected, (state, action) => {
				state.status = "Failed to add admins";
				state.error = action.error.message;
			})

			.addCase(getAgentForm.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getAgentForm.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.formData = action.payload.data;
			})
			.addCase(getAgentForm.rejected, (state, action) => {
				state.status = "Failed to fetch form data";
				state.error = action.error.message;
			})

			.addCase(removeAgent.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeAgent.fulfilled, (state, action) => {
				state.status = action.payload.success;
				// state.agents = action.payload.relevantUsers;
			})
			.addCase(removeAgent.rejected, (state, action) => {
				state.status = "Failed to fetch form data";
				state.error = action.error.message;
			});
	},
});

export const { clearAgents } = agentSlice.actions;
export default agentSlice.reducer;
