import { IoAnalyticsOutline } from "react-icons/io5";
import { BiCloudDownload } from "react-icons/bi";
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { getCsv } from "../../../../store/extraReducers";
import toast from "react-hot-toast";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomToolbar = ({ setPs, getAnalytics }) => {
    const apiRef = useGridApiContext();
    const dispatch = useDispatch()

    const handlePsChange = (e) => {
        const selectedRows = apiRef.current.getSelectedRows();
        const selectedData = Array.from(selectedRows.values());

        const ps = selectedData.map((data) => data.ps);

        setPs(ps);
        getAnalytics(e, ps)
    };

    const handleExport = () => {
        toast.loading("Requesting for survey analytics");
        const selectedRows = apiRef.current.getSelectedRows();
        const selectedData = Array.from(selectedRows.values());

        const ps = selectedData.map((data) => data.ps);

        dispatch(getCsv(ps)).then(action => {
            toast.dismiss();

            if (action.payload?.size) {
                toast.success("Download started")
            } else {
                toast.error("Something Went Wrong")
            }
        })
    };

    return (
        <GridToolbarContainer>
            <div className='flex justify-between items-center w-full'>
                <div className="flex gap-5">
                    <button
                        id="analytics"
                        className='flex gap-2.5 items-center justify-center bg-emerald-100/80 text-emerald-900 px-4 py-2 rounded-xl hover:shadow-md transition-all duration-500'
                        onClick={(e) => handlePsChange(e.currentTarget)}
                    >
                        <IoAnalyticsOutline className="text-xl" /> View Analytics
                    </button>

                    <button
                        className='flex gap-2.5 items-center justify-center text-blue-500 bg-blue-50 px-4 py-2 rounded-xl hover:shadow-md transition-all duration-500'
                        onClick={handleExport}
                    >
                        <BiCloudDownload className="text-xl" /> Export data
                    </button>
                </div>

                <GridToolbarQuickFilter
                    sx={{
                        width: "40%",
                        '& .MuiInputBase-root': {
                            padding: "10px"
                        },
                        '& .MuiInputBase-root:hover::before': {
                            borderBottom: "1px solid #9ca3af !important",
                        },
                    }}
                />
            </div>
        </GridToolbarContainer>
    );
};

const PsFilters = ({ open, setOpen, setPs, ps, getAnalytics }) => {
    const { data } = useSelector((state) => state.analytics);
    const { pollingStations } = data;

    const columns = [
        { field: "psNumber", headerName: "Number", sortable: false, width: 100 },
        { field: "psName", headerName: "Name", width: 250 },
        { field: "psAddress", headerName: "Address", flex: 1 },
        { field: "totalVoterCount", headerName: "Number of Voters", width: 150 },
        { field: "totalSurveysTaken", headerName: "Surveys Taken", width: 150 },
    ];

    const rows = pollingStations.map((obj) => {
        const { pollingStation, address } = obj;
        const [psNumber, psName] = pollingStation.split(" - ");
        return {
            id: psNumber,
            psNumber: parseInt(psNumber),
            psName: psName,
            psAddress: address,
            ps: pollingStation,
            totalVoterCount: obj.totalDocs,
            totalSurveysTaken: obj.surveyTakenCount,
        };
    });

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(ps.map((ps) => ps.split(" - ")[0]));
    }, [ps, open]);

    const getRowClassName = (params) => {
        const surveysTaken = params.row.totalSurveysTaken;
        const voterCount = params.row.totalVoterCount;

        if (surveysTaken === 0) {
            return '!bg-red-100';
        } else if (surveysTaken === voterCount) {
            return '!bg-emerald-100';
        }

        return '';
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={() => setOpen(false)}
            PaperProps={{
                style: {
                    height: "70dvh",
                    width: "100%",
                    minWidth: "70%",
                    margin: "1rem",
                    borderRadius: "1rem",
                    padding: "1rem 0 0 0",
                },
            }}
        >
            <DialogTitle className="flex">
                <p className="text-slate-800 font-semibold first-letter:uppercase poppins">
                    Select Polling Stations
                </p>
            </DialogTitle>

            <DialogContent className="!px-5" dividers>
                <DataGrid
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    rows={rows}
                    getRowClassName={getRowClassName}
                    slots={{ toolbar: () => <CustomToolbar setPs={setPs} getAnalytics={getAnalytics} /> }}
                    rowSelectionModel={selected}
                    onRowSelectionModelChange={(newSelection) => setSelected(newSelection)}
                    checkboxSelection
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'psNumber', sort: 'asc' }],
                        },
                    }}
                    sx={{
                        border: "none",
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default PsFilters;
