export const processSurveyData = (allOptions, surveyData) => {
	// Create a Map with lowercase keys
	const surveyDataMap = new Map(
		surveyData.map((item) => [item.option?.toLowerCase(), item.count])
	);

	// Normalize case for all options and retrieve counts from the Map
	return allOptions.map((option) => ({
		option,
		count: surveyDataMap.get(option.toLowerCase()) || 0,
	}));
};
