import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiEditAlt, BiUser } from "react-icons/bi";
import { HiOutlineUserRemove } from "react-icons/hi";
import { formateDataAndTime } from "../../../../../Utils/dateAndTimeFormatter";
import { Tooltip } from "@mui/material";

const AdminDetailsCard = ({ admin, getFormDatafunc, removeUserFunc, loading, anchorEl }) => {

    return (
        <div
            className="flex h-max bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 flex-col gap-2 relative"
        >
            <div className="flex">
                <div className="flex items-center w-full sm:w-max rounded-2xl">
                    <div className="p-2 mr-3 inline-flex items-center justify-center rounded-full bg-violet-700 text-white text-lg sm:text-2xl">
                        <BiUser />
                    </div>

                    <div className="flex flex-col">
                        <span className="text-gray-900 text-sm sm:text-lg first-letter:uppercase font-bold">
                            {admin?.name || "Admin Name"}
                        </span>

                        <span className="text-sm">+91 {admin?.mobile || 1234567890}</span>
                    </div>
                </div>


                <div className="flex gap-2 ml-auto mb-auto">
                    <Tooltip title="Edit" arrow>
                        <button
                            id={admin?._id}
                            name="edit"
                            disabled={loading}
                            onClick={(e) => getFormDatafunc(e.currentTarget)}
                        >
                            <div className="p-2 bg-orange-100 rounded-full text-lg text-orange-500">
                                {
                                    loading && (anchorEl?.id === admin._id && anchorEl?.name === "edit") ?
                                        <AiOutlineLoading3Quarters className="animate-spin" />
                                        :
                                        <BiEditAlt />
                                }
                            </div>
                        </button>
                    </Tooltip>

                    <Tooltip title="Delete" arrow>
                        <button
                            id={admin?._id}
                            name="del"
                            disabled={loading}
                            onClick={(e) => removeUserFunc(e.currentTarget)}
                        >
                            <div className="p-2 bg-red-100 rounded-full text-lg text-red-500">
                                {
                                    loading && anchorEl?.id === admin._id && anchorEl?.name === "del" ?
                                        <AiOutlineLoading3Quarters className="animate-spin" />
                                        :
                                        <HiOutlineUserRemove />
                                }
                            </div>
                        </button>
                    </Tooltip>
                </div>
            </div>

            {/* Admin Details */}
            <div className="flex flex-col flex-grow leading-relaxed">
                <p>
                    <span className="font-semibold">Constituencies Assigned: </span>

                    <span>{admin?.assigned.constituencies.join(", ")}</span>
                </p>

                <p>
                    <span className="font-semibold">Created At: </span>

                    <span>{formateDataAndTime(admin?.createdAt)}</span>
                </p>

                {/* <p className="mt-5">
                    <span className="font-bold">Survey Progress: 50% </span>

                    <LinearProgress
                        sx={{
                            bgcolor: '#d1d5db80',
                            '& .MuiLinearProgress-bar': { backgroundColor: '#6d28d9' }
                        }}
                        color="secondary"
                        variant="determinate"
                        value={20}
                        className="rounded-full p-1 mt-2 bg-gray-300" />
                </p> */}
            </div>
        </div>
    )
}

export default AdminDetailsCard