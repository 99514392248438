import { useSelector } from "react-redux"
import VoterAdmin from "../../voterAdmin/dashboard/VoterAdmin"
import SurveyAdmin from "../../surveyAdmin/SurveyAdmin"

const SuperAdmin = () => {
    const { userDetails } = useSelector((store) => store.user)

    const surveyType = userDetails.surveyType

    return (
        <>
            {
                surveyType === "voter" ?
                    <VoterAdmin /> :
                    <SurveyAdmin />
            }
        </>
    )
}

export default SuperAdmin