import { createSlice } from "@reduxjs/toolkit";
import { getConstituencies } from "../extraReducers";

const sidebarSlice = createSlice({
	name: "sidebar",
	initialState: {
		constituencies: [],
		active: false,
	},
	reducers: {
		setSidebarActive(state, action) {
			state.active = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getConstituencies.pending, (state) => {
				state.status = "loading";
			})

			.addCase(getConstituencies.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.constituencies = action.payload.data;
			})
			.addCase(getConstituencies.rejected, (state, action) => {
				state.status = "error";
				state.error = action.error.message;
			});
	},
});

export const { setSidebarActive } = sidebarSlice.actions;
export default sidebarSlice.reducer;
