export const mandals = [
	"Banganapalle ( బనగానపల్లె)",
	"Owk (అవుకు)",
	"Kolimigundla (కొలిమిగుండ్ల)",
	"Koilkuntla (కొయిలకుంట్ల)",
	"Sanjamala (సంజమాల)",
	// "Nandikotkur",
	// "⁠pagidyala",
	// "⁠J. Bangla",
	// "⁠Kothapalle",
	// "⁠pamulapadu",
	// "⁠midthuru",
	// "Pendlimarri",
	// "Chinthakommadinne",
	// "Vallur",
	// "Veerapunayunipalle",
	// "Nandavaram",
	// "Yemmiganur",
	// "Gonegandla",
	// "Adilabad Rural",
	// "Adilabad Urban",
	// "Bazarhatnoor",
	// "Bela",
	// "Boath",
	// "Bheempoor",
	// "Gudihathnur",
	// "Ichoda",
	// "Jainad",
	// "Mavala",
	// "Neradigonda",
	// "Sirikonda",
	// "Talamadagu",
	// "Tamsi",
	// "Gadiguda",
	// "Inderavelly",
	// "Narnoor",
	// "Utnoor",
	// "Allapalli",
	// "Annapureddypally",
	// "Aswaraopeta",
	// "Chandrugonda",
	// "Chunchupally",
	// "Dammapeta",
	// "Gundala",
	// "Julurpad",
	// "Kothagudem",
	// "Laxmidevipalli",
	// "Mulakalapalle",
	// "Palvancha",
	// "Sujathanagar",
	// "Tekulapalle",
	// "Yellandu",
	// "Aswapuram",
	// "BhadrachalamCherla",
	// "Burgampahad",
	// "Dummugudem",
	// "Karakagudem",
	// "Manuguru",
	// "Pinapaka",
	// "Bheemadevarapalle",
	// "Dharmasagar",
	// "Elkathurthy",
	// "Hanamkonda",
	// "Hasanparthy",
	// "Inavole",
	// "Kamalapuram",
	// "Kamalapuram Municipality",
	// "Kazipet",
	// "Velair",
	// "Atmakur",
	// "Damera",
	// "Nadikuda",
	// "Parkal",
	// "Shayampet",
	// "Amberpet",
	// "Asif Nagar",
	// "Bahadurpura",
	// "Bandlaguda",
	// "Charminar",
	// "Golkonda",
	// "Himayathnagar",
	// "Nampally",
	// "Saidabad",
	// "Ameerpet",
	// "Khairtabad",
	// "Maredpally",
	// "Musheerabad",
	// "Secunderabad",
	// "Shaikpet",
	// "Tirumalgiri",
	// "Beerpur",
	// "Buggaram",
	// "Dharmapuri",
	// "Gollapalle",
	// "Jagtial",
	// "Jagtial Rural",
	// "Kodimial",
	// "Mallial",
	// "Pegadapalle",
	// "Raikal",
	// "Sarangapur",
	// "Velgatoor",
	// "Ibrahimpatnam",
	// "Mallapur",
	// "Metpalli",
	// "Kathlapur",
	// "Korutla",
	// "Medipalle",
	// "Bachannapeta",
	// "Devaruppala",
	// "Jangaon",
	// "Lingalaghanpur",
	// "Narmetta",
	// "Raghunathapalle",
	// "Tharigoppula",
	// "Chilpur",
	// "Kodakandla",
	// "Palakurthi",
	// "Station Ghanpur",
	// "Zaffergadh",
	// "Bhupalpalle",
	// "Chityal",
	// "Ghanpur",
	// "Kataram",
	// "Mahadevpur",
	// "Maha Mutharam",
	// "Malharrao",
	// "Mogullapalle",
	// "Palimela",
	// "Regonda",
	// "Tekumatla",
	// "Eturunagaram",
	// "Govindaraopet",
	// "Kannaigudem",
	// "Mangapet",
	// "Mulug",
	// "Tadvai",
	// "Vajedu",
	// "Venkatapur",
	// "Kaloor Timmanadoddi",
	// "Dharur",
	// "Gadwal",
	// "Itikyal",
	// "Maldakal",
	// "Ghattu",
	// "Aiza",
	// "Rajoli",
	// "Waddepalle",
	// "Manopad",
	// "Undavelli",
	// "Alampur",
	// "Banswada",
	// "Bichkunda",
	// "Birkoor",
	// "Jukkal",
	// "Madnur",
	// "Nasurullabad",
	// "Nizamsagar",
	// "Pedda Kodapgal",
	// "Pitlam",
	// "Dongli",
	// "Bhiknoor",
	// "Bibipet",
	// "Domakonda",
	// "Kamareddy",
	// "Machareddy",
	// "Rajampet",
	// "Ramareddy",
	// "Sadasivanagar",
	// "Tadwai",
	// "Gandhari",
	// "Lingampet",
	// "Naga Reddipet",
	// "Yellareddy",
	// "Chigurumamidi",
	// "Choppadandi",
	// "Gangadhara",
	// "Ganneruvaram",
	// "Karimnagar",
	// "Karimnagar (Rural-I)",
	// "Karimnagar (Rural-II)",
	// "Manakondur",
	// "Ramadugu",
	// "Thimmapur",
	// "Ellandakunta",
	// "Huzurabad",
	// "Jammikunta",
	// "Shankarapatnam",
	// "V Saidapur",
	// "Veenavanka",
	// "Enkuru",
	// "Kalluru",
	// "Penuballi",
	// "Sathupalli",
	// "Thallada",
	// "Vemsoor",
	// "Bonakal",
	// "Chinthakani",
	// "Kamepalle",
	// "Khammam Rural",
	// "Khammam Urban",
	// "Konijerla",
	// "Kusumanchi",
	// "Madhira",
	// "Mudigonda",
	// "Nelakondapalle",
	// "Raghunadhapalem",
	// "Singareni",
	// "Thirumalayapalem",
	// "Wyra",
	// "Yerrupalem",
	// "Asifabad",
	// "Jainoor",
	// "Kerameri",
	// "Lingapur",
	// "Rebbena",
	// "Sirpur U",
	// "Tiryani",
	// "Wankidi",
	// "Bejjur",
	// "Chintalamanepally",
	// "Dahegaon",
	// "Kagaznagar",
	// "Kouthala",
	// "Penchikalpet",
	// "Sirpur T",
	// "Bayyaram",
	// "Dornakal",
	// "Ganagavaram",
	// "Garla",
	// "Gudur",
	// "Kesamudram",
	// "Kothaguda",
	// "Kuravi",
	// "Mahabubabad",
	// "Seerole",
	// "Inugurthy",
	// "Chinnagudur",
	// "Danthalapalle",
	// "Maripeda",
	// "Narsimhulapet",
	// "Nellikudur",
	// "Peddavangara",
	// "Thorrur",
	// "Addakal",
	// "Balanagar",
	// "Bhoothpur",
	// "Chinna Chintha Kunta",
	// "Devarkadara",
	// "Gandeed",
	// "Hanwada",
	// "Jadcherla",
	// "Koilkonda",
	// "Mahabubnagar Rural",
	// "Mahabubnagar Urban",
	// "Midjil",
	// "Mohammadabad",
	// "Musapet",
	// "Nawabpet",
	// "Rajapur",
	// "Bheemaram",
	// "Chennur",
	// "Dandepally",
	// "Hajipur",
	// "Jaipur",
	// "Jannaram",
	// "Kotapally",
	// "Luxettipet",
	// "Mancherial",
	// "Mandamarri",
	// "Naspur",
	// "Bellampally Mandal",
	// "Bheemini",
	// "Kannepally",
	// "Kasipet",
	// "Nennel",
	// "Tandur",
	// "Vempally",
	// "Alladurg",
	// "Havelighanpur",
	// "Medak",
	// "Nizampet",
	// "Papannapet",
	// "Ramayampet",
	// "Regode",
	// "Shankarampet A",
	// "Shankarampet R",
	// "Tekmal",
	// "Chilipched",
	// "Kowdipalle",
	// "Kulcharam",
	// "Narsapur",
	// "Shivampet",
	// "Chegunta",
	// "Manoharabad",
	// "Masaipet",
	// "Narsingi",
	// "Tupran",
	// "Yeldurthy",
	// "Alwal",
	// "Bachupally",
	// "Dundigal Gandimaisamma",
	// "Kukatpally",
	// "Malkajgiri",
	// "Quthbullapur",
	// "Ghatkesar",
	// "Kapra",
	// "Keesara",
	// "Medchal",
	// "Medipally",
	// "Muduchintalpalli",
	// "Shamirpet",
	// "Uppal",
	// "Eturnagaram",
	// "Mulugu",
	// "Sammakka Saralamma Tadvai",
	// "Venkatapuram",
	// "Wazeed",
	// "Achampet",
	// "Amrabad",
	// "Balmoor",
	// "Lingal",
	// "Padra",
	// "Uppunuthala",
	// "Charakonda",
	// "Kalwakurthy",
	// "Urkonda",
	// "Vangoor",
	// "Veldanda",
	// "Kodair",
	// "Kollapur",
	// "Peddakothapalle",
	// "Pentlavelli",
	// "Bijinapalle",
	// "Nagarkurnool",
	// "Tadoor",
	// "Telkapalle",
	// "Thimmajipeta",
	// "Chandampet",
	// "Chinthapalle",
	// "Devarakonda",
	// "Gundlapalle",
	// "Gurrampode",
	// "Kondamallapally",
	// "Marriguda",
	// "Nampalle",
	// "Neredugommu",
	// "Pedda adiserlapalle",
	// "Adavi devula palli",
	// "Anumula Haliya",
	// "Damaracherla",
	// "Madugulapally",
	// "Miryalaguda",
	// "Nidamanur",
	// "Peddavoora",
	// "Thripuraram",
	// "Tirumalagiri Sagar",
	// "Vemulapalle",
	// "Chandur",
	// "Chityala",
	// "Kangal",
	// "Kattangoor",
	// "Kethepalle",
	// "Munugode",
	// "Nakrekal",
	// "Narketpalle",
	// "Nalgonda",
	// "Saligouraram",
	// "Thipparthi",
	// "Damaragidda",
	// "Dhanwada",
	// "Kosgi",
	// "Krishna",
	// "Maddur",
	// "Maganoor",
	// "Makthal",
	// "Marikal",
	// "Narayanpet",
	// "Narva",
	// "Utkoor",
	// "Basar",
	// "Bhainsa",
	// "Kubeer",
	// "Kuntala",
	// "Lokeshwaram",
	// "Mudhole",
	// "Tanoor",
	// "Dastuarabad",
	// "Dilawarpur",
	// "Kaddampeddur",
	// "Khanapur",
	// "Laxmanchanda",
	// "Mamada",
	// "Narsapur G",
	// "Nirmal Rural",
	// "Nirmal Urban",
	// "Pembi",
	// "Soan",
	// "Aloor",
	// "Armur",
	// "Balkonda",
	// "Bheemgal",
	// "Donkeshwar",
	// "Jakranpalle",
	// "Kammarpalle",
	// "Mendora",
	// "Mortad",
	// "Mupkal",
	// "Nandipet",
	// "Vailpur",
	// "Yergatla",
	// "Bodhan",
	// "Kotgiri",
	// "Pothangal",
	// "Mosra",
	// "Ranjal",
	// "Rudrur",
	// "Saloora",
	// "Varni",
	// "Yedapalle",
	// "Dharpalle",
	// "Dichpalle",
	// "Indalwai",
	// "Makloor",
	// "Mugpal",
	// "Navipet",
	// "Nizamabad North",
	// "Nizamabad Rural",
	// "Nizamabad South",
	// "Sirkonda",
	// "Kamanpur",
	// "Manthani",
	// "Mutharam",
	// "Ramagiri",
	// "Anthergoan",
	// "Dharmaram",
	// "Elgaid",
	// "Julapalle",
	// "Palakurthy",
	// "Peddapalli",
	// "Ramagundam",
	// "Srirampur",
	// "Sultanabad",
	// "Odela",
	// "Ellanthakunta",
	// "Gambhiraopeta",
	// "Mustabad",
	// "Sircilla",
	// "Thangallapalle",
	// "Veernapalle",
	// "Yellareddypeta",
	// "Boinpalle",
	// "Chandurthi",
	// "Konaraopeta",
	// "Rudrangi",
	// "Vemulawada",
	// "Vemulawada Rural",
	// "Chevella",
	// "Moinabad",
	// "Shabad",
	// "Shankarpalle",
	// "Abdullapurmet",
	// "Hayathnagar",
	// "Madgul",
	// "Manchal",
	// "Yacharam",
	// "Amangal",
	// "Balapur",
	// "Kadthal",
	// "Kandukur",
	// "Maheshwaram",
	// "Saroornagar",
	// "Talakonapally",
	// "Gandipet",
	// "Rajendranagar",
	// "Serilingampally",
	// "Shamshabad",
	// "Farooqnagar",
	// "Jilled Chowdergudem",
	// "Keshampeta",
	// "Kondurg",
	// "Kothur",
	// "Nandigam",
	// "Shadnagar",
	// "Kalher",
	// "Kangti",
	// "Manoor",
	// "Nagilgidda",
	// "Narayankhed",
	// "Sirgapoor",
	// "Tadkal[7]",
	// "Ameenpur",
	// "Andole",
	// "Gummadidala",
	// "Hathnoora",
	// "Jinnaram",
	// "Kandi",
	// "Kondapur",
	// "Munipally",
	// "Patancheru",
	// "Pulkal",
	// "Ramchandrapuram",
	// "Sadasivpet",
	// "Sangareddy",
	// "Vatpally",
	// "Jharasangam",
	// "Kohir",
	// "Mogudampally",
	// "Nyalkal",
	// "Raikode",
	// "Zaheerabad",
	// "Cherial",
	// "Chinnakodur",
	// "Doulthabad",
	// "Dubbak",
	// "Komuravelli",
	// "Mirdoddi",
	// "Nangnoor",
	// "Narayanaraopet",
	// "Siddipet (Urban)",
	// "Siddipet (Rural)",
	// "Thoguta",
	// "Gajwel",
	// "Jagdevpur",
	// "Kondapak",
	// "Markook",
	// "Raipole",
	// "Wargal",
	// "Akkannapet",
	// "Bejjanki",
	// "Dhoolmitta",
	// "Husnabad",
	// "Koheda",
	// "Atmakur(s)",
	// "Chivvemla",
	// "Jajireddygudem",
	// "Maddirala",
	// "Mothey",
	// "Nagaram",
	// "Nuthankal",
	// "Penpahad",
	// "Thirumalagiri",
	// "Thungathurthy",
	// "Suryapet",
	// "Ananthagiri",
	// "Chilkur",
	// "Kodad",
	// "Munagala",
	// "Nadigudem",
	// "Garidepally",
	// "Huzurnagar",
	// "Mallareddygudem",
	// "Mattampally",
	// "Mellachervu",
	// "Neredcherla",
	// "Palakeedu",
	// "Basheerabad",
	// "Bommaraspet",
	// "Kodangal",
	// "Peddemul",
	// "Yelal",
	// "Bantwaram",
	// "Doma",
	// "Kotepally",
	// "Kulkacherla",
	// "Marpalle",
	// "Mominpet",
	// "Pargi",
	// "Pudur",
	// "Vikarabad",
	// "Amarchinta",
	// "Chinnambavi",
	// "Ghanpur (Khilla)",
	// "Gopalpeta",
	// "Kothakota",
	// "Madanapur",
	// "Pangal",
	// "Pebbair",
	// "Peddamandadi",
	// "Revally",
	// "Srirangapur",
	// "Veepanagandla",
	// "Wanaparthy",
	// "Geesugonda",
	// "Khila Warangal",
	// "Parvathagiri",
	// "Rayaparthy",
	// "Wardhannapet",
	// "Warangal",
	// "Sangem",
	// "Chennaraopet",
	// "Duggondi",
	// "Nallabelly",
	// "Narsampet",
	// "Nekkonda",
	// "Addaguduru",
	// "Alair",
	// "Atmakur (M)",
	// "Bibinagar",
	// "Bhongir",
	// "Bommalaramaram",
	// "Motakondur",
	// "Mothkur",
	// "Rajapet",
	// "Turkapally",
	// "Yadagirigutta",
	// "Bhoodan Pochampally",
	// "Choutuppal",
	// "Narayanpur",
	// "Ramannapet",
	// "Valigonda",
];
