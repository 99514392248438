import { BiUser } from 'react-icons/bi'
import { formateDataAndTime } from '../../../../Utils/dateAndTimeFormatter'

const VoterSurveyCard = ({ survey: voter, setSurveyId }) => {
    const { survey, _id: id, isAddition } = voter

    console.log(voter);


    return (
        <button id={id} onClick={(e) => setSurveyId(e.currentTarget.id)} className={`${survey?.fieldsCorrect === "No" ? "bg-yellow-50" : "bg-emerald-50"} ${isAddition && "!bg-sky-100"} ${["Died", "Moved outside Assembly"].includes(survey?.["Voter Present Status"]) && "!bg-red-100"} ${survey?.["Voter Present Status"] === "Moved to another Booth" && "!bg-orange-100"} flex flex-col gap-3 shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 relative`}>
            <div className="flex items-center w-full sm:w-max rounded-2xl">
                <div className={`${survey?.fieldsCorrect === "No" ? "bg-yellow-500" : "bg-emerald-700"} ${isAddition && "bg-sky-700"} ${["Died", "Moved outside Assembly"].includes(survey?.["Voter Present Status"]) && "bg-red-700"} ${survey?.["Voter Present Status"] === "Moved to another Booth" && "!bg-orange-700"} p-2 mr-3 inline-flex items-center justify-center rounded-full text-white text-lg sm:text-2xl`}>
                    <BiUser />
                </div>

                <div className="flex flex-col items-start">
                    <p className="text-gray-900 text-sm sm:text-lg first-letter:uppercase font-bold">
                        {voter.name || "Admin Name"}
                    </p>

                    <p className="text-sm">+91 {voter?.survey?.["Mobile Number"] || 1234567890}</p>
                </div>
            </div>

            <div className='flex flex-col items-start'>
                <p><span className='font-bold'>Surveyed By:</span> {voter.agentDetails.name}</p>

                <p><span className='font-bold'>Agent Contact:</span> {voter.agentDetails.mobile}</p>

                <p><span className='font-bold'>Surveyed on:</span> {formateDataAndTime(voter.surveyedAt)}</p>
            </div>
        </button>
    )
}

export default VoterSurveyCard