import { Chip, Checkbox, TextField, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

const ChipSelect = ({ formHook, fieldData, parentId }) => {
    const { watch, register, setValue: setFormValue, formState: { errors }, unregister } = formHook;

    const { id, options = [], question, defaultValue, errorMsg, max } = fieldData;

    // Map the defaultValue to match the structure of options
    const mappedDefaultValue = defaultValue
        ? defaultValue
            .map(val => options.find(opt => opt?.option ? opt?.option === val : opt === val))
            .filter(option => option !== undefined) // Filter out undefined values
        : [];

    // Initialize the value as an array of selected options
    const [value, setValue] = useState(mappedDefaultValue ?? []);

    // Handle the change in the selection
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const parentVal = watch()?.[parentId]

    useEffect(() => {
        setFormValue(id, value.map(v => v?.option ? v?.option : v), { shouldDirty: true });

        register(id, {
            validate: () => (value.length > 0) || errorMsg
        });
    }, [register, id, errorMsg, value, setFormValue]);

    useEffect(() => {
        return () => {
            unregister(id); // Unregister the field when the component is unmounted
        };
    }, [register, id, unregister])

    useEffect(() => {
        if (parentVal?.length === 0)
            setValue([])
        // else
        // setValue(mappedDefaultValue)
    }, [parentVal, setValue])

    return (
        <div>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {question}
            </label>

            <div className="mt-2">
                <Autocomplete
                    multiple
                    id={id}
                    options={options}
                    ListboxProps={{
                        sx: {
                            maxHeight: "200px",
                        },
                    }}
                    sx={{
                        borderRadius: '8px',
                        outline: 'none',
                        border: '2px solid rgb(209 213 219)',
                        '& .MuiOutlinedInput-root': {
                            minHeight: "50px",
                            padding: "5px 0 5px 10px",

                            '& fieldset': {
                                border: 'none',
                            },
                            '&:hover fieldset': {
                                border: 'none',

                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            border: 'none',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                            border: 'none',
                        },
                    }}
                    getOptionLabel={(option) => option?.option ?? option}
                    value={value}
                    onChange={handleChange}
                    isOptionEqualToValue={(option, value) => option?.option ? (option?.option === value?.option) : (option === value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            key={id}
                            placeholder={value.length === 0 ? "Select" : ""}
                        />
                    )}
                    renderOption={(props, option, { selected }) =>
                        <MenuItem
                            disabled={option?.available === false || (max && value?.length === max) || false}
                            {...props}
                            key={option?.option || option} // Use unique key for each option
                        >
                            <Checkbox
                                disabled={option?.available === false || (max && value?.length === max) || false}
                                checked={selected}
                                style={{ marginRight: 8 }}
                            />
                            <span className='flex flex-wrap items-center gap-2'>
                                {option?.option || option}{" "}{option?.isAssigned ? <span className='text-gray-400 text-sm'>(Already Assigned)</span> : ""}
                            </span>
                        </MenuItem>
                    }
                    renderTags={
                        (value, getTagProps) =>
                            value.map((option, index) =>
                                <Chip
                                    key={option?.option || option} // Use unique key for each chip
                                    label={option?.option || option}
                                    {...getTagProps({ index })}
                                    sx={{ bgcolor: "#ede9fe" }}
                                />
                            )
                    }
                    disableCloseOnSelect
                />
            </div>

            {
                errors[id] && (
                    <span className='text-[#D32F2F] text-[12px] ml-[14px] roboto'>
                        Please Select an Option
                    </span>
                )
            }
        </div>
    );
}

export default ChipSelect;
