import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react'

const CustomSelect = ({ fieldData, formHook }) => {
    const { register, setValue: setFormValue, formState: { errors }, clearErrors, unregister } = formHook;

    const { id, options, question, defaultValue, errorMsg, disabled = false } = fieldData;

    const [value, setValue] = useState(defaultValue ?? "Select")

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        value !== "Select" && clearErrors(id);

        setFormValue(id, value, { shouldDirty: true });
    }, [id, value, setFormValue, clearErrors])

    useEffect(() => {
        return () => {
            unregister(id); // Unregister the field when the component is unmounted
        };
    }, [id, unregister])

    return (
        <div>
            <label
                htmlFor={id}
                className="block text-sm font-medium leading-6 text-gray-900">
                {question}
            </label>

            <div className="mt-2 w-full">
                <Select
                    labelId="demo-simple-select-label"
                    id={id}
                    name={id}
                    value={value}
                    disabled={disabled}
                    className="w-full focus:outline-gray-400"
                    sx={{
                        "& fieldset": { border: 'none' },
                        height: '50px',
                        borderRadius: '8px',
                        outline: 'none',
                        border: '2px solid rgb(209 213 219)',
                    }}
                    selectprops={{
                        menuprops: {
                            PaperProps: {
                                style: {
                                    maxHeight: 20
                                },
                            },
                        }
                    }}
                    {...register(id, {
                        validate: (value) => value !== 'Select'
                    })}
                    onChange={(e) => handleChange(e.target.value)
                    }
                    error={id in errors}
                >
                    <MenuItem value="Select">Select</MenuItem>

                    {
                        options.map((option, key) => {
                            return (
                                <MenuItem key={key} value={option}>
                                    {option}
                                </MenuItem>
                            )
                        })
                    }
                </Select>

                {
                    errors[id] && (
                        <span className='text-[#D32F2F] text-[12px] ml-[14px] roboto'>
                            {errorMsg || "Please Select an Option"}
                        </span>
                    )
                }
            </div>
        </div>
    )
}

export default CustomSelect