import { createSlice } from "@reduxjs/toolkit";
import {
	addAdmin,
	editAdmin,
	getAdminForm,
	getAdmins,
	removeAdmin,
} from "../extraReducers";

const adminSlice = createSlice({
	name: "adminSlice",
	initialState: {
		admins: [],
		formData: {},
		status: "idle",
		error: null,
	},
	reducers: {
		clearAdmins(state) {
			state.admins = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAdmins.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getAdmins.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.admins = action.payload.admins;
			})
			.addCase(getAdmins.rejected, (state, action) => {
				state.status = "Failed to fetch admins";
				state.error = action.error.message;
			})

			.addCase(addAdmin.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAdmin.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.admins = [action.payload.admin, ...state.admins];
			})
			.addCase(addAdmin.rejected, (state, action) => {
				state.status = "Failed to add admins";
				state.error = action.error.message;
			})

			.addCase(editAdmin.pending, (state) => {
				state.status = "loading";
			})
			.addCase(editAdmin.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.admins = action.payload.admins;
			})
			.addCase(editAdmin.rejected, (state, action) => {
				state.status = "Failed to add admins";
				state.error = action.error.message;
			})

			.addCase(getAdminForm.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getAdminForm.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.formData = action.payload.data;
			})
			.addCase(getAdminForm.rejected, (state, action) => {
				state.status = "Failed to fetch form data";
				state.error = action.error.message;
			})

			.addCase(removeAdmin.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeAdmin.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.admins = action.payload.relevantUsers;
			})
			.addCase(removeAdmin.rejected, (state, action) => {
				state.status = "Failed to fetch form data";
				state.error = action.error.message;
			});
	},
});

export const { clearAdmins } = adminSlice.actions;
export default adminSlice.reducer;
