import { createSlice } from "@reduxjs/toolkit";
import { getBasicSurveyAnalytics, getCsv, getVoterSurveyAnalytics } from "../extraReducers";

const analyticsSlice = createSlice({
	name: "analytics",
	initialState: {
		data: {},
		status: "idle",
		error: "",
	},
	reducers: {
		clearAnalytics(state) {
			state.data = {};
			state.status = "idle";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBasicSurveyAnalytics.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getBasicSurveyAnalytics.fulfilled, (state, action) => {
				state.status = "succeeded";
				const { data, totalDocs } = action.payload;

				state.data = { analytics: data, totalDocs };
			})
			.addCase(getBasicSurveyAnalytics.rejected, (state, action) => {
				state.status = "error";
				state.error = action.error.message;
			})

			.addCase(getVoterSurveyAnalytics.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getVoterSurveyAnalytics.fulfilled, (state, action) => {
				state.status = "succeeded";
				// const { data, totalDocs } = action.payload;

				state.data = action.payload.analytics;
			})
			.addCase(getVoterSurveyAnalytics.rejected, (state, action) => {
				state.status = "error";
				state.error = action.error.message;
			})

			.addCase(getCsv.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getCsv.fulfilled, (_, action) => {
				const url = window.URL.createObjectURL(new Blob([action.payload]));
				const a = document.createElement("a");
				a.href = url;
				a.download = "Analytics.csv";
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.addCase(getCsv.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const { clearAnalytics } = analyticsSlice.actions;
export default analyticsSlice.reducer;
