import { RiSurveyLine } from "react-icons/ri";
import { useState } from 'react'
import { RiUserAddLine } from "react-icons/ri";
import SurveyCard from './SurveyCard';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import SurveyForm from './SurveyForm';
import useRecording from "../../../../Utils/useRecording";

const VoterList = ({ handleScroll }) => {
    const { surveys, data } = useSelector((state) => state.surveys);
    const [formOpen, setFormOpen] = useState(false)

    const rtc = useRecording()

    return (
        <section className="p-6 pb-4 relative overflow-y-auto" onScroll={handleScroll}>
            <div className='flex justify-between items-center mb-7'>
                <h1 className="sm:text-3xl text-2xl font-bold">
                    Survey List
                </h1>
            </div>


            <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5">
                <div className='flex items-center gap-3'>
                    <div className='h-full text-xl font-semibold border-2 border-slate-300 rounded-2xl flex flex-col items-center p-2.5 justify-around'>
                        <p className='text-xs whitespace-nowrap'>Total Count</p>
                        <p>
                            {data.totalDocs}
                        </p>
                    </div>

                    <button
                        onClick={() => setFormOpen(true)}
                        className={`flex w-full h-full shadow-md border-2 border-dashed border-slate-500 rounded-2xl gap-2 bg-slate-50 items-center justify-center text-slate-500 p-2 focus:outline-none`}
                    >
                        <div
                            className={`p-2 mr-3 inline-flex items-center justify-center rounded-full text-lg sm:text-2xl`}
                        >
                            <RiUserAddLine />
                        </div>
                        <div className="font-semibold">
                            Add New Survey
                        </div>
                    </button>
                </div>


                {
                    surveys.length < 1 ?
                        <button
                            className={`flex w-full h-full shadow-md border-slate-500 rounded-2xl gap-2 bg-slate-100 items-center justify-center text-slate-500 py-4 focus:outline-none font-semibold ring-1 ring-gray-200`}
                        >
                            Create your first survey now! <RiSurveyLine />
                        </button>
                        :
                        <>
                            {surveys.map((survey, key) => {
                                return (
                                    <SurveyCard key={key} info={survey} />
                                );
                            })}

                            {
                                (surveys.length !== data.totalDocs) &&
                                Array(6).fill().map((i, key) => (
                                    <div key={key}>
                                        <div className="flex h-full bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 gap-2 items-center">
                                            <div className="flex gap-2">
                                                <Skeleton variant="circular" width={40} height={40} />

                                                <div className="flex flex-col">
                                                    <Skeleton variant="text" width={120} height={20} />
                                                    <Skeleton variant="text" width={80} height={16} />
                                                </div>
                                            </div>

                                            <div className='ml-auto text-xl'>
                                                <BiDotsVerticalRounded />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </>

                }
            </div>

            {
                (surveys.length >= 12 && data.totalDocs === surveys.length) &&
                <div className='md:hidden w-full flex mt-6 mb-4 text-gray-400 p-4 rounded-md border-t-2 border-dashed'>
                    <p className='mx-auto'>End of List 📃</p>
                </div>
            }

            <SurveyForm formOpen={formOpen} setFormOpen={setFormOpen} rtc={rtc} />
        </section>
    )
}

export default VoterList