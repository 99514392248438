import { TextField } from '@mui/material';
import { useEffect } from 'react';

const CustomTextField = ({ formHook, fieldData }) => {
    const { register, unregister, formState: { errors } } = formHook

    const { id, question, errorMsg, placeholder, defaultValue, isEmail = false, disabled } = fieldData

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const basicRegex = /^[a-zA-Z\s\u0C00-\u0C7F]+$/;

    useEffect(() => {
        return () => {
            unregister(id); // Unregister the field when the component is unmounted
        };
    }, [register, id, unregister])

    return (
        <div>
            <div className="flex items-center justify-between">
                <label
                    htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900"
                >
                    {question}
                </label>
            </div>

            <div className="mt-2">
                <TextField
                    type="text"
                    defaultValue={defaultValue ?? ""}
                    disabled={disabled || false}
                    name={id}
                    placeholder={placeholder}
                    inputProps={{
                        id: id,
                        style: {
                            borderRadius: "8px",
                            outline: "none",
                            border: "2px solid rgb(209 213 219)",
                        }
                    }}
                    sx={{
                        "& fieldset": { border: 'none' },
                    }}
                    className="w-full focus:outline-gray-400"
                    {...register(id,
                        {
                            required: errorMsg,
                            pattern: {
                                value: isEmail ? emailRegex : basicRegex,
                                message: isEmail ? "Please enter a valid email" : "Field can not contain numbers or special characters",
                            }
                        })
                    }
                    error={id in errors}
                    helperText={id in errors ? errors[id].message : null}
                />
            </div>
        </div>
    )
}

export default CustomTextField