import Skeleton from "@mui/material/Skeleton";
import { BiSearch, BiUser } from "react-icons/bi";

const VoterAgentSkl = () => {
    // Create an array to mimic the loading state with a certain number of items
    const skeletonCount = 9; // Adjust this as needed

    return (
        <section>
            <div className="sm:bg-agentAnalytics flex flex-co xl:flex-row rounded-3xl items-center w-full md:p-6 sm:shadow-md gap-6 bg-slate-700">
                <div className="grid grid-cols-2 bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3 md:p-5 gap-2 items-center w-full divide-x ">
                    <div className="aspect-square h-40 lg:h-56 mx-auto">
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            height="100%"
                            width="100%"
                        />
                    </div>

                    <div className="flex flex-col items-center justify-center text-center text-sm lg:text-lg h-full">
                        <p>
                            <Skeleton
                                sx={{ borderRadius: "0.5rem" }}
                                animation="wave"
                                variant="rectangular"
                                height={20}
                                width={100}
                            />
                        </p>

                        <p>
                            Voters Remaining
                        </p>

                    </div>
                </div>
            </div>

            <div className="container py-14 mx-auto">
                <div className="flex w-full mb-10">
                    <h1 className="sm:text-3xl text-2xl font-semibold text-gray-900">
                        Voter List
                    </h1>

                    <div className="flex items-center ml-auto gap-5">
                        <div className="hidden lg:flex items-center ml-auto gap-5">
                            <div className="flex xl:w-96 bg-slate-100 ring-1 ring-gray-300 rounded-full">
                                <input
                                    disabled
                                    className="w-full p-2 pl-5 focus:outline-none bg-transparent"
                                    type="text"
                                    name="search"
                                    aria-label="Search"
                                    placeholder="Search"
                                />

                                <button disabled className="text-gray-50 bg-teal-900 p-2 px-5 rounded-full rounded-l-none">
                                    <BiSearch size={25} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:hidden flex items-center gap-5 mb-10 w-full">
                    <div className="flex bg-slate-100 ring-1 ring-gray-300 rounded-full w-full items-center">
                        <input
                            disabled
                            className="w-full p-2 pl-5 focus:outline-none bg-transparent"
                            type="text"
                            name="search"
                            aria-label="Search"
                            placeholder="Search"
                        />

                        <div disabled className="text-gray-400 p-2 px-5 text-xl">
                            <BiSearch />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5">
                    {Array.from({ length: skeletonCount }).map((_, key) => (
                        <div key={key} className="text-start">
                            <div
                                className={`bg-slate-100 flex h-full shadow-md ring-1 ring-slate-200 rounded-2xl p-2 md:p-2 gap-2`}
                            >
                                <div className="w-full">
                                    <div className="flex items-center w-full px-2 py-2 rounded-2xl">
                                        <div
                                            className={`bg-slate-700 p-2 mr-3 inline-flex items-center justify-center rounded-full text-white text-lg sm:text-2xl`}
                                        >
                                            <BiUser />
                                        </div>


                                        <div className="flex flex-col">
                                            <Skeleton animation="wave" width={100} height={18} />
                                            <Skeleton animation="wave" width={60} height={14} />
                                        </div>

                                    </div>

                                    <div className="flex flex-col mt-4">
                                        <Skeleton animation="wave" width={100} height={18} />
                                        <Skeleton animation="wave" width={150} height={18} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default VoterAgentSkl;
