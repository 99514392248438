import { useState } from 'react'
import RecordingListSkl from '../../../../components/skeletons/RecordingListSkl'
import { FiRefreshCw } from 'react-icons/fi'
import { getVoterSurveys } from '../../../../store/extraReducers'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import VoterSurveyCard from './VoterSurveyCard'
import VoterSurveyDetailsForm from './VoterSurveyDetailsForm'

const VoterSurveyList = ({ loadingMore }) => {
    const dispatch = useDispatch();
    const { surveys } = useSelector((state) => state.voters);

    const [loading, setLoading] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [surveyId, setSurveyId] = useState(null)

    const handleAudioPlay = (audioId) => {
        if (currentAudio && currentAudio !== audioId) {
            const prevAudio = document.getElementById(currentAudio);
            if (prevAudio) {
                prevAudio.pause(); // Pause the previously playing audio
            }
        }

        setCurrentAudio(audioId); // Set the current audio ID
    };

    const getRecordings = () => {
        setLoading(true)

        dispatch(getVoterSurveys({ page: 1 })).then(action => {
            setLoading(false)

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }

    return (
        <div>
            <h1 className='text-2xl mb-10 font-bold flex justify-between items-center'>
                📃 Survey List

                <button onClick={getRecordings} disabled={loading === 'loading'} className="p-2 px-3.5 bg-red-200 rounded-full flex gap-2 items-center">
                    <FiRefreshCw className={`${loading && "animate-spin"} text-lg`} /> <span className="text-sm">Refresh</span>
                </button>
            </h1>

            {
                surveys.length === 0 ?
                    <div className="flex min-h-[20dvh] bg-slate-50 mt-8 text-xl items-center justify-center w-full shadow-md rounded-xl font-bold my-10">
                        <p>No data found 🗄️</p>
                    </div>

                    :
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 2xl:gap-5">
                        {
                            surveys.map((survey, key) => (
                                <VoterSurveyCard key={key} survey={survey} handleAudioPlay={handleAudioPlay} setSurveyId={setSurveyId} />
                            ))
                        }


                        {
                            loadingMore &&
                            Array(8).fill().map((_, i) => (
                                <RecordingListSkl key={i} />
                            ))
                        }
                    </div>
            }

            <VoterSurveyDetailsForm setSurveyId={setSurveyId} surveyId={surveyId} handleAudioPlay={handleAudioPlay} />
        </div>
    )
}

export default VoterSurveyList