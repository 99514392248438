import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { processSurveyData } from '../../Utils/processSurveyData';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ labels, data, type }) => {
    const options = {
        maintainAspectRatio: type && false,
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'white'  // Change the label color here
                }
            },
        },
    };

    const processedData = processSurveyData(labels, data);

    const pieData = {
        labels,
        datasets: [
            {
                label: 'Number of voters',
                data: processedData.map((obj) => obj.count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(255, 159, 64)',
                    'rgb(75, 192, 192)',
                    'rgb(255, 205, 86)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)',
                    'rgb(201, 203, 207)'
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className='p-10 bg-slate-900 rounded-xl shadow-md w-full'>
            <Pie options={options} data={pieData} />
        </div>
    )
}

export default PieChart