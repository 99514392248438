/* eslint-disable no-control-regex */
import { BiLoaderAlt } from "react-icons/bi";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { addSurvey, getSurveys } from "../../../../store/extraReducers";
import { useForm } from "react-hook-form"
import toast from "react-hot-toast";
import CustomSelect from "../../../../components/inputs/Select";
import CustomTextField from "../../../../components/inputs/TextField";
import NumField from "../../../../components/inputs/NumField";
import FreeField from "../../../../components/inputs/FreeField";
import AutoComplete from "../../../../components/inputs/AutoComplete";
import DependentSelect from "../../../../components/inputs/DependentSelect";
import { surveyForms } from "../../../../assets/data/forms";
import { DialogActions, Divider } from "@mui/material";
import ChipSelect from "../../../../components/inputs/ChipSelect";

const SurveyForm = ({ formOpen, setFormOpen, rtc }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const { userDetails } = useSelector((store) => store.user);

    const formHook = useForm({ mode: 'onSubmit' });

    const { handleSubmit, reset } = formHook

    const { stopRecording, setFormData } = rtc

    const addSurveyFunc = async (formData) => {
        setLoading(true);
        try {
            const finalData = await setFormData(formData);

            dispatch(addSurvey(finalData)).then(action => {
                setLoading(false);
                if (action.payload.success) {
                    setFormOpen(false);
                    dispatch(getSurveys(1))
                    toast.success("Survey Details Added");
                } else {
                    toast.error(action.payload);
                }
            });
        } catch (error) {
            setLoading(false);
            toast.error("An error occurred while processing the recording");
        }
    };

    const onSubmit = (details) => {
        try {
            setLoading(true);

            const formData = new FormData();

            if ("geolocation" in navigator) {
                navigator?.geolocation?.getCurrentPosition(
                    (position) => {
                        const data = { ...details, boothNumber: details?.boothNumber?.trim(), location: { lat: position?.coords?.latitude, lng: position?.coords?.longitude } }

                        formData.append('data', JSON.stringify(data));

                        addSurveyFunc(formData);
                    },
                    (err) => {
                        toast.error(err, { duration: 4000 });
                    }
                );
            } else {
                toast.error("Geolocation is not available.");
            }
        } catch (err) {
            toast.error(err)
        }

    }

    const constituencyCode = parseInt(userDetails?.constituencyCode)
    const questions = surveyForms?.[constituencyCode]

    return (
        questions ?
            <Dialog
                open={formOpen}
                onClose={() => {
                    setFormOpen(false)
                    stopRecording()
                    reset()
                }}
                PaperProps={{
                    style: {
                        width: "100%",
                        margin: "1rem",
                        borderRadius: "1rem",
                        padding: "1rem 0 0 0",
                    },
                }}
            >
                <DialogTitle className="border-b">
                    <p className="text-slate-800 font-bold first-letter:uppercase mulish">
                        Survey Form
                    </p>
                </DialogTitle>

                <DialogContent>
                    <form className="space-y-6 mt-6" onSubmit={handleSubmit(onSubmit)}>
                        {
                            questions.map((obj, key) => {
                                switch (obj.type) {
                                    case "TextField":
                                        return <CustomTextField key={key} formHook={formHook} fieldData={obj} />

                                    case "NumField":
                                        return <NumField key={key} formHook={formHook} fieldData={obj} />

                                    case "FreeField":
                                        return <FreeField key={key} formHook={formHook} fieldData={obj} />

                                    case "Select":
                                        return <CustomSelect key={key} formHook={formHook} fieldData={obj} />

                                    case "ChipSelect":
                                        return <ChipSelect key={key} formHook={formHook} fieldData={obj} />

                                    case "AutoComplete":
                                        return <AutoComplete key={key} formHook={formHook} fieldData={obj} />

                                    case "DependentSelect":
                                        return <DependentSelect key={key} formHook={formHook} fieldData={obj} />
                                }
                            })
                        }

                        <Divider className="pt-4" />
                        {/* Action buttons */}
                        <div className="flex gap-5">
                            <button
                                type="button"
                                onClick={() => {
                                    setFormOpen(false)
                                    stopRecording()
                                    reset()
                                }}
                                className="flex-1 w-full sm:w-auto justify-center rounded-md border border-slate-600 px-3 py-2 text-sm font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                            >
                                Cancel
                            </button>

                            <button
                                disabled={loading}
                                type="submit"
                                className="flex-1 flex w-full sm:w-auto justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
                            >
                                {
                                    loading ?
                                        <>
                                            Submitting {" "}
                                            <BiLoaderAlt className="animate-spin" />
                                        </> :
                                        "Submit"
                                }
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            :
            <Dialog
                open={formOpen}
                onClose={() => {
                    setFormOpen(false)
                    stopRecording();
                }}
                PaperProps={{
                    style: {
                        width: "100%",
                        margin: "1rem",
                        borderRadius: "1rem",
                        padding: "1rem 0 0 0",
                    },
                }}
            >
                <DialogTitle className="flex">
                    <p className="text-slate-800 font-bold first-letter:uppercase mulish">
                        Add New Survey
                    </p>
                </DialogTitle>

                <DialogContent dividers>
                    <p className="text-slate-800 first-letter:uppercase mulish">
                        Ops, there are no questions for your survey! Please contact your admin.
                    </p>
                </DialogContent>

                <DialogActions>
                    <button
                        type="button"
                        onClick={() => {
                            setFormOpen(false)
                            stopRecording()
                        }}
                        className="flex-1 flex sm:w-auto justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
                    >
                        Okay
                    </button>
                </DialogActions>
            </Dialog>
    )

};

export default SurveyForm;
