import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../../components/global/Header';
import Error from '../../../../Error';
import toast from 'react-hot-toast';
import { getVoterSurveys } from '../../../../store/extraReducers';
import VoterSurveyList from './VoterSurveyList';
import SurveyDetailsSkl from '../../../../components/skeletons/SurveyDetailsSkl';

const VoterSurveys = () => {
    const dispatch = useDispatch();
    const scrollableDivRef = useRef(null);

    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [errorFetching, setErrorFetching] = useState(false)
    const [page, setPage] = useState(1)
    // const [keyword, setKeyword] = useState('')

    const { userDetails } = useSelector((store) => {
        return store.user;
    });

    const { surveys, data } = useSelector((state) => state.voters);


    const handleScroll = (event) => {
        if (surveys.length < data.totalDocs) {
            const { target } = event;
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                if (target.scrollHeight - target.scrollTop - 200 < target.clientHeight) {
                    getRecordings(page + 1)
                    setPage((prevPage) => prevPage + 1);
                }
            }, 50);
        }
    };

    const getRecordings = (page, keyword) => {
        setLoadingMore(true)
        dispatch(getVoterSurveys({ page, keyword })).then(action => {
            setLoadingMore(false)

            if (!action.payload.success) {
                setErrorFetching(true)
                toast.error(action.payload)
            }
        })
    }

    useEffect(() => {
        scrollableDivRef.current.scrollTop = 0;

        setLoading(true)
        dispatch(getVoterSurveys({ page: 1 })).then(action => {
            setLoading(false)

            if (!action.payload.success) {
                setErrorFetching(true)
                toast.error(action.payload)
            }
        })
    }, [dispatch, userDetails])

    // useEffect(() => {
    //     setLoading(true)
    // }, [keyword])

    let timeoutId;

    if (errorFetching && page === 1) {
        return (
            <Error />
        )
    }

    return (
        <section className='flex flex-col h-screen'>
            <Header />

            <div className='overflow-y-scroll px-4 py-8 h-full ' onScroll={(e) => handleScroll(e)} ref={scrollableDivRef}>

                {
                    loading ?
                        <SurveyDetailsSkl /> :
                        <VoterSurveyList loadingMore={loadingMore} />
                }
            </div>

        </section>
    );
};

export default VoterSurveys;
