import { FiRefreshCw } from "react-icons/fi";
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../../components/global/Header";
import { getBasicSurveyAnalytics } from "../../../store/extraReducers";
import BarGraph from "../../../components/graphs/BarGraph";
import Percentage from "../../../components/graphs/Percentage";
import PieChart from "../../../components/graphs/PieChart";
// import SurveyAnalyticsMap from "../../../components/graphs/SurveyAnalyticsMap";
import Count from "../../../components/graphs/Count";
import AnalyticsSkl from "../../../components/skeletons/AnalyticsSkl";
import Error from "../../../Error";
import { findAnalyticsFor } from "../../../assets/data/analyticsData";
import toast from "react-hot-toast";

const SurveyAdmin = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.analytics)
  const { userDetails } = useSelector((store) => store.user);

  const { totalDocs, analytics } = data || {};

  const [loading, setLoading] = useState(true)
  const [errorFetching, setErrorFetching] = useState(false)

  const constituencyCode = userDetails.constituencyCode;

  useEffect(() => {
    setLoading(true)

    dispatch(getBasicSurveyAnalytics()).then(action => {
      setLoading(false)
      setErrorFetching(false)

      if (!action.payload?.success) {
        toast.error(action.payload)
        setErrorFetching(true)
      }
    })
  }, [dispatch, constituencyCode])


  if (loading) return <AnalyticsSkl />
  
  if (errorFetching) return (
    <section className="flex flex-col max-h-[100dvh]">
      <Error />
    </section >
  )

  const getMatchingValues = (existingArray, dataArray) => {
    const matchingValues = existingArray.map(item => {
      const dataItem = dataArray.find(obj => Object.keys(obj)[0].split("(")[0].trim() === item.split("(")[0].trim());

      return dataItem !== undefined ? dataItem[item] : 0;
    });

    return matchingValues;
  };

  const sortByKeys = (array) => {
    return [...array].sort((a, b) => {

      // Get the key of each object (since it's already a number)
      const keyA = Object.keys(a)[0];
      const keyB = Object.keys(b)[0];

      // Compare the keys
      return keyA - keyB;
    });
  };

  const sortedBoothAnalytics = sortByKeys(analytics.boothNumber);

  const splitArray = (arr, size) => {
    const parts = [];
    for (let i = 0; i < arr.length; i += size) {
      parts.push(arr.slice(i, i + size));
    }
    return parts;
  };

  const questions = findAnalyticsFor(constituencyCode, data)

  return (
    <section className='flex flex-col max-h-screen'>
      <Header />

      <div className='overflow-y-scroll px-4 pt-6 h-full'>
        <h1 className='text-2xl font-bold flex justify-between items-center'>
          🚀 Survey Analytics

          <button disabled={loading} onClick={() => dispatch(getBasicSurveyAnalytics())} className="p-2 bg-green-200 rounded-full flex gap-2 items-center">
            <FiRefreshCw className={`${loading && "animate-spin"} text-lg`} /> <span className="text-sm">Refresh</span>
          </button>
        </h1>

        {
          questions === null ?
            <div className="flex min-h-[20dvh] my-10 bg-slate-50 mt-8 text-xl items-center justify-center w-full shadow-md rounded-xl font-bold">
              <p>No data found 🗄️</p>
            </div>
            :
            <>
              <div className="bg-slate-100 border shadow-sm rounded-xl py-4 text-center mt-8 ">
                Survey collected for <span className="font-bold">{totalDocs}</span> people!
              </div>

              <div className='my-8 grid xl:grid-cols-3 xl:flex-row gap-8 text-lg font-semibold'>

                {
                  questions.map((obj, i) => {
                    var values = getMatchingValues(obj.options, obj.data)

                    return (
                      <div key={i} className='flex flex-col justify-between xl:border xl:p-4 xl:rounded-lg'>
                        <div>
                          <h2 className='text-lg font-extrabold'>{i + 1}. {obj.question}</h2>

                          <Percentage labels={obj.options} values={values} totalDocs={obj.totalDocs ?? totalDocs} />
                        </div>

                        <div>
                          <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={obj.options} values={values} />
                          <PieChart labels={obj.options} values={values} />
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              <div>
                <div className="flex flex-col xl:col-span-2 justify-between flex-1">
                  <div>
                    <h2 className='text-lg font-extrabold'>18. Mandal-wise People Distribution</h2>

                    <Percentage labels={analytics.mandal.map(obj => Object.keys(obj)[0])} values={analytics.mandal.map(obj => Object.values(obj)[0])} totalDocs={totalDocs} />
                  </div>

                  <div className="w-full xl:max-h-[45dvh] xl:flex gap-10">
                    <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={analytics.mandal.map(obj => Object.keys(obj)[0])} values={analytics.mandal.map(obj => Object.values(obj)[0])} />

                    <PieChart labels={analytics.mandal.map(obj => Object.keys(obj)[0])} values={analytics.mandal.map(obj => Object.values(obj)[0])} />
                  </div>
                </div>
              </div>

              {/* <div>
                <div className="flex flex-col xl:col-span-2 justify-between flex-1">
                  <div>
                    <h2 className='text-lg font-extrabold'>19. Village-wise People Distribution</h2>

                    <Percentage labels={analytics.village.map(obj => Object.keys(obj)[0])} values={analytics.village.map(obj => Object.values(obj)[0])} totalDocs={totalDocs} />
                  </div>

                  <div className="w-full xl:max-h-[45dvh] xl:flex gap-10">
                    <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={analytics.village.map(obj => Object.keys(obj)[0])} values={analytics.village.map(obj => Object.values(obj)[0])} />

                    <PieChart labels={analytics.village.map(obj => Object.keys(obj)[0])} values={analytics.village.map(obj => Object.values(obj)[0])} />
                  </div>
                </div>
              </div> */}

              {
                // Check if the array length is greater than 40
                sortedBoothAnalytics.length > 0 && splitArray(sortedBoothAnalytics, 40).map((part, index) => {
                  return (
                    <div key={index}>
                      <div className="flex flex-col xl:col-span-2 justify-between flex-1">
                        <div>
                          <h2 className='text-lg font-extrabold'>
                            19.{index + 1}. Survey count as per the Booth Numbers from {Object.keys(part[0])} to {Object.keys(part[part.length - 1])}
                          </h2>

                          <Count labels={part.map(obj => Object.keys(obj)[0])} values={part.map(obj => Object.values(obj)[0])} type={"x"} />
                        </div>

                        <div className="w-full xl:max-h-[45dvh] xl:flex gap-10">
                          <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={part.map(obj => Object.keys(obj)[0])} values={part.map(obj => Object.values(obj)[0])
                          } />
                        </div>
                      </div>
                    </div>
                  )
                })
              }


              <div>
                {/* <SurveyAnalyticsMap locations={analytics.locations} /> */}
              </div>
            </>
        }

      </div>
    </section >
  )
}

export default SurveyAdmin