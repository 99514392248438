import { LuSettings2 } from "react-icons/lu";
import { FiRefreshCw } from "react-icons/fi";
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../../../components/global/Header";
import { getVoterSurveyAnalytics } from "../../../../store/extraReducers";
import BarGraph from "../../../../components/graphs/BarGraph";
import Percentage from "../../../../components/graphs/Percentage";
import PieChart from "../../../../components/graphs/PieChart";
import AnalyticsSkl from "../../../../components/skeletons/AnalyticsSkl";
import toast from "react-hot-toast";
import PsFilters from "./PsFilters";
import { IoCloseSharp } from "react-icons/io5";
import { Button } from "@mui/material";
// import VoterAnalyticsMap from "../../../../components/graphs/VoterAnalyticsMap";

const VoterAdmin = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.analytics)
  const { userDetails } = useSelector((store) => store.user);

  // const { totalDocs, locationDetails } = data
  const { totalDocs } = data

  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [ps, setPs] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  const constituencyCode = userDetails.constituencyCode;

  useEffect(() => {
    setLoading(true)

    dispatch(getVoterSurveyAnalytics()).then(action => {
      setLoading(false)

      if (!action.payload?.success) {
        toast.error(action.payload)
      }
    })
  }, [dispatch, constituencyCode])

  const getAnalytics = (e, ps) => {
    setLoading(true)
    setAnchorEl(e)

    toast.loading("Updating Analytics...");

    dispatch(getVoterSurveyAnalytics(ps)).then(action => {
      setLoading(false)
      setOpen(false)
      toast.dismiss();

      if (action.payload?.success) {
        toast.success("Analytics Updated")
      } else {
        toast.error(action.payload)
      }
    })
  }

  if (!totalDocs && totalDocs !== 0 && loading) return <AnalyticsSkl />

  if (!totalDocs && totalDocs !== 0) {
    return (
      <div className="flex min-h-[20dvh] my-10 bg-slate-50 mt-8 text-xl items-center justify-center w-full shadow-md border rounded-xl font-bold">
        <p>No data found 🗄️</p>
      </div>
    )
  }

  const analyticsFields = [
    {
      label: "Age group analytics",
      key: "ageGroupAnalysis",
      options: ["18-30", "31-40", "41-50", "51-60", "60+"]
    },
    {
      label: "Gender wise analytics",
      key: "genderAnalysis",
      options: ["Male", "Female", "Third Gender"]
    },
  ]

  return (
    <section className='flex flex-col max-h-screen'>
      <Header />

      <div className='overflow-y-scroll px-4 pt-6 h-full'>
        <h1 className='text-2xl font-semibold flex justify-between items-center poppins'>
          🚀 Survey Analytics

          <button
            id="refresh"
            disabled={loading && anchorEl?.id === "refresh"}
            onClick={(e) => getAnalytics(e.currentTarget)}
            className="p-2 px-3.5 bg-green-200 rounded-full flex gap-2 items-center"
          >
            <FiRefreshCw className={`${loading && anchorEl?.id === "refresh" && "animate-spin"} text-lg`} />
            <span className="text-sm">Refresh</span>
          </button>
        </h1>

        <div className="flex gap-8 lg:px-6 py-2 mt-8 items-stretch">
          <div className="bg-blue-50 rounded-3xl border shadow-sm p-6 w-full lg:w-max">
            <p className="w-max mx-auto">
              Survey collected for <span className="font-bold">{totalDocs}</span> people!
            </p>
          </div>

          <div className="hidden lg:flex items-center w-full rounded-3xl border divide-x-2 shadow-sm relative overflow-hidden">
            <div className="p-4">
              <button onClick={() => setOpen(true)} className="flex items-center gap-2.5 bg-blue-950 text-blue-50 px-6 py-2 rounded-2xl ring- ring-blue-400">
                <LuSettings2 /> Filter
              </button>
            </div>

            <div className="flex px-6 h-full items-center overflow-x-scroll max-w-[60dvw]">
              <div className="flex gap-4">
                {
                  ps.length !== 0 ?
                    ps.map((station, key) => {
                      return (
                        <Button
                          key={key}
                          sx={{
                            borderRadius: "10rem",
                            padding: 0,
                            textTransform: 'none'
                          }}
                          onClick={(e) => {
                            const newPs = ps.filter(item => item !== station)
                            setPs(newPs)
                            getAnalytics(e, newPs)
                          }
                          }
                        >
                          <div className="flex items-center gap-3 bg-blue-100/60 text-blue-500 min-w-max px-5 py-2 rounded-2xl ring-1 ring-blue-400 poppins">
                            <span>{station}</span>

                            <IoCloseSharp className="text-base" />
                          </div>
                        </Button>
                      )
                    })
                    :
                    <Button
                      sx={{ borderRadius: "10rem", padding: 0, textTransform: 'none' }}
                    >
                      <div className="flex items-center gap-3 bg-blue-100/60 text-blue-500 px-5 py-2 rounded-2xl ring-1 ring-blue-400 poppins">
                        <span>No Filters Selected</span>

                        <IoCloseSharp className="text-base" />
                      </div>
                    </Button>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='my-8 grid gap-8 text-lg font-semibold w-full'>
          {
            analyticsFields.map((field, i) => {
              var fieldData = data[field.key]

              return (
                <div key={i} className='flex flex-col gap-2 justify-between xl:borde xl:p-6 xl:rounded-lg w-full overflow-hidden'>
                  <div>
                    <h4 className='text-lg font-semibold poppins'>{i + 1}. {field.label}</h4>

                    <Percentage labels={field.options} data={fieldData} totalDocs={totalDocs} />
                  </div>

                  <div className="flex gap-10 lg:gap-8 overflow-x-scroll lg:snap-none lg:overflow-x-hidden snap-x w-full py-2">
                    <div className="flex snap-center w-full min-w-full lg:min-w-max">
                      <BarGraph type={screen.width > 1280 ? 'x' : 'x'} labels={field.options} data={fieldData} />
                    </div>

                    <div className="flex snap-center min-w-full lg:min-w-max">
                      <PieChart labels={field.options} data={fieldData} />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        {/* <div>
                <div className="flex flex-col xl:col-span-2 justify-between flex-1">
                  <div>
                    <h2 className='text-lg font-extrabold'>18. Mandal-wise People Distribution</h2>

                    <Percentage labels={analytics.mandal.map(obj => Object.keys(obj)[0])} values={analytics.mandal.map(obj => Object.values(obj)[0])} totalDocs={totalDocs} />
                  </div>

                  <div className="w-full xl:max-h-[45dvh] xl:flex gap-10">
                    <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={analytics.mandal.map(obj => Object.keys(obj)[0])} values={analytics.mandal.map(obj => Object.values(obj)[0])} />

                    <PieChart labels={analytics.mandal.map(obj => Object.keys(obj)[0])} values={analytics.mandal.map(obj => Object.values(obj)[0])} />
                  </div>
                </div>
              </div> */}

        {/* <div>
                <div className="flex flex-col xl:col-span-2 justify-between flex-1">
                  <div>
                    <h2 className='text-lg font-extrabold'>19. Village-wise People Distribution</h2>

                    <Percentage labels={analytics.village.map(obj => Object.keys(obj)[0])} values={analytics.village.map(obj => Object.values(obj)[0])} totalDocs={totalDocs} />
                  </div>

                  <div className="w-full xl:max-h-[45dvh] xl:flex gap-10">
                    <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={analytics.village.map(obj => Object.keys(obj)[0])} values={analytics.village.map(obj => Object.values(obj)[0])} />

                    <PieChart labels={analytics.village.map(obj => Object.keys(obj)[0])} values={analytics.village.map(obj => Object.values(obj)[0])} />
                  </div>
                </div>
              </div> */}

        {/* {
                // Check if the array length is greater than 40
                sortedBoothAnalytics.length > 0 && splitArray(sortedBoothAnalytics, 40).map((part, index) => {
                  return (
                    <div key={index}>
                      <div className="flex flex-col xl:col-span-2 justify-between flex-1">
                        <div>
                          <h2 className='text-lg font-extrabold'>
                            19.{index + 1}. Survey count as per the Booth Numbers from {Object.keys(part[0])} to {Object.keys(part[part.length - 1])}
                          </h2>

                          <Count labels={part.map(obj => Object.keys(obj)[0])} values={part.map(obj => Object.values(obj)[0])} type={"x"} />
                        </div>

                        <div className="w-full xl:max-h-[45dvh] xl:flex gap-10">
                          <BarGraph type={screen.width > 1280 ? 'x' : 'y'} labels={part.map(obj => Object.keys(obj)[0])} values={part.map(obj => Object.values(obj)[0])
                          } />
                        </div>
                      </div>
                    </div>
                  )
                })
              } */}


        {/* <div>
          <VoterAnalyticsMap locationDetails={locationDetails} />
        </div> */}
      </div>

      <PsFilters setOpen={setOpen} open={open} setPs={setPs} ps={ps} getAnalytics={getAnalytics} setAnchorEl={setAnchorEl} />
    </section >
  )
}

export default VoterAdmin