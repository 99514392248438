import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const VoterSurveyDetailsForm = ({ setSurveyId, surveyId, handleAudioPlay }) => {
  const { surveys } = useSelector((store) => store.voters);
  const { userDetails } = useSelector((store) => store.user);

  const voter = surveys?.filter((survey) => survey._id === surveyId)[0] || {}

  const { name, voterId, age, houseNumber, gender, fathersName, husbandsName, mothersName, others, survey = {}, recording } = voter

  const surveyQnA = Object.entries(survey).map((res) => {
    return ({
      question: res[0], answer: res[1]
    })
  }) || []

  const specialQuestions = ["fieldsCorrect", "incorrectFields", "corrected"];

  const otherQuestionsArray = surveyQnA.filter(q => !specialQuestions.includes(q.question));

  return (
    <Dialog
      open={surveyId ? true : false}
      onClose={() => {
        setSurveyId(null)
      }}
      PaperProps={{
        style: {
          width: "100%",
          margin: "1rem",
          borderRadius: "1rem",
          padding: "1rem 0 0 0",
        },
      }}
    >
      <DialogTitle className="flex">
        <p className="text-slate-800 font-bold first-letter:uppercase mulish">
          Survey Details
        </p>
      </DialogTitle>

      <DialogContent dividers>
        <div>
          <p className="flex items-center gap-1.5 my-2 font-semibold">
            <span>Voter Details</span> <AiOutlineInfoCircle />
          </p>

          <div className="flex flex-col items-start first-letter:uppercase font-semibold mt-2 mb-3 bg-gray-100 rounded-xl p-4">
            <p>
              <span className='font-bold'>
                Name:
              </span>
              {" "}
              <span className='text-gray-500'>
                {name}
              </span>
            </p>

            <p>
              <span className='font-bold'>
                Voter ID:
              </span>
              {" "}
              <span className='text-gray-500'>
                {
                  voterId || "N/A"
                }
              </span>
            </p>

            {
              Object.entries(survey).length > 0 &&
              <>
                <p>
                  <span className='font-bold'>
                    {
                      (fathersName && "Fathers") ||
                      (mothersName && "Mothers") ||
                      (husbandsName && "Husbands") ||
                      (others && "Others")
                    } Name:
                  </span>
                  {" "}
                  <span className='text-gray-500'>
                    {
                      fathersName || mothersName || husbandsName || others || "Not Available"
                    }
                  </span>
                </p>

                <p>
                  <span className='font-bold'>
                    Age:
                  </span>
                  {" "}
                  <span className='text-gray-500'>
                    {age}
                  </span>
                </p>

                <p>
                  <span className='font-bold'>
                    Gender:
                  </span>
                  {" "}
                  <span className='text-gray-500'>
                    {gender}
                  </span>
                </p>
              </>
            }

            <p>
              <span className='font-bold'>
                House Number:
              </span>
              {" "}
              <span className='text-gray-500'>
                {
                  houseNumber && houseNumber !== "" ?
                    houseNumber : "Not available"
                }
              </span>
            </p>
          </div>
        </div>

        <div>
          <p className="flex items-center gap-1.5 my-2 font-semibold">
            <span>Survey Summary</span> <AiOutlineInfoCircle />
          </p>

          <div className="flex flex-col gap-4 items-start font-semibold bg-gray-100 rounded-xl p-4">
            {
              survey.fieldsCorrect &&
              <div>
                <p>
                  <span className='font-bold'>
                    Are pre-filled voter details correct:
                  </span>
                  {" "}
                  <span className='text-gray-500'>
                    {survey.fieldsCorrect}
                  </span>
                </p>

                {
                  survey.fieldsCorrect === "No" && (
                    <>
                      <p>
                        <span className='font-bold'>
                          Which fields are incorrect:
                        </span>
                        {" "}
                        <span className='text-gray-500'>
                          {survey.incorrectFields.join(", ")}
                        </span>
                      </p>

                      <div>
                        <h5 className='font-bold mt-2 poppins'>Corrected Fields:</h5>

                        <div className='ml-4'>
                          {
                            Object.entries(survey.corrected).map(([que, ans], key) => (
                              <p key={key}>
                                <span className='font-bold'>
                                  {que}:
                                </span>
                                {" "}
                                <span className='uppercase text-gray-500'>
                                  {ans}
                                </span>
                              </p>
                            ))
                          }
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            }

            {
              Object.entries(survey).length > 0 &&
              <div>
                <h5 className='font-bold poppins'>Survey QnA:</h5>

                <div className='ml-4'>
                  {
                    otherQuestionsArray.map((QnA, key) => {
                      return (
                        <p key={key}>
                          <span className='font-bold'>
                            {QnA.question}:
                          </span>
                          {" "}
                          <span className='text-gray-500'>
                            {QnA.answer}
                          </span>
                        </p>
                      )
                    })
                  }
                </div>
              </div>
            }

            {
              [7798704499, 9121786668, 7827435791, 9121886668].includes(userDetails.mobile) &&
              <div>
                <h5 className='font-bold poppins'>Recording:</h5>

                <audio className='border-2 border-gray-300 mt-2 rounded-xl' id={survey._id} controls src={recording} type="audio/wav" onPlay={() => handleAudioPlay(survey._id)}></audio>
              </div>
            }
          </div>
        </div>

      </DialogContent>

      <DialogActions>
        <button
          type="button"
          onClick={() => {
            setSurveyId(null)
          }}
          className="flex-1 flex sm:w-auto justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
        >
          Okay
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default VoterSurveyDetailsForm