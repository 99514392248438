import { createSlice } from "@reduxjs/toolkit";
import { addSurvey, getSurveys } from "../extraReducers";

const surveySlice = createSlice({
	name: "surveys",
	initialState: {
		data: {},
		surveys: [],
		status: "idle",
		error: "",
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSurveys.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getSurveys.fulfilled, (state, action) => {
				state.status = "succeeded";

				const { surveys, ...remainingInfo } = action.payload;

				state.surveys =
					remainingInfo.currentPage !== 1
						? [...state.surveys, ...surveys]
						: [...surveys];

				state.data = remainingInfo;
			})
			.addCase(getSurveys.rejected, (state) => {
				state.status = "failed";
			})
			
			.addCase(addSurvey.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addSurvey.fulfilled, (state) => {
				state.status = "succeeded";
			})
			.addCase(addSurvey.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export default surveySlice.reducer;
