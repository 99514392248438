import { useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux";
import { RiUserAddLine } from "react-icons/ri";
import { getAdminForm, getAdmins, removeAdmin } from "../../../../../store/extraReducers";
import toast from "react-hot-toast";
import AdminForm from "./AdminForm";
import AdminDetailsCard from "./AdminDetailsCard";

const AdminList = () => {
  const dispatch = useDispatch();

  const { admins } = useSelector((store) => store.admins);

  const [loading, setLoading] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const getFormDatafunc = (e) => {
    setLoading(true)
    setAnchorEl(e)
    !e?.id && toast.loading("Genrating Admin Form")

    dispatch(getAdminForm(e?.id)).then(action => {
      setLoading(false)
      toast.dismiss()

      if (action.payload?.success) {
        setFormOpen(true);
      } else {
        toast.error("Please upload a voter list");
      }
    })
  }

  const removeUserFunc = (e) => {
    setLoading(true)
    setAnchorEl(e)

    dispatch(removeAdmin(e.id)).then(action => {
      setLoading(false)

      if (action.payload.success) {
        const name = action.payload.removedUser.name
        toast.success(`Admin - ${name}, was  removed`)
      } else {
        toast.error(action.payload)
      }
    })
  }

  const refresh = (e) => {
    setLoading(true)
    setAnchorEl(e)

    dispatch(getAdmins()).then(action => {
      setLoading(false)

      if (!action.payload.success) {
        toast.error(action.payload)
      }
    })
  }

  return (
    <div className="flex flex-col p-4 sm:p-8 h-full overflow-y-scroll">
      <div className="flex justify-between items-center">
        <h1 className='text-2xl font-bold'>
          Admin Manager
        </h1>

        <button id="refresh" onClick={(e) => refresh(e.currentTarget)} disabled={loading && anchorEl?.id === "refresh"} className="p-2 px-4 bg-violet-200 text-violet-900 font-bold rounded-full flex gap-2 items-center">
          <FiRefreshCw className={`${loading && anchorEl?.id === "refresh" && "animate-spin"} text-lg`} /> <span className="text-sm">Refresh</span>
        </button>
      </div>

      <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5 py-8">
        <button
          onClick={() => getFormDatafunc()}
          className={`flex w-full h-full lg:h-36 shadow-md border-2 border-dashed border-slate-500 rounded-2xl gap-2 bg-slate-50 items-center justify-center text-slate-500 p-2 focus:outline-none`}
        >
          <div
            className={`p-2 mr-3 inline-flex items-center justify-center rounded-full text-lg sm:text-2xl`}
          >
            <RiUserAddLine />
          </div>
          <div className="font-semibold">Add New Admin</div>
        </button>

        {
          admins.map((admin, key) => {
            return (
              <AdminDetailsCard key={key} admin={admin} getFormDatafunc={getFormDatafunc} removeUserFunc={removeUserFunc} loading={loading} anchorEl={anchorEl} />
            );
          })
        }
      </div>

      <AdminForm formOpen={formOpen} setFormOpen={setFormOpen} />
    </div>
  )
}

export default AdminList