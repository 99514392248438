import logo from "../assets/images/png/logo.png"

const Loader = () => {
    return (
        <section className="h-screen flex justify-center items-center bg-white 2xl:border-t 2xl:border-t-slate-300 gap-3 pb-24">
            <div className="w-48 drop-shadow-2xl">
                <img className="w-full h-full animate-pulse" src={logo} alt="logo" />
            </div>
        </section>
    );
}

export default Loader