// Importing necessary React components for the Dashboard page
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { UploadVotersListSkl } from "../../../../components/skeletons/UploadVoterListSkl";
import Header from "../../../../components/global/Header";
import UploadedVotersList from "./UploadedVotersList";
import UploadedVoterAnalytics from "./UploadedVoterAnalytics";
import { getVoters } from "../../../../store/extraReducers";
import toast from "react-hot-toast";

const UploadVoters = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true)
	const [page, setPage] = useState(1)
	const [keyword, setKeyword] = useState('')
	// const [error, setError] = useState(false)


	const { userDetails } = useSelector((store) => {
		return store.user;
	});

	const { voters, data } = useSelector((state) => state.voters);

	const scrollableDivRef = useRef(null);

	const getVotersFunc = (page, keyword) => {
		dispatch(getVoters({ page, keyword })).then(action => {

			if (!action.payload.success) {
				// setError(true)
				toast.error(action.payload)
			}
		})
	}

	const handleScroll = (event) => {
		if (voters.length < data.totalSurveysRemaining) {
			const { target } = event;
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				if (target.scrollHeight - target.scrollTop - 200 < target.clientHeight) {
					getVotersFunc(page + 1, keyword)
					setPage((prevPage) => prevPage + 1);
					// setQuery(`page=${page + 1}&q=${keyword}`)
				}
			}, 50);
		}
	};

	useEffect(() => {
		scrollableDivRef.current.scrollTop = 0;
		setLoading(true)

		dispatch(getVoters({ page: 1 })).then(action => {
			setLoading(false)

			if (!action.payload.success) {
				toast.error(action.payload)
			}
		})
	}, [dispatch, userDetails])

	let timeoutId;

	return (
		<section className='flex flex-col h-screen'>
			<Header />

			<section className="flex flex-col p-4 sm:p-8 flex-1 h-full overflow-y-scroll" onScroll={(e) => handleScroll(e)} ref={scrollableDivRef}>
				{
					loading ?
						<UploadVotersListSkl />
						:
						<>
							<UploadedVoterAnalytics />
							<UploadedVotersList setKeyword={setKeyword} getVotersFunc={getVotersFunc} />
						</>
				}
			</section>
		</section>
	);
};

export default UploadVoters;
