import { FiRefreshCw } from "react-icons/fi";
import Header from "./components/global/Header";
import { useSelector } from "react-redux";

const Error = () => {
    const { isAuthenticated } = useSelector((state) => state.user);

    return (
        <section>
            {
                isAuthenticated && <Header />
            }

            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-justify">
                    <h1 className="mb-0 text-6xl tracking-tight font-extrabold lg:text-8xl text-slate-900">Error 500</h1>

                    <p className="ml-1 mb-2">Something went wrong</p>

                    <div className="border w-full"></div>

                    <p className="my-4 text-lg font-light text-slate-800">
                        We apologize for the inconvenience, but it seems that our server is currently experiencing some technical difficulties. Our team has been notified and is working diligently to resolve the issue. In the meantime, here are a few things you can try:



                        If you continue to encounter this error, or if you have any urgent concerns, please don&apos;t hesitate to contact us for assistance.

                        We appreciate your patience and understanding.
                    </p>

                    <ol className="px-4 list-decimal">
                        <li>
                            <b>
                                Refresh the page
                            </b>
                            : Sometimes, a simple refresh can help resolve temporary glitches.
                        </li>

                        <li>
                            <b>
                                Return to the previous page
                            </b>
                            : You can use your browser&apos;s back button or navigate to a different page on our site.
                        </li>

                        <li>
                            <b>
                                Check back later
                            </b>
                            : Our team is actively working to fix the problem. Please try again later.
                        </li>
                    </ol>

                    <button
                        type="button"
                        onClick={() => {
                            location.reload();
                        }}
                        className="flex-1 flex sm:w-64 mt-6 justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
                    >
                        <FiRefreshCw />
                        Refresh
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Error;
