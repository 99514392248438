import { useEffect, useState } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from './store/extraReducers';
import Loader from './components/Loader';
import { Toaster } from 'react-hot-toast';
import Error from './Error';
import VoterAdmin from './Pages/admin/voterAdmin/dashboard/VoterAdmin';
import SurveyAdmin from './Pages/admin/surveyAdmin/SurveyAdmin';
import SurveyAgent from './Pages/agent/surveyAgent/SurveyAgent';
import Sidebar from './components/global/Sidebar';
import { setSidebarActive } from './store/slices/sidebarSlice';
import UploadVoters from './Pages/admin/superAdmin/uploadVoters/UploadVoters';
import SuperAdmin from './Pages/admin/superAdmin/dashboard/SuperAdmin';
import ProtectedRoutes from './Pages/auth/ProtectedRoutes';
import AdminManager from './Pages/admin/superAdmin/adminManager/AdminManager';
import AgentManager from './Pages/admin/common/AgentManager';
import VoterSurveys from './Pages/admin/voterAdmin/surveys/VoterSurveys';
import VoterAgent from './Pages/agent/voterAgent/dashboard/VoterAgent';
import CompletedSurveys from './Pages/agent/voterAgent/completedSurveys/CompletedSurveys';


const App = () => {
  const { userDetails, isAuthenticated } = useSelector((store) => store.user);
  const [loading, setLoading] = useState(true)

  const { active: sidebarActive } = useSelector((store) => store.sidebar);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)

    if (!isAuthenticated) {
      dispatch(authenticateUser()).then(() => {
        setLoading(false)
      })
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, dispatch, navigate]);

  const renderUserRole = () => {
    switch (userDetails.role) {
      case 'voterAdmin':
        return <VoterAdmin />;
      case 'voterAgent':
        return <VoterAgent />;
      case 'surveyAdmin':
      case 'guestAdmin':
        return <SurveyAdmin />;
      case 'surveyAgent':
        return <SurveyAgent />;
      case 'superAdmin':
        return <SuperAdmin />;
      default:
        return <Error />;
    }
  };

  if (loading) return <Loader />

  return (
    <section className='app'>
      <div className="h-screen flex lg:gap-2 text-gray-900 overflow-hidden 2xl:border-t 2xl:border-t-slate-300 bg-slate-100">

        {
          isAuthenticated &&
          <div
            onClick={(e) => (e.target === e.currentTarget) && dispatch(setSidebarActive(false))}
            className={`${sidebarActive ? "w-full" : "-translate-x-full"
              } backdrop-blur-sm bg-slate-50/70 fixed lg:static lg:translate-x-0 lg:shadow-none h-full lg:w-max lg:h-auto ease-in-out duration-500 z-[9999]`}
          >
            <Sidebar />
          </div>
        }

        <div className={`relative w-full ${sidebarActive && "lg:ml-2"} lg:rounded-lg bg-slate-50`}>
          <ProtectedRoutes>
            <Route path='/' element={renderUserRole()} roles={userDetails.role} />

            <Route path='/surveys' element={< VoterSurveys />} roles={['voterAdmin']} />

            <Route path='/admins' element={< AdminManager />} roles="superAdmin" />

            <Route path='/uploadVoters' element={< UploadVoters />} roles="superAdmin" />

            <Route path='/completed' element={< CompletedSurveys />} roles={["voterAgent", "surveyAgent"]} />

            <Route path='/agents' element={<AgentManager />} roles={["voterAdmin", "surveyAdmin", "superAdmin"]} />
          </ProtectedRoutes>
        </div>
      </div>

      <Toaster reverseOrder={true} />
    </section >
  );
};

export default App;
