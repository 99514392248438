import { FiUsers } from "react-icons/fi";
import { RiSurveyLine } from "react-icons/ri";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiSwitchHorizontal } from "react-icons/hi";
import { IoAnalyticsOutline } from "react-icons/io5";
import { FaUsersCog } from "react-icons/fa";
import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarActive } from "../../store/slices/sidebarSlice";
import { getConstituencies, switchConstituency, switchSurveyType } from "../../store/extraReducers";
import { Menu } from "@mui/material";
import toast from "react-hot-toast";

const Sidebar = () => {
	const { userDetails } = useSelector((store) => store.user);
	const { constituencies } = useSelector((store) => store.constituency);
	const { active: sidebarActive } = useSelector((store) => store.sidebar);

	const [constituencySwitch, setConstituencySwitch] = useState(null);
	const [surveyTypeSwitch, setSurveyTypeSwitch] = useState(null);

	const [loading, setLoading] = useState(true);

	const constituencyButtonRef = useRef(null);
	const surveyButtonRef = useRef(null);

	const constituencySwitchOpen = Boolean(constituencySwitch);
	const surveyTypeSwitchOpen = Boolean(surveyTypeSwitch);

	const dispatch = useDispatch();

	const handleClose = () => {
		setConstituencySwitch(null);
		setSurveyTypeSwitch(null);
	};

	const getSurveyConstituenciesFunc = () => {
		setLoading(true);
		setConstituencySwitch(constituencyButtonRef.current)

		dispatch(getConstituencies()).then(action => {
			setLoading(false);

			if (!action.payload.success) {
				handleClose();
				toast.error(action.payload);
			}
		});
	};

	const switchConstituencyFunc = (code) => {
		dispatch(switchConstituency(code)).then(action => {
			if (action.payload.success) {
				handleClose();
				dispatch(setSidebarActive(false));
			} else {
				toast.error(action.payload);
			}
		});
	};

	const openSurveyTypeSwitch = () => {
		setSurveyTypeSwitch(surveyButtonRef.current);
		setLoading(false)
	}

	const switchSurveyTypeFunc = (type) => {
		dispatch(switchSurveyType(type)).then(action => {
			if (action.payload.success) {
				handleClose();
				dispatch(setSidebarActive(false));
			} else {
				toast.error(action.payload);
			}
		});
	};

	// const downloadCSV = () => {
	// 	dispatch(setSidebarActive(false));
	// 	toast.loading("Requesting for survey analytics");

	// 	dispatch(getCsv()).then(action => {
	// 		toast.dismiss();

	// 		if (action.payload.success) toast.success("Download started")
	// 		else toast.error(action.payload)
	// 	})
	// }

	return (
		<div
			className={`${sidebarActive && "shadow-2xl"
				} w-max h-full lg:shadow-none bg-slate-50 transition-all duration-500 flex flex-col justify-between overflow-y-auto relative`}
		>
			<div className="sidebar h-full">
				<div className="p-5 flex flex-col gap-5 text-slate-500">
					<div className="text-slate-500 rounded-2xl bg-slate-200 p-2">
						<p className="text-xs font-semibold text-center text-wrap w-full">
							Constituency <br />
							Code - <b>{userDetails.constituencyCode}</b>
						</p>
					</div>

					<NavLink
						to="/"
						style={({ isActive }) => ({
							border: isActive && "2px solid #475569",
							color: isActive && "#020617",
							background: isActive && "#f1f5f9",
							borderRadius: isActive && "20px",
						})}
						className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center hover:bg-slate-200/60 rounded-2xl bg-slate-200/40"
						onClick={() => dispatch(setSidebarActive(false))}
					>
						<div>
							<IoAnalyticsOutline className="text-xl" />
						</div>

						<div>
							<p className="text-xs font-semibold text-center">Dashboard</p>
						</div>
					</NavLink>

					{
						["voterAgent", "surveyAgent"].includes(userDetails.role) &&
						<>
							<NavLink
								to="https://electoralsearch.eci.gov.in"
								target="_blank"
								style={({ isActive }) => ({
									border: isActive && "2px solid rgb(124 58 237)",
									color: isActive && "rgb(124 58 237)",
									background: isActive && "rgb(245 243 255)",
									borderRadius: isActive && "20px",
								})}
								className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-violet-600 hover:bg-violet-200/60 rounded-2xl bg-violet-200/40"
								onClick={() => dispatch(setSidebarActive(false))}
							>
								<div>
									<FiUsers className="text-xl" />
								</div>

								<div>
									<p className="text-xs font-semibold text-center">Find Missing <br /> Voter</p>
								</div>
							</NavLink>

							<NavLink
								to="/completed"
								style={({ isActive }) => ({
									border: isActive && "2px solid rgb(5 150 105)",
									color: isActive && "rgb(5 150 105)",
									background: isActive && "rgb(167 243 208 / 0.4)",
									borderRadius: isActive && "20px",
								})}
								className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-emerald-600 hover:bg-emerald-200/60 rounded-2xl bg-emerald-200/40"
								onClick={() => dispatch(setSidebarActive(false))}
							>
								<div>
									<FiUsers className="text-xl" />
								</div>

								<div>
									<p className="text-xs font-semibold text-center">Completed <br /> Surveys</p>
								</div>
							</NavLink>
						</>
					}

					{
						userDetails.role === "superAdmin" &&
						<>
							{/* Recordings
						// <NavLink
						// 	to="/recordings"
						// 	style={({ isActive }) => ({
						// 		border: isActive && "2px solid rgb(220 38 38)",
						// 		color: isActive && "rgb(220 38 38)",
						// 		background: isActive && "rgba(254 202 202 / 0.4)",
						// 		borderRadius: isActive && "20px",
						// 	})}
						// 	className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-red-600 hover:bg-red-200/60 rounded-2xl bg-red-200/40"
						// 	onClick={() => dispatch(setSidebarActive(false))}
						// >
						// 	<div>
						// 		<BiMicrophone className="text-xl" />
						// 	</div>
						// 	<div>
						// 		<p className="text-xs font-semibold text-center">Recordings</p>
						// 	</div>
						// </NavLink> */}

							{/* Constituency Switch */}
							<button
								ref={constituencyButtonRef}
								onClick={() => getSurveyConstituenciesFunc()}
								className={`flex hover:bg-blue-200/60 text-blue-500 rounded-2xl bg-blue-200/40 cursor-pointer ${constituencySwitchOpen && "group"}`}
							>
								<div
									className="flex w-full flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center"
								>
									<div>
										<HiSwitchHorizontal className="text-xl" />
									</div>
									<p className="text-xs font-semibold text-center text-wrap w-full">Constituency <br /> switch</p>
								</div>
							</button>

							{/* Constituency Switch Menu */}
							<Menu
								anchorEl={constituencySwitch}
								id="account-menu"
								open={constituencySwitchOpen}
								onClose={handleClose}
								PaperProps={{
									sx: {
										overflow: "hidden",
										background: "#f8fafc",
										filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
										boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
										borderRadius: "1rem",
										ml: 5,
										padding: 0
									},
								}}
								anchorOrigin={{ horizontal: "right", vertical: "top" }}
							>
								<div className={`grid ${constituencies?.length > 2 ? "grid-cols-2 md:grid-cols-3 overflow-y-scroll" : "grid-flow-col"} gap-2 px-2 max-h-[25dvh] md:max-h-[26dvh] rounded-2xl text-gray-700`}>
									{
										loading ?
											<div className="flex m-2">
												<AiOutlineLoading3Quarters className="text-xl animate-spin m-auto" />
											</div>

											:

											constituencies.map((code, key) => {
												return (
													<button key={key} disabled={userDetails.constituencyCode === code} onClick={() => {
														dispatch(switchConstituencyFunc(code))
													}}

														className={`${(userDetails.constituencyCode === code) ? "bg-blue-200/60 border-blue-300 text-blue-500" : "bg-slate-200"} w-20 h-16 md:w-24 border-2 hover:border-blue-300 hover:text-blue-500 transition-all duration-300 hover:bg-blue-200/60 rounded-2xl`
														}
													>
														<p>
															{code}
														</p>
														{
															userDetails.constituencyCode === code &&
															<p className="text-xs">
																Selected
															</p>
														}
													</button>
												)
											})
									}
								</div>
							</Menu>
						</>
					}
				</div>


				{["surveyAdmin", "voterAdmin"].includes(userDetails.role) ?
					(
						<div className="border-t border-gray-400 border-dashed flex flex-col mx-5 gap-5">
							<div className="m-auto mt-5">
								<p className="text-slate-50 bg-slate-950 text-xs rounded-full px-2">
									{userDetails.role === "surveyAdmin" ? "Survey Admin" : "Voter Admin"}
								</p>
							</div>

							<button
								ref={constituencyButtonRef}
								onClick={() => getSurveyConstituenciesFunc()}
								className={`flex hover:bg-blue-200/60 text-blue-500 rounded-2xl bg-blue-200/40 cursor-pointer ${constituencySwitchOpen && "group"}`}
							>
								<div
									className="flex w-full flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center"
								>
									<div>
										<HiSwitchHorizontal className="text-xl" />
									</div>
									<p className="text-xs font-semibold text-center text-wrap w-full">Constituency <br /> switch</p>
								</div>
							</button>

							{/* Constituency Switch Menu */}
							<Menu
								anchorEl={constituencySwitch}
								id="account-menu"
								open={constituencySwitchOpen}
								onClose={handleClose}
								PaperProps={{
									sx: {
										overflow: "hidden",
										background: "#f8fafc",
										filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
										boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
										borderRadius: "1rem",
										ml: 5,
										padding: 0
									},
								}}
								anchorOrigin={{ horizontal: "right", vertical: "top" }}
							>
								<div className={`grid ${constituencies?.length > 2 ? "grid-cols-2 md:grid-cols-3 overflow-y-scroll" : "grid-flow-col"} gap-2 px-2 max-h-[25dvh] md:max-h-[26dvh] rounded-2xl text-gray-700`}>
									{
										loading ?
											<div className="flex m-2">
												<AiOutlineLoading3Quarters className="text-xl animate-spin m-auto" />
											</div>

											:

											constituencies.map((code, key) => {
												return (
													<button key={key} disabled={userDetails.constituencyCode === code} onClick={() => {
														dispatch(switchConstituencyFunc(code))
													}}

														className={`${(userDetails.constituencyCode === code) ? "bg-blue-200/60 border-blue-300 text-blue-500" : "bg-slate-200"} w-20 h-16 md:w-24 border-2 hover:border-blue-300 hover:text-blue-500 transition-all duration-300 hover:bg-blue-200/60 rounded-2xl`
														}
													>
														<p>
															{code}
														</p>
														{
															userDetails.constituencyCode === code &&
															<p className="text-xs">
																Selected
															</p>
														}
													</button>
												)
											})
									}
								</div>
							</Menu>

							{/* Surveys */}
							<NavLink
								to="/surveys"
								style={({ isActive }) => ({
									border: isActive && "2px solid rgb(220 38 38)",
									color: isActive && "rgb(220 38 38)",
									background: isActive && "rgba(254 202 202 / 0.4)",
									borderRadius: isActive && "20px",
								})}
								className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-red-600 hover:bg-red-200/60 rounded-2xl bg-red-200/40"
								onClick={() => dispatch(setSidebarActive(false))}
							>
								<div>
									<RiSurveyLine className="text-xl" />
								</div>
								<div>
									<p className="text-xs font-semibold text-center">Surveys</p>
								</div>
							</NavLink>

							{/* Agents */}
							<NavLink
								to="/agents"
								style={({ isActive }) => ({
									border: isActive && "2px solid rgb(124 58 237)",
									color: isActive && "rgb(124 58 237)",
									background: isActive && "rgb(245 243 255)",
									borderRadius: isActive && "20px",
								})}
								className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-violet-600 hover:bg-violet-200/60 rounded-2xl bg-violet-200/40"
								onClick={() => dispatch(setSidebarActive(false))}
							>
								<div>
									<FaUsersCog className="text-xl" />
								</div>

								<div>
									<p className="text-xs font-semibold text-center">Agents</p>
								</div>
							</NavLink>

							{/* Download */}
							{/* <button
								className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center hover:bg-green-200/60 text-green-500 rounded-2xl bg-green-200/40"
								onClick={downloadCSV}
							>
								<div>
									<BsFiletypeCsv className="text-xl" />
								</div>

								<div className="w-min">
									<p className="text-xs font-semibold text-center text-wrap w-full">Download</p>
								</div>
							</button> */}
						</div>
					)
					:
					userDetails.role === "superAdmin" &&
					<div className="border-t border-gray-400 border-dashed flex flex-col mx-5 gap-5">
						<div className="m-auto mt-5">
							<p className="text-slate-50 bg-slate-950 text-xs rounded-full px-2">
								Super Admin
							</p>
						</div>

						<button
							ref={surveyButtonRef}
							onClick={openSurveyTypeSwitch}
							className={`flex hover:bg-yellow-200/60 text-yellow-700 rounded-2xl bg-yellow-200/40 cursor-pointer ${surveyTypeSwitchOpen && "group"}`}
						>
							<div
								className="flex w-full flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center"
							>
								<div>
									<HiSwitchHorizontal className="text-xl" />
								</div>
								<p className="text-xs font-semibold text-center text-wrap w-full">Survey-Type <br />switch</p>
							</div>
						</button>

						{/* Survey-Type switch */}
						<Menu
							anchorEl={surveyTypeSwitch}
							id="account-menu"
							open={surveyTypeSwitchOpen}
							onClose={handleClose}
							PaperProps={{
								sx: {
									overflow: "hidden",
									background: "#f8fafc",
									filter: "drop-shadow(0px, 2px, 8px, rgba(0,0,0,0.12))",
									boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
									borderRadius: "1rem",
									ml: 5,
									padding: 0
								},
							}}
							anchorOrigin={{ horizontal: "right", vertical: "top" }}
						>
							<div className={`grid ${constituencies?.length > 2 ? "grid-cols-2 md:grid-cols-3 overflow-y-scroll" : "grid-flow-col"} gap-2 px-2 max-h-[25dvh] md:max-h-[26dvh] rounded-2xl text-gray-700`}>
								{
									loading ?
										<div className="flex m-2">
											<AiOutlineLoading3Quarters className="text-xl animate-spin m-auto" />
										</div>

										:

										<>
											<button disabled={userDetails.surveyType === "basic"} onClick={() => {
												switchSurveyTypeFunc("basic")
											}}
												className={`${(userDetails.surveyType === "basic") ? "bg-blue-200/60 border-blue-300 text-blue-500" : "bg-slate-200"} w-20 h-16 md:w-24 border-2 hover:border-blue-300 hover:text-blue-500 transition-all duration-300 hover:bg-blue-200/60 rounded-2xl`
												}
											>
												<p>
													Basic
												</p>
												{
													userDetails.surveyType === "basic" &&
													<p className="text-xs">
														Selected
													</p>
												}
											</button>

											<button disabled={userDetails.surveyType === "voter"} onClick={() => {
												switchSurveyTypeFunc("voter")
											}}
												className={`${(userDetails.surveyType === "voter") ? "bg-blue-200/60 border-blue-300 text-blue-500" : "bg-slate-200"} w-20 h-16 md:w-24 border-2 hover:border-blue-300 hover:text-blue-500 transition-all duration-300 hover:bg-blue-200/60 rounded-2xl`
												}
											>
												<p>
													Voter
												</p>
												{
													userDetails.surveyType === "voter" &&
													<p className="text-xs">
														Selected
													</p>
												}
											</button>
										</>
								}
							</div>
						</Menu>

						{/* Admins */}
						<NavLink
							to="/admins"
							style={({ isActive }) => ({
								border: isActive && "2px solid rgb(124 58 237)",
								color: isActive && "rgb(124 58 237)",
								background: isActive && "rgb(245 243 255)",
								borderRadius: isActive && "20px",
							})}
							className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-violet-600 hover:bg-violet-200/60 rounded-2xl bg-violet-200/40"
							onClick={() => dispatch(setSidebarActive(false))}
						>
							<div>
								<FaUsersCog className="text-xl" />
							</div>

							<div>
								<p className="text-xs font-semibold text-center">Admins</p>
							</div>
						</NavLink>

						{/* Upload Voter Data */}
						{
							userDetails.surveyType === "voter" &&
							<NavLink
								to="/uploadVoters"
								className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center text-teal-600 hover:bg-teal-200/60 rounded-2xl bg-teal-200/40"
								onClick={() => dispatch(setSidebarActive(false))}
							>
								<div>
									<AiOutlineCloudUpload className="text-xl" />
								</div>

								<div>
									<p className="text-xs font-semibold text-center">Upload Voter <br /> Sheet</p>
								</div>
							</NavLink>
						}

						{/* Download */}
						{/* <button
							className="flex flex-col border-2 transition-all duration-300 h-20 border-transparent p-2 px-4 items-center gap-2 justify-center hover:bg-green-200/60 text-green-500 rounded-2xl bg-green-200/40"
							onClick={downloadCSV}
						>
							<div>
								<BsFiletypeCsv className="text-xl" />
							</div>

							<div className="w-min">
								<p className="text-xs font-semibold text-center text-wrap w-full">Download</p>
							</div>
						</button> */}
					</div>
				}

				{/* Button to toggle the sidebar visibility for smaller screens */}
				{/* <div className="flex lg:hidden m-auto py-8 fixed z-10 bg-black">
					<button
						className="bg-slate-20 text-slate-600 bg-gray-300 w-max m-auto justify-center p-2 rounded-full mt-auto"
						onClick={() => dispatch(setSidebarActive(false))}
					>
						<AiOutlineLeft />
					</button>
				</div> */}
			</div>
		</div>



	);
};

// Exporting the Sidebar component as the default export
export default Sidebar;
