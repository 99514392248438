import { useSelector } from "react-redux";
import VoterAgentManager from "../voterAdmin/agentManager/VoterAgentManager";
import SurveyAgentManager from "../surveyAdmin/agentManager/SurveyAgentManager";

const AgentManager = () => {
    const { userDetails } = useSelector((store) => store.user);
    const role = userDetails.role
    const type = userDetails?.surveyType

    if (role === "surveyAdmin" || type === "survey") {
        return <SurveyAgentManager />
    } else {
        return <VoterAgentManager />

    }
}

export default AgentManager