import { useEffect, useState } from 'react'
import Header from "../../../components/global/Header";
import VoterList from './components/SurveyList';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveys } from '../../../store/extraReducers';
import toast from 'react-hot-toast';
import SurveyListSkl from '../../../components/skeletons/SurveyListSkl';

const SurveyAgent = () => {
    const { surveys } = useSelector((state) => state.surveys);

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true)
    
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)

        dispatch(getSurveys(page)).then(action => {
            setLoading(false)

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }, [dispatch, page]);

    let timeoutId;

    const handleScroll = (event) => {
        const { target } = event;
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            if (target.scrollHeight - target.scrollTop - 50 < target.clientHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        }, 50);
    };

    return (
        <section className='flex flex-col max-h-[100dvh]'>
            <Header />
            {
                (surveys.length < 1 && loading) ?
                    <SurveyListSkl /> :
                    <>
                        <VoterList handleScroll={handleScroll} />
                    </>

            }
        </section>
    )
}

export default SurveyAgent