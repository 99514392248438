import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const AgentAnalytics = () => {
	const { data } = useSelector((state) => state.voters);
	// Conditionally render the chart components only when data is available
	const completedVoterProfiles = data.totalSurveysTaken

	const incompleteVoterProfiles = data.totalSurveysRemaining

	const totalProfiles = completedVoterProfiles + incompleteVoterProfiles;

	const formData = {
		labels: ["Completed", "Remaining"],
		datasets: [
			{
				label: "No. of Voters",
				data: [completedVoterProfiles, incompleteVoterProfiles],
				backgroundColor: ["rgba(51, 65, 85, 1)", "rgba(51, 65, 85, 0.2)"],
				borderColor: ["rgba(22, 163, 74, 0.5)", "rgba(163, 163, 163, 1)"],
				borderWidth: 1,
			},
		],
	};

	return (
		<section className="sm:bg-agentAnalytics flex flex-co xl:flex-row rounded-3xl items-center w-full md:p-6 sm:shadow-md gap-6 bg-slate-700">
			<div className="grid grid-cols-2 bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 gap-2 items-center w-full divide-x">
				<div className="max-h-56">
					<Doughnut className="w-full h-full" data={formData} />
				</div>

				<div className="flex flex-col items-center justify-center text-center text-sm h-full">
					{
						incompleteVoterProfiles > 0 ?
							<>
								<p>
									{incompleteVoterProfiles} of {totalProfiles}
								</p>

								<p>
									Survyes Remaining
								</p>
							</> :
							<>
								<p>
									All ({totalProfiles})
								</p>

								<p>Surveys Completed</p>
							</>
					}

					<br />

					<p className="border-t ml-3 px-1 py-3">
						Your Count: <span>{data.individualSurveyCount}</span> <br />
						Others Count: <span>{completedVoterProfiles - data.individualSurveyCount}</span>
					</p>
				</div>
			</div>
		</section>
	);
};

export default AgentAnalytics;
