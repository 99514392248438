/* eslint-disable no-mixed-spaces-and-tabs */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = axios.create({
	withCredentials: true,
	baseURL: import.meta.env.VITE_API_URL,
});

// User,Auth based reducers

export const getOtp = createAsyncThunk(
	"user/getOtp",
	async (mobile, thunkAPI) => {
		// URL and JSON data
		const url = `user/getOtp`;

		// Send GET request with Axios
		try {
			const response = await baseUrl.post(
				url,
				{ mobile },
				{
					withCredentials: true,
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const loginUser = createAsyncThunk(
	"user/verifyOtp",
	async (creds, thunkAPI) => {
		// URL and JSON data
		const url = `user/verifyOtp`;

		// Send GET request with Axios
		try {
			const response = await baseUrl.post(url, creds, {
				withCredentials: true,
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const authenticateUser = createAsyncThunk(
	"user/me",
	async (_, thunkAPI) => {
		// URL and JSON data
		const url = `user/me`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const getAdmins = createAsyncThunk(
	"user/admins",
	async (_, thunkAPI) => {
		// URL and JSON data
		const url = `user/admins`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const addAdmin = createAsyncThunk(
	"user/addAdmin",
	async (data, thunkAPI) => {
		// URL and JSON data
		const url = `user/addAdmin`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.post(
				url,
				{ data },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const editAdmin = createAsyncThunk(
	"user/editAdmin",
	async (data, thunkAPI) => {
		// URL and JSON data
		const url = `user/editAdmin`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.post(
				url,
				{ data },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getAgents = createAsyncThunk(
	"user/agents",
	async (_, thunkAPI) => {
		// URL and JSON data
		const url = `user/agents`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const addAgent = createAsyncThunk(
	"user/addAgent",
	async (data, thunkAPI) => {
		// URL and JSON data
		const url = `user/addAgent`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.post(
				url,
				{ data },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const editAgent = createAsyncThunk(
	"user/editAgent",
	async (data, thunkAPI) => {
		// URL and JSON data
		const url = `user/editAgent`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.post(
				url,
				{ data },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const removeAdmin = createAsyncThunk(
	"user/removeAdmin",
	async (id, thunkAPI) => {
		// URL and JSON data
		const url = `user/remove?id=${id}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.put(
				url,
				{},
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const removeAgent = createAsyncThunk(
	"user/removeAgent",
	async (id, thunkAPI) => {
		// URL and JSON data
		const url = `user/remove?id=${id}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.put(
				url,
				{},
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

// Form based reducers

export const getAdminForm = createAsyncThunk(
	"user/adminForm",
	async (id, thunkAPI) => {
		// URL and JSON data
		const url = `user/adminForm?${id ? `id=${id}` : ""}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getAgentForm = createAsyncThunk(
	"user/agentForm",
	async (id, thunkAPI) => {
		// URL and JSON data
		const url = `user/agentForm?${id ? `id=${id}` : ""}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getVoterSurveyForm = createAsyncThunk(
	"voter/form",
	async (id, thunkAPI) => {
		// URL and JSON data
		const url = `voter/form?${id ? `id=${id}` : ""}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

// Survey based reducers

export const addSurvey = createAsyncThunk(
	"survey/new",
	async (details, thunkAPI) => {
		const url = `survey/new`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.post(url, details, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const getSurveys = createAsyncThunk(
	"survey/all",
	async (count, thunkAPI) => {
		// URL and JSON data
		const url = `survey/all?page=${count}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getBasicSurveyAnalytics = createAsyncThunk(
	"survey/analytics",
	async (_, thunkAPI) => {
		// URL and JSON data
		const url = `survey/analytics`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getBasicSurveyRecordings = createAsyncThunk(
	"survey/recordings",
	async (page, thunkAPI) => {
		// URL and JSON data
		const url = `survey/recordings?page=${page}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

// Voter based reducers

export const addBulkVoters = createAsyncThunk(
	"voter/bulkAdd",
	async (file, thunkAPI) => {
		// URL and JSON data
		const formData = new FormData();

		formData.append("file", file);

		const url = `voter/bulkAdd`;

		let token = Cookies.get("token");

		try {
			const response = await baseUrl.post(url, formData, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data",
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const getVoters = createAsyncThunk(
	"voter/all",
	async (query, thunkAPI) => {
		// URL and JSON data
		const { page = 1, keyword = "" } = query;

		const url = `voter/all?page=${page}&q=${keyword}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getCompletedVoters = createAsyncThunk(
	"voter/all",
	async (query, thunkAPI) => {
		// URL and JSON data
		const { page = 1, keyword = "" } = query;

		const url = `voter/all?page=${page}&q=${keyword}&status=completed`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getVoterSurveys = createAsyncThunk(
	"voter/surveys",
	async (query, thunkAPI) => {
		// URL and JSON data
		const { page = 1, keyword = "" } = query;
		const url = `voter/surveys?page=${page}&q=${keyword}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const searchVoter = createAsyncThunk(
	"voter/search",
	async (keyword, thunkAPI) => {
		// URL and JSON data
		const url = `voter/search?q=${keyword}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const addVoter = createAsyncThunk(
	"voter/new",
	async (details, thunkAPI) => {
		// URL and JSON data
		const url = `voter/new`;

		let token = Cookies.get("token");

		try {
			const response = await baseUrl.post(url, details, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const editVoter = createAsyncThunk("voter/edit", async (details) => {
	// URL and JSON data
	const url = `voter/edit/${details.id}`;
	let data;

	let token = Cookies.get("token");

	// Send GET request with Axios
	await baseUrl
		.put(url, details, {
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			timeout: 120000,
		})
		.then((response) => {
			// Handle the response here
			data = response?.data;
		})
		.catch((error) => {
			data = error.response?.data ?? {
				success: false,
				message: "Server Down. Please try again after some time",
			};
		});

	return data;
});

export const deleteVoter = createAsyncThunk("voter/delete", async (id) => {
	// URL and JSON data
	const url = `voter/delete/${id}`;
	let data;

	let token = Cookies.get("token");

	// Send GET request with Axios
	await baseUrl
		.delete(url, {
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((response) => {
			// Handle the response here
			data = response?.data;
		})
		.catch((error) => {
			data = error.response?.data ?? {
				success: false,
				message: "Server Down. Please try again after some time",
			};
		});

	return data;
});

export const addVoterSurvey = createAsyncThunk(
	"voter/survey",
	async (survey, thunkAPI) => {
		const url = `voter/survey`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.post(url, survey, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const getVoterSurveyAnalytics = createAsyncThunk(
	"voter/analytics",
	async (ps = [], thunkAPI) => {
		// URL and JSON data

		const url = `voter/analytics`;

		let token = Cookies.get("token");

		try {
			const response = await baseUrl.post(
				url,
				{ ps },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const getVoterSurveyRecordings = createAsyncThunk(
	"voter/recordings",
	async (page, thunkAPI) => {
		// URL and JSON data
		const url = `voter/recordings?page=${page}`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			const errorMessage = err.response?.data?.message || "Unexpected error";
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

// CSV based reducer

export const getCsv = createAsyncThunk("csvData", async (ps = [], thunkAPI) => {
	// URL and JSON data
	const url = `csvData`;

	let token = Cookies.get("token");
	try {
		const response = await baseUrl.post(
			url,
			{ ps },
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseType: "blob",
			}
		);

		return response.data;
	} catch (err) {
		console.error(err);
		const errorMessage = err.response?.data?.message || "No Data Found";
		return thunkAPI.rejectWithValue(errorMessage);
	}
});

// Constituency based reducers

export const getConstituencies = createAsyncThunk(
	"user/constituencyCodes",
	async (_, thunkAPI) => {
		// URL and JSON data
		const url = `user/constituencyCodes`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.get(url, {
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

export const switchConstituency = createAsyncThunk(
	"user/updateConstituencyCode",
	async (code, thunkAPI) => {
		// URL and JSON data
		const url = `user/updateConstituencyCode`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.put(
				url,
				{ constituencyCode: code },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);

// Change Survey Type (Super Admin)
export const switchSurveyType = createAsyncThunk(
	"user/updateUserSurveyType",
	async (type, thunkAPI) => {
		// URL and JSON data
		const url = `user/updateUserSurveyType`;

		let token = Cookies.get("token");
		try {
			const response = await baseUrl.put(
				url,
				{ type },
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (err) {
			console.error(err);
			return thunkAPI.rejectWithValue(
				err.response?.data?.message || "Unexpected error"
			);
		}
	}
);
