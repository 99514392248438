import { createSlice } from "@reduxjs/toolkit";
import {
	addBulkVoters,
	addVoter,
	addVoterSurvey,
	deleteVoter,
	editVoter,
	getVoters,
	getVoterSurveyForm,
	getVoterSurveys,
	searchVoter,
} from "../extraReducers";

const voterSlice = createSlice({
	name: "voter",
	initialState: {
		data: {},
		voters: [],
		surveys: [],
		voter: {},
		status: "idle",
		message: "",
		error: null,
	},
	reducers: {
		clearList(state) {
			state.voters = [];
			state.surveys = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getVoters.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getVoters.fulfilled, (state, action) => {
				state.status = "succeeded";

				const { voters, ...remainingInfo } = action.payload;

				state.voters =
					remainingInfo.count !== "1"
						? [...state.voters, ...voters]
						: [...voters];

				state.data = remainingInfo;
			})
			.addCase(getVoters.rejected, (state) => {
				state.status = "failed";
			})

			.addCase(getVoterSurveys.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getVoterSurveys.fulfilled, (state, action) => {
				state.status = "succeeded";

				const { surveys, ...remainingInfo } = action.payload;

				state.surveys =
					remainingInfo.currentPage !== "1"
						? [...state.surveys, ...surveys]
						: [...surveys];

				state.data = remainingInfo;
			})
			.addCase(getVoterSurveys.rejected, (state) => {
				state.status = "failed";
			})

			.addCase(addBulkVoters.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addBulkVoters.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.voters = action.payload.voters
			})
			.addCase(addBulkVoters.rejected, (state) => {
				state.status = "failed";
			})

			.addCase(searchVoter.pending, (state) => {
				state.status = "loading";
			})
			.addCase(searchVoter.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.voters = [...action.payload.voter];
			})
			.addCase(searchVoter.rejected, (state) => {
				state.status = "failed";
			})

			.addCase(addVoter.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addVoter.fulfilled, (state, action) => {
				state.status = action.payload.success;
			})
			.addCase(addVoter.rejected, (state) => {
				state.status = "failed";
			})

			.addCase(getVoterSurveyForm.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getVoterSurveyForm.fulfilled, (state, action) => {
				state.status = action.payload.success;
				state.voter = action.payload.voter;
			})
			.addCase(getVoterSurveyForm.rejected, (state) => {
				state.status = "failed";
			})

			.addCase(editVoter.pending, (state) => {
				state.status = "loading";
			})
			.addCase(editVoter.fulfilled, (state) => {
				state.status = "succeeded";
			})
			.addCase(editVoter.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})

			.addCase(addVoterSurvey.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addVoterSurvey.fulfilled, (state) => {
				state.status = "succeeded";
			})
			.addCase(addVoterSurvey.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})

			.addCase(deleteVoter.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteVoter.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.message = action.payload.message;
			})
			.addCase(deleteVoter.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const { clearList } = voterSlice.actions;
export default voterSlice.reducer;
