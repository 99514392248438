import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import bg from "../../../../assets/images/jpg/bg.jpg"

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.font.size = 12;

const UploadedVoterAnalytics = () => {
	const { data } = useSelector((state) => state.voters);
	// Conditionally render the chart components only when data is available
	const completedVoterProfiles = data.totalSurveysTaken

	const incompleteVoterProfiles = data.totalSurveysRemaining

	const acWiseAnalytics = data.acWiseAnalytics

	const totalProfiles = completedVoterProfiles + incompleteVoterProfiles;

	const colourSchemes = [
		{
			bg: ["rgba(20, 184, 166, 0.5)", "rgba(20, 184, 166, 0.1)"],
			borderColor: ["rgb(20 184 166)"]
		},
		{
			bg: ["rgba(59, 130, 246, 0.5)", "rgba(59, 130, 246, 0.1)"],
			borderColor: ["rgb(59, 130, 246)"]
		},
		{
			bg: ["rgba(244, 63, 94, 0.5)", "rgba(244, 63, 94, 0.1)"],
			borderColor: ["rgba(244, 63, 94)"]
		},
		{
			bg: ["rgba(249, 115, 22, 0.5)", "rgba(249, 115, 22, 0.1)"],
			borderColor: ["rgba(249, 115, 22)"]
		},
		{
			bg: ["rgba(6, 182, 212, 0.5)", "rgba(6, 182, 212, 0.1)"],
			borderColor: ["rgba(6, 182, 212)"]
		},
		{
			bg: ["rgba(217, 70, 239, 0.5)", "rgba(217, 70, 239, 0.1)"],
			borderColor: ["rgb(217, 70, 239)"]
		},
	]

	const data1 = {
		labels: ["Completed", "Remaining"],
		datasets: [
			{
				label: "No. of Voters",
				data: [completedVoterProfiles, incompleteVoterProfiles],
				backgroundColor: colourSchemes[0].bg,
				borderColor: colourSchemes[0].borderColor,
				borderWidth: 1,
			},
		],
	};

	const data2 = {
		labels: ["Completed", "Remaining"],
		datasets: acWiseAnalytics?.map((data, i) => {
			return (
				{
					label: `No. of Voters in ${data.code}`,
					data: [data.surveysTaken, data.surveysRemaining],
					backgroundColor: colourSchemes[i].bg,
					borderColor: colourSchemes[i].borderColor,
					borderWidth: 1,
				}
			)
		})
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	return (
		<section className="flex flex-co xl:flex-row rounded-3xl items-center w-full md:p-6 sm:shadow-md gap-6 bg-teal-700">
			{
				totalProfiles > 0 ?
					<>
						<div className="grid grid-cols-2 bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3 md:p-5 gap-2 items-center w-full divide-x">
							<div className="max-h-56 mx-auto">
								<Doughnut className="w-full h-full" data={data1} />
							</div>

							<div className="flex flex-col items-center justify-center text-center text-sm lg:text-lg h-full font-medium">
								{
									incompleteVoterProfiles > 0 ?
										<>
											<p>
												{incompleteVoterProfiles} of {totalProfiles}
											</p>

											<p>
												Survyes Remaining
											</p>
										</> :
										<>
											<p>
												{totalProfiles}
											</p>

											<p>Surveys Completed</p>

										</>
								}

							</div>
						</div>

						<div className="grid grid-cols-2 bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 gap-2 items-center w-full divide-x">
							<div className="max-h-56 mx-auto">
								<Pie className="w-full h-full" data={data2} options={options} />
							</div>

							<div className="flex flex-col justify-center items-center w-full">
								<h2 className="text-xl font-semibold mb-4">Constituencies</h2>
								<div className="grid grid-cols-2 gap-5">
									{acWiseAnalytics.map((data, index) => (
										<div key={index} className="flex items-center">
											<span className="inline-block w-3 h-3 rounded-full mr-2" style={{ backgroundColor: colourSchemes[index].bg }}></span>
											<span>{data.code}</span>
										</div>
									))}
								</div>
							</div>
						</div>
					</>
					:
					<>
						<div className="bg-white/20 shadow-md ring-1 rounded-2xl p-3 md:p-0 gap-2 items-center w-full divide-x overflow-hidden">
							<div className="flex items-center h-42 md:h-52 my-3">
								<img src={bg} className="object-cover object-center opacity-70" />
							</div>
						</div>
					</>
			}
		</section>
	);
};

export default UploadedVoterAnalytics;
