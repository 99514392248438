const Count = ({ labels, values, type }) => {

    return (
        <div className={`${type && "grid-cols-3 md:grid-cols-8"} flex-1 my-2  grid grid-cols-2`}>
            {
                labels.map((label, i) => {
                    return (
                        <div key={i} className='text-base'>
                            <p className='font-bold'>{label}: <span className='font-normal'>
                                {values[i]}
                            </span>
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Count