import React from 'react'
import CustomSelect from './Select';
import FreeField from './FreeField';
import ChipSelect from './ChipSelect';
import CustomTextField from './TextField';
import NumField from './NumField';
import AutoComplete from './AutoComplete';

const DependentSelect = ({ fieldData, formHook }) => {
    const { watch } = formHook

    const { id, defaultValue, subQuestions, subType } = fieldData

    const parentValue = watch()?.[id] ?? defaultValue
    const subQuestion = subQuestions[parentValue] ?? subQuestions[0]

    const parentField = (subType) => {
        switch (subType) {
            case "AutoComplete":
                return (
                    <AutoComplete fieldData={fieldData} formHook={formHook} />
                );
            case "Select":
                return (
                    <CustomSelect formHook={formHook} fieldData={fieldData} />
                )
            case "ChipSelect":
                return (
                    <ChipSelect formHook={formHook} fieldData={fieldData} />
                )
            default:
                return <></>
        }
    }

    const subField = (subQuestion) => {
        switch (subQuestion?.type) {
            case "AutoComplete":
                return (
                    <AutoComplete fieldData={subQuestion} formHook={formHook} />
                );
            case "Select":
                return (
                    <CustomSelect fieldData={subQuestion} formHook={formHook} />
                )
            case "TextField":
                return (
                    <CustomTextField fieldData={subQuestion} formHook={formHook} />
                )
            case "NumField":
                return (
                    <NumField fieldData={subQuestion} formHook={formHook} />
                )
            case "FreeField":
                return (
                    (parentValue.length !== 0) &&
                    <FreeField fieldData={subQuestion} formHook={formHook} />
                )
            case "DependentSelect":
                return (
                    (parentValue.length !== 0) &&
                    <DependentSelect fieldData={subQuestion} formHook={formHook} />
                )
            case "ChipSelect":
                return (
                    <ChipSelect
                        formHook={formHook}
                        parentId={id}
                        fieldData={
                            parentValue?.constructor === Array ?
                                {
                                    ...subQuestion,
                                    options: parentValue?.reduce((acc, id) => {
                                        return acc.concat(subQuestion.options[id]);
                                    }, [])

                                }
                                :
                                {
                                    ...subQuestion,
                                    options: subQuestion.options[parentValue]

                                }
                        }
                    />
                )
            case "DependentChipSelect":
                return (
                    <>
                        <ChipSelect
                            formHook={formHook}
                            fieldData={
                                parentValue?.constructor === Array ?
                                    {
                                        ...subQuestion,
                                        options: parentValue?.reduce((acc, id) => {
                                            return acc.concat(subQuestion.options[id]);
                                        }, [])
                                    }
                                    :
                                    subQuestion
                            }
                        />

                        {
                            watch()?.[subQuestion?.id] &&
                            watch()[subQuestion?.id].map((option, key) => (
                                <React.Fragment key={key}>
                                    {subField(subQuestion?.subQuestions[option])}
                                </React.Fragment>
                            ))

                        }
                    </>

                )
            default:
                return <></>
        }
    }

    return (
        <>
            {
                parentField(subType)
            }

            {
                Array.isArray(subQuestion)
                    ?
                    subQuestion.map((que) => subField(que))
                    :
                    subField(subQuestion)
            }
        </>
    )
}

export default DependentSelect