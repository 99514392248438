import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getVoterSurveyForm } from "../../../../../store/extraReducers";

const VoterCard = ({ voter, setFormOpen, rtc, openAddVoterForm, setVoterRel }) => {
    const { userDetails } = useSelector((store) => store.user);

    const { name, voterId, fathersName, mothersName, husbandsName, others, houseNumber, surveyedBy, surveyTaken, _id: id, isAddition, survey } = voter;

    const openRelVoterForm = (e) => {
        e.stopPropagation();
        openAddVoterForm();
        setVoterRel(voter)
    };

    const dispatch = useDispatch();
    const { startRecording } = rtc

    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const getFormDatafunc = (e) => {
        setLoading(true)
        setAnchorEl(e)

        dispatch(getVoterSurveyForm(e.id)).then(action => {
            if (action.payload?.success) {
                startRecording(setFormOpen, setLoading)
            } else {
                toast.error(action.payload);
            }
        })
    }

    return (
        <button
            id={id}
            disabled={surveyTaken || (loading && anchorEl?.id === id)}
            onClick={(e) => getFormDatafunc(e.currentTarget)}
            className={`${surveyTaken ? "bg-emerald-50" : "bg-white"} ${isAddition && "!bg-sky-100"} ${["Died", "Moved outside Assembly"].includes(survey?.["Voter Present Status"]) && "!bg-red-100"} ${survey?.["Voter Present Status"] === "Moved to another Booth" && "!bg-orange-100"} flex text-gray-900 shadow-md ring-1 ring-slate-200 rounded-2xl flex-col gap-2 relative overflow-hidden select-none`}
        >
            <div className={`${loading && anchorEl?.id === id ? "flex backdrop-blur-sm" : "hidden"} absolute w-full h-full bg-emerald-500/30 text-emerald-900 font-semibold`}>
                <span className='flex  items-center gap-2 m-auto'>
                    Genrating Form <AiOutlineLoading3Quarters className="animate-spin" />
                </span>
            </div>

            <div className='p-3.5 md:p-5 w-full'>
                <div className={`flex items-center w-full border-b border-black/10 pb-2`}>
                    <div className={`${surveyTaken ? "bg-emerald-700" : "bg-slate-700"} ${isAddition && "bg-sky-700"} ${["Died", "Moved outside Assembly"].includes(survey?.["Voter Present Status"]) && "bg-red-700"} ${survey?.["Voter Present Status"] === "Moved to another Booth" && "!bg-orange-700"} p-2 mr-3 inline-flex items-center justify-center rounded-full text-white text-lg sm:text-2xl`}>
                        <BiUser />
                    </div>

                    <div className="flex flex-col items-start">
                        <p className="text-xs text-start sm:text-lg first-letter:uppercase font-bold">
                            {name}
                        </p>

                        <p className='text-xs'>
                            {voterId}
                        </p>
                    </div>

                    <button
                        onClick={openRelVoterForm}
                        className={` ${isAddition && "!bg-sky-200 !text-sky-700"} ${["Died", "Moved outside Assembly"].includes(survey?.["Voter Present Status"]) && "!bg-red-200 !text-red-700"} ${survey?.["Voter Present Status"] === "Moved to another Booth" && "!bg-orange-700"} p-1.5 px-3 rounded-xl text-emerald-700 bg-emerald-100 text-xs sm:text-sm ml-auto`}>
                        Add Relation
                    </button>
                </div>


                <div className="flex flex-col items-start text-sm first-letter:uppercase font-semibold mt-2 text-start">
                    {
                        !isAddition &&
                        <p>
                            <span className='text-sm font-bold'>
                                {
                                    (fathersName && "Fathers") ||
                                    (mothersName && "Mothers") ||
                                    (husbandsName && "Husbands") ||
                                    (others && "Others")
                                } Name:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {
                                    fathersName || mothersName || husbandsName || others || "Not Available"
                                }
                            </span>
                        </p>
                    }

                    <p>
                        <span className='font-bold'>
                            House Number:
                        </span>
                        {" "}
                        <span className='text-gray-500'>
                            {
                                houseNumber && houseNumber !== "" ?
                                    houseNumber : "Not available"
                            }
                        </span>
                    </p>
                </div>
            </div>

            {
                surveyTaken && (surveyedBy !== userDetails._id) &&
                <div className="absolute bottom-0 right-0 ml-auto text-xs bg-slate-700 p-1 px-2 rounded-l-xl text-white z-10">
                    Surveyed by other Agent
                </div>
            }
        </button>

    )
}

export default VoterCard