import { TextField } from '@mui/material';
import { useEffect } from 'react';

const NumField = ({ fieldData, formHook }) => {
    const { register, formState: { errors }, unregister } = formHook

    const { id, question, errorMsg, placeholder, defaultValue, isPhoneNumber, max = null, maxLength = null, disabled } = fieldData;

    const inputType = isPhoneNumber ? "tel" : "number";
    const pattern = isPhoneNumber
        ? /^\d{10}$/ // Adjust this regex pattern for phone number validation
        : /^[1-9]\d*$/; // Pattern for regular numbers (cannot start with 0)

    const inputProps = {
        id: id,
        style: {
            borderRadius: "8px",
            outline: "none",
            border: "2px solid rgb(209 213 219)",
        },
        ...(maxLength && { maxLength: maxLength }), // Set the maxLength to 10 to limit the input to 10 digits
        ...(isPhoneNumber && { autoComplete: "off" })
    };

    useEffect(() => {
        return () => {
            unregister(id); // Unregister the field when the component is unmounted
        };
    }, [register, id, unregister])


    return (
        <div>
            <div className="flex items-center justify-between">
                <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                    {question}
                </label>
            </div>

            <div className="mt-2">
                <TextField
                    type={inputType}
                    defaultValue={defaultValue ?? ""}
                    disabled={disabled || false}
                    name={id}
                    placeholder={placeholder}
                    inputProps={inputProps}
                    sx={{
                        "& fieldset": { border: 'none' },
                    }}
                    className="w-full focus:outline-gray-400"
                    {...register(id,
                        {
                            required: errorMsg,
                            pattern: {
                                value: pattern,
                                message: isPhoneNumber ? "Please enter a valid 10 digit phone number" : "Value cannot be 0",
                            },
                            ...(max && { max: { value: max, message: `Value cannot exceed more than ${max}` } }), // Add max validation condition if max is provided
                        })}
                    error={id in errors && !!errors[id].message}
                    helperText={id in errors ? errors[id].message : null}
                />
            </div>
        </div>
    );
};

export default NumField;
