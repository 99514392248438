/* eslint-disable no-mixed-spaces-and-tabs */
export const findAnalyticsFor = (constituencyCode, data) => {
	const { analytics, totalDocs } = data || {};

	const getDataFor = (partyName) => {
		const party = analytics?.support.find(
			(item) => Object.keys(item)[0] === partyName
		);

		if (party) {
			const reasons = party[partyName].reason;
			// console.log(reasons);
			return Object.entries(reasons).map(([key, value]) => ({
				[key]: value,
			}));
		} else {
			return [];
		}
	};


	const getTotalPartyDocs = (partyName) => {
		return analytics?.support.filter(
			(party) => Object.keys(party)[0] === partyName
		)[0]
			? analytics?.support.filter(
					(item) => Object.keys(item)[0] === partyName
			  )[0]?.[partyName].count
			: totalDocs;
	};

	switch (constituencyCode) {
		case "130":
			return [
				{
					question: "Gender wise people distribution",
					options: ["Male", "Female", "Prefer not to say"],
					data: analytics?.gender,
				},

				{
					question: "Age wise people distribution",
					options: ["18-30", "31-40", "41-50", "51-60", "60+"],
					data: analytics?.ageGroup,
				},

				{
					question: "Caste wise people distribution",
					options: [
						"B.C",
						"O.C",
						"S.T",
						"S.C",
						"Christian",
						"Muslim",
						"Kurni",
						"Boya",
						"Other",
						"Not answered",
					],
					data: analytics?.caste,
				},

				{
					question: "Sub caste wise people distribution",
					options: [
						"Reddy",
						"Kamma",
						"Velama",
						"Vysya / Komati",
						"Brahmin",
						"Mudiraju",
						"Yadav / Golla",
						"Setti Balija",
						"Goud",
						"Padmashali",
						"SC Mala",
						"SC Madhiga",
						"ST Lambadi",
						"ST(Others)",
						"Christian",
						"Muslim",
						"BC - B",
						"Other",
						"Not answered",
					],
					data: analytics?.subCaste,
				},

				{
					question: "Education qualification wise distribution",
					options: ["10th Class", "Intermediate", "Degree", "PG", "Uneducated"],
					data: analytics?.eduQualification,
				},

				{
					question: "Occupation wise people distribution",
					options: [
						"Farmer",
						"Shop Owner",
						"Student",
						"Tenant Farmer",
						"Daily wage worker",
						"Political Worker",
						"Private Employee",
						"Goverment Employee",
						"Doctor",
						"Journalist",
						"Unemployed",
						"Teacher",
						"Private Teacher",
						"SHG Worker",
						"Anganwadi Woker",
						"House wife / House husband",
						"Auto / Taxi Driver",
						"Priest",
						"Retired",
						"Others",
					],
					data: analytics?.occupation,
				},

				{
					question: "Which party do people support?",
					options: ["TDP", "YSRCP", "JSP", "BJP", "Not decided yet"],
					data: analytics?.support,
				},

				{
					question: "On what basis people vote for TDP?",
					options: [
						"TDP party",
						"Looking at the MLA candidate",
						"Seeing Chandrababu Naidu",
						"An earlier development",
						"Government welfare schemes",
						"Opposition to Vice - RCP MLA",
						"Other reasons",
					],
					data: getDataFor("TDP"),
					totalDocs: getTotalPartyDocs("TDP"),
				},

				{
					question: "On what basis people vote for YSRCP?",
					options: [
						"YSRCP Party",
						"Looking at the MLA candidate",
						"Seeing CM Jagan",
						"Development programs",
						"Government welfare schemes",
						"Other reasons",
					],
					data: getDataFor("YSRCP"),
					totalDocs: getTotalPartyDocs("YSRCP"),
				},

				{
					question: "On what basis people vote for JSP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("JSP"),
					totalDocs: getTotalPartyDocs("JSP"),
				},

				{
					question: "On what basis people vote for BJP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("BJP"),
					totalDocs: getTotalPartyDocs("BJP"),
				},

				{
					question: "What factors do people consider while voting",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("Not decided yet"),
					totalDocs: getTotalPartyDocs("Not decided yet"),
				},

				{
					question: "Image of MLA",
					options: ["Very good", "Good", "Average", "Bad", "Don't know"],
					data: analytics?.MLAperfomance,
				},

				{
					question: "Which candidate will win in the upcoming elections?",
					options: [
						"Putha Narasimha Reddy",
						"⁠Pochimareddy Ravindranath Reddy",
						"Don't know",
						"Others",
					],
					data: analytics?.whichCandidateWillWin,
				},

				{
					question:
						"In the upcoming elections which party do people think will win in this Kamalapuram constituency",
					options: ["TDP", "YSRCP", "JSP", "The BJP", "Don't know"],
					data: analytics?.whoWillWin,
				},

				{
					question:
						"Which party has the most chances to win according to the people?",
					options: ["TDP + JSP", "YSRCP", "BJP", "Tough Fight", "Don't know"],
					data: analytics?.whichPartyWillWin,
				},

				{
					question: "Which party did people vote in 2019 Elections?",
					options: ["TDP", "YSRCP", "JSP", "BJP", "NOTA", "Did not vote"],
					data: analytics?.voted2019,
				},

				// End of 130
			];
		case "144":
			return [
				{
					question: "Gender wise people distribution",
					options: ["Male", "Female", "Prefer not to say"],
					data: analytics?.gender,
				},

				{
					question: "Age wise people distribution",
					options: ["18-30", "31-40", "41-50", "51-60", "60+"],
					data: analytics?.ageGroup,
				},

				{
					question: "Caste wise people distribution",
					options: [
						"B.C",
						"O.C",
						"S.T",
						"S.C",
						"Christian",
						"Muslim",
						"Kurni",
						"Boya",
						"Other",
						"Not answered",
					],
					data: analytics?.caste,
				},

				{
					question: "Sub caste wise people distribution",
					options: [
						"Reddy",
						"Kamma",
						"Velama",
						"Vysya / Komati",
						"Brahmin",
						"Mudiraju",
						"Yadav / Golla",
						"Setti Balija",
						"Goud",
						"Padmashali",
						"SC Mala",
						"SC Madhiga",
						"ST Lambadi",
						"ST(Others)",
						"Christian",
						"Muslim",
						"BC - B",
						"Other",
						"Not answered",
					],
					data: analytics?.subCaste,
				},

				{
					question: "Education qualification wise distribution",
					options: ["10th Class", "Intermediate", "Degree", "PG", "Uneducated"],
					data: analytics?.eduQualification,
				},

				{
					question: "Occupation wise people distribution",
					options: [
						"Farmer",
						"Shop Owner",
						"Student",
						"Tenant Farmer",
						"Daily wage worker",
						"Political Worker",
						"Private Employee",
						"Goverment Employee",
						"Doctor",
						"Journalist",
						"Unemployed",
						"Teacher",
						"Private Teacher",
						"SHG Worker",
						"Anganwadi Woker",
						"House wife / House husband",
						"Auto / Taxi Driver",
						"Priest",
						"Retired",
						"Others",
					],
					data: analytics?.occupation,
				},

				{
					question: "Which party do people support?",
					options: ["TDP", "YSRCP", "JSP", "BJP", "Not decided yet"],
					data: analytics?.support.map((i) => {
						return {
							[Object.entries(i)[0][0]]: Object.entries(i)[0][1].count,
						};
					}),
				},

				{
					question: "On what basis people vote for TDP?",
					options: [
						"TDP party",
						"Looking at the MLA candidate",
						"Seeing Chandrababu Naidu",
						"An earlier development",
						"Government welfare schemes",
						"Opposition to Vice - RCP MLA",
						"Other reasons",
					],
					data: getDataFor("TDP"),
					totalDocs: getTotalPartyDocs("TDP"),
				},

				{
					question: "On what basis people vote for YSRCP?",
					options: [
						"YSRCP Party",
						"Looking at the MLA candidate",
						"Seeing CM Jagan",
						"Development programs",
						"Government welfare schemes",
						"Other reasons",
					],
					data: getDataFor("YSRCP"),
					totalDocs: getTotalPartyDocs("YSRCP"),
				},

				{
					question: "On what basis people vote for JSP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("JSP"),
					totalDocs: getTotalPartyDocs("JSP"),
				},

				{
					question: "On what basis people vote for BJP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("BJP"),
					totalDocs: getTotalPartyDocs("BJP"),
				},

				{
					question: "What factors do people consider while voting",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("Not decided yet"),
					totalDocs: getTotalPartyDocs("Not decided yet"),
				},

				{
					question: "Image of MLA",
					options: ["Very good", "Good", "Average", "Bad", "Don't know"],
					data: analytics?.MLAperfomance,
				},

				{
					question: "Which candidate will win in the upcoming elections?",
					options: [
						"Butta Renuka",
						"B V Nageshwar Reddy",
						"Don't know",
						"Others",
					],
					data: analytics?.whichCandidateWillWin,
				},

				{
					question:
						"In the upcoming elections which party do people think will win in this Yemmiganur constituency",
					options: ["TDP", "YSRCP", "JSP", "The BJP", "Don't know"],
					data: analytics?.whoWillWin,
				},

				{
					question:
						"Which party has the most chances to win according to the people?",
					options: ["TDP + JSP", "YSRCP", "BJP", "Tough Fight", "Don't know"],
					data: analytics?.whichPartyWillWin,
				},

				{
					question: "Which party did people vote in 2019 Elections?",
					options: ["TDP", "YSRCP", "JSP", "BJP", "NOTA", "Did not vote"],
					data: analytics?.voted2019,
				},
			];
		case "136":
			return [
				{
					question: "Gender wise people distribution",
					options: ["Male", "Female", "Prefer not to say"],
					data: analytics?.gender,
				},

				{
					question: "Age wise people distribution",
					options: ["18-30", "31-40", "41-50", "51-60", "60+"],
					data: analytics?.ageGroup,
				},

				{
					question: "Caste wise people distribution",
					options: [
						"B.C",
						"O.C",
						"S.T",
						"S.C",
						"Christian",
						"Muslim",
						"Kurni",
						"Boya",
						"Other",
						"Not answered",
					],
					data: analytics?.caste,
				},

				{
					question: "Sub caste wise people distribution",
					options: [
						"Reddy",
						"Kamma",
						"Velama",
						"Vysya / Komati",
						"Brahmin",
						"Mudiraju",
						"Yadav / Golla",
						"Setti Balija",
						"Goud",
						"Padmashali",
						"SC Mala",
						"SC Madhiga",
						"ST Lambadi",
						"ST(Others)",
						"Christian",
						"Muslim",
						"BC - B",
						"Other",
						"Not answered",
					],
					data: analytics?.subCaste,
				},

				{
					question: "Education qualification wise distribution",
					options: ["10th Class", "Intermediate", "Degree", "PG", "Uneducated"],
					data: analytics?.eduQualification,
				},

				{
					question: "Occupation wise people distribution",
					options: [
						"Farmer",
						"Shop Owner",
						"Student",
						"Tenant Farmer",
						"Daily wage worker",
						"Political Worker",
						"Private Employee",
						"Goverment Employee",
						"Doctor",
						"Journalist",
						"Unemployed",
						"Teacher",
						"Private Teacher",
						"SHG Worker",
						"Anganwadi Woker",
						"House wife / House husband",
						"Auto / Taxi Driver",
						"Priest",
						"Retired",
						"Others",
					],
					data: analytics?.occupation,
				},

				{
					question: "Which party do people support?",
					options: ["TDP", "YSRCP", "JSP", "BJP", "Not decided yet"],
					data: analytics?.support.map((i) => {
						return {
							[Object.entries(i)[0][0]]: Object.entries(i)[0][1].count,
						};
					}),
				},

				{
					question: "On what basis people vote for TDP?",
					options: [
						"TDP party",
						"Looking at the MLA candidate",
						"Seeing Chandrababu Naidu",
						"An earlier development",
						"Government welfare schemes",
						"Opposition to Vice - RCP MLA",
						"Other reasons",
					],
					data: getDataFor("TDP"),
					totalDocs: getTotalPartyDocs("TDP"),
				},

				{
					question: "On what basis people vote for YSRCP?",
					options: [
						"YSRCP Party",
						"Looking at the MLA candidate",
						"Seeing CM Jagan",
						"Development programs",
						"Government welfare schemes",
						"Other reasons",
					],
					data: getDataFor("YSRCP"),
					totalDocs: getTotalPartyDocs("YSRCP"),
				},

				{
					question: "On what basis people vote for JSP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("JSP"),
					totalDocs: getTotalPartyDocs("JSP"),
				},

				{
					question: "On what basis people vote for BJP?",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("BJP"),
					totalDocs: getTotalPartyDocs("BJP"),
				},

				{
					question: "What factors do people consider while voting",
					options: [
						"Party",
						"MLA Candidate",
						"CM Candidate",
						"Development Activities",
						"Goverment Schemes",
						"Others",
					],
					data: getDataFor("Not decided yet"),
					totalDocs: getTotalPartyDocs("Not decided yet"),
				},

				{
					question: "Image of MLA",
					options: ["Very good", "Good", "Average", "Bad", "Don't know"],
					data: analytics?.MLAperfomance,
				},

				{
					question: "Which candidate will win in the upcoming elections?",
					options: ["Jaya Surya", "Sudheer Babu", "Don't know", "Others"],
					data: analytics?.whichCandidateWillWin,
				},

				{
					question:
						"In the upcoming elections which party do people think will win in this Nandikotkur constituency",
					options: ["TDP", "YSRCP", "JSP", "The BJP", "Don't know"],
					data: analytics?.whoWillWin,
				},

				{
					question:
						"Which party has the most chances to win according to the people?",
					options: ["TDP + JSP", "YSRCP", "BJP", "Tough Fight", "Don't know"],
					data: analytics?.whichPartyWillWin,
				},

				{
					question: "Which party did people vote in 2019 Elections?",
					options: ["TDP", "YSRCP", "JSP", "BJP", "NOTA", "Did not vote"],
					data: analytics?.voted2019,
				},
			];
		
		case "140":
			return [
				{
					question: "Gender wise people distribution",
					options: [
						"Male (పురుషుడు)",
						"Female (స్త్రీ)",
						"Dont want to Answer (సమాధానం చెప్పాలనుకోవడం లేదు)",
					],
					data: analytics?.gender,
				},

				{
					question: "Age wise people distribution",
					options: ["18-30", "31-45", "46-60", "60-85", "Above 85 (85 పైన)"],
					data: analytics?.ageGroup,
				},

				{
					question: "Which party do people support?",
					options: [
						"TDP (టీడీపీ)",
						"YSRCP (వైస్సార్సీపీ)",
						"JSP (జనసేన)",
						"BJP (బీజేపీ)",
						"Not Decided Yet (ఇంకా నిర్ణయించలేదు)",
					],
					data: analytics?.support.map((i) => {
						return {
							[Object.entries(i)[0][0]]: Object.entries(i)[0][1].count,
						};
					}),
				},

				{
					question: "Number of Local people",
					options: ["Yes (అవును)", "No (కాదు)"],
					data: analytics?.isVoterLocal,
				},
			];
		
		default:
			return null;
	}
};
