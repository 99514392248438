import { createSlice } from "@reduxjs/toolkit";
import {
	authenticateUser,
	getOtp,
	loginUser,
	switchConstituency,
	switchSurveyType,
} from "../extraReducers";
import Cookies from "js-cookie";

const userSlice = createSlice({
	name: "user",
	initialState: {
		userDetails: {},
		status: "idle",
		isAuthenticated: false,
		error: null,
	},
	reducers: {
		logout(state) {
			Cookies.remove("token");
			state.userDetails = {};
			state.isAuthenticated = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOtp.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getOtp.fulfilled, (state) => {
				state.status = "succeeded";
			})
			.addCase(getOtp.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})

			.addCase(loginUser.pending, (state) => {
				state.status = "loading";
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.isAuthenticated = action.payload.success;
				state.userDetails = action.payload.user;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})

			.addCase(authenticateUser.pending, (state) => {
				state.status = "loading";
			})
			.addCase(authenticateUser.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.isAuthenticated = true;
				state.userDetails = action.payload.user;
			})
			.addCase(authenticateUser.rejected, (state, action) => {
				state.status = "failed";
				state.isAuthenticated = false;
				state.error = action.error.message;
			})

			.addCase(switchConstituency.pending, (state) => {
				state.status = "loading";
			})
			.addCase(switchConstituency.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.userDetails.constituencyCode = action.payload.constituencyCode;
			})
			.addCase(switchConstituency.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})

			.addCase(switchSurveyType.pending, (state) => {
				state.status = "loading";
			})
			.addCase(switchSurveyType.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.userDetails.surveyType = action.payload.surveyType;
			})
			.addCase(switchSurveyType.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
