import Skeleton from "@mui/material/Skeleton";
import { BiCloudUpload, BiDotsVerticalRounded, BiSearch, BiUser } from "react-icons/bi";

export const UploadVotersListSkl = () => {
	// Create an array to mimic the loading state with a certain number of items
	const skeletonCount = 9; // Adjust this as needed

	return (
		<section>
			<div className="sm:bg-agentAnalytics flex flex-co xl:flex-row rounded-3xl items-center w-full md:p-6 sm:shadow-md gap-6 bg-teal-700">
				<div className="grid grid-cols-2 bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3 md:p-5 gap-2 items-center w-full divide-x">
					<div className="max-h-56">
						<Skeleton
							animation="wave"
							variant="circular"
							width={200}
							height={200}
						/>
					</div>

					<div className="flex flex-col items-center justify-center text-center text-sm lg:text-lg h-full">
						<p>
							<Skeleton
								sx={{ borderRadius: "0.5rem" }}
								animation="wave"
								variant="rectangular"
								height={20}
								width={100}
							/>
						</p>

						<p>
							Survyes Remaining
						</p>

					</div>
				</div>

				<div className="grid grid-cols-2 bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 gap-2 items-center w-full divide-x">
					<div className="max-h-56">
						<Skeleton
							animation="wave"
							variant="circular"
							width={200}
							height={200}
						/>
					</div>

					<div className="flex flex-col items-center justify-center text-center text-sm lg:text-lg h-full">
						<p>
							<Skeleton
								sx={{ borderRadius: "0.5rem" }}
								animation="wave"
								variant="rectangular"
								height={20}
								width={100}
							/>
						</p>

						<p>
							Survyes Remaining
						</p>
					</div>
				</div>
			</div>

			<div className="container py-14 mx-auto">
				<div className="flex w-full mb-10">
					<h1 className="sm:text-3xl text-2xl font-semibold text-gray-900">
						Voter List
					</h1>

					<div className="flex items-center ml-auto gap-5">
						<div className="hidden lg:flex items-center ml-auto gap-5">
							<div className="flex xl:w-96 bg-slate-100 ring-1 ring-gray-300 rounded-full">
								<input
									disabled
									className="w-full p-2 pl-5 focus:outline-none bg-transparent"
									type="text"
									name="search"
									aria-label="Search"
									placeholder="Search"
								/>

								<button disabled className="text-gray-50 bg-teal-900 p-2 px-5 rounded-full rounded-l-none">
									<BiSearch size={25} />
								</button>
							</div>
						</div>

						<div>
							<button
								disabled
								className="bg-teal-900 text-slate-50 px-4 py-2 rounded-full font-medium inline-flex items-center gap-1.5"
							>
								<BiCloudUpload className="text-xl" /> Upload File
							</button>
						</div>
					</div>
				</div>

				<div className="lg:hidden flex items-center ml-auto gap-5 mb-10 w-full">
					<div className="flex xl:w-96 bg-slate-100 ring-1 ring-gray-300 rounded-full">
						<input
							disabled
							className="w-full p-2 pl-5 focus:outline-none bg-transparent"
							type="text"
							name="search"
							aria-label="Search"
							placeholder="Search"
						/>

						<button disabled className="text-gray-50 bg-teal-900 p-2 px-5 rounded-full rounded-l-none">
							<BiSearch size={25} />
						</button>
					</div>
				</div>

				<div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5">
					{Array.from({ length: skeletonCount }).map((_, key) => (
						<div key={key}
							className={`ring-slate-200 bg-slate-100
								} flex h-full shadow-md ring-1 rounded-2xl p-2 md:p-2 gap-2`}
						>
							<div className="flex w-full">
								<div className="flex items-center w-full px-2 py-2 rounded-2xl">
									<div className="p-2 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white text-lg sm:text-2xl">
										<BiUser />
									</div>

									<div className="flex flex-col">
										<Skeleton variant="text" width={120} height={24} />
										<Skeleton variant="text" width={80} height={20} />
									</div>
								</div>

								<button className="ml-auto mb-auto text-xl text-slate-500" disabled>
									<BiDotsVerticalRounded />
								</button>
							</div>

							{/* <div className="flex flex-col flex-grow leading-relaxed">
								<Skeleton variant="text" width="60%" height={20} />
								<Skeleton variant="text" width="80%" height={20} className="mt-5" />
								<Skeleton variant="rectangular" width="100%" height={10} className="rounded-full mt-2" />
							</div> */}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
