import { BiUser } from 'react-icons/bi';

const SurveyCard = ({ info }) => {
    const { name, createdAt } = info;

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const formattedDate = `${day}-${month}-${year}`;
        const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

        return `${formattedDate}, ${formattedTime}`;
    };

    return (
        <div
            className="flex h-full bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 flex-col gap-2"
        >
            <div className="flex">
                <div className="flex items-center w-full sm:w-max rounded-2xl">
                    <div className="p-2 mr-3 inline-flex items-center justify-center rounded-full bg-slate-700 text-white text-lg sm:text-2xl">
                        <BiUser />
                    </div>

                    <div className="flex flex-col">
                        <h2 className="text-gray-900 text-sm sm:text-lg first-letter:uppercase font-semibold">
                            {name}
                        </h2>
                        <p className='text-sm'>
                            {formatDateString(createdAt)}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SurveyCard