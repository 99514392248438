// import { BiCloudUpload } from "react-icons/bi";
import { BiSearch, BiUser } from "react-icons/bi";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { HiPencilAlt } from "react-icons/hi";
import toast from "react-hot-toast";
import { getVoters } from "../../../../store/extraReducers";

const UploadedVotersList = ({ setKeyword, /*getVotersFunc*/ }) => {
	const [searching, setSearching] = useState(false)

	const dispatch = useDispatch();

	// const fileInputRef = useRef(null);
	const skeletonCount = 12; // Adjust this as needed

	const { voters } = useSelector((state) => state.voters);

	const debounce = (func, wait) => {
		let timeout;
		return function (...args) {
			const context = this;
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(context, args), wait);
		};
	}

	// const handleFileButtonClick = () => {
	// 	fileInputRef.current.click();
	// };

	// const handleChange = (data) => {
	// 	toast.loading("Adding Voters...")
	// 	dispatch(addBulkVoters(data)).then(action => {
	// 		toast.dismiss()
	// 		fileInputRef.current.value = null;

	// 		if (action.payload.success) {
	// 			getVotersFunc(1, "")
	// 			toast.success("Voters added successfully")
	// 		} else {
	// 			toast.error(action.payload)
	// 		}
	// 	})
	// };


	const searchFunc = debounce((keyword) => {
		setKeyword(keyword)
		setSearching(true)

		dispatch(getVoters({ page: 1, keyword })).then(action => {
			setSearching(false)

			if (!action.payload.success) {
				toast.error(action.payload)
			}
		})
	}, 1000)

	return (
		<section className="container mt-14 mx-auto h-full">
			<div className="flex w-full">
				<h1 className="sm:text-3xl text-2xl font-semibold text-gray-900">
					Voter List
				</h1>

				<div className="flex items-center ml-auto gap-5">
					<div className="hidden lg:flex items-center ml-auto gap-5">
						<div className="flex xl:w-96 bg-slate-100 ring-1 ring-gray-300 rounded-full">
							<input
								className="w-full p-2 pl-5 focus:outline-none bg-transparent"
								type="text"
								name="search"
								aria-label="Search"
								placeholder="Search"
								onChange={(e) => searchFunc(e.target.value)}
							/>

							<button className="text-gray-400 p-2 px-5 rounded-full rounded-l-none text-xl">
								<BiSearch />
							</button>
						</div>
					</div>

					{/* <div>
						<button
							onClick={handleFileButtonClick}
							className="bg-teal-900 text-slate-50 px-4 py-2 rounded-full font-medium inline-flex items-center gap-1.5 text-sm lg:text-base"
						>
							<BiCloudUpload className="text-xl" /> Upload File
						</button>

						<input
							className="hidden"
							accept=".xlsx"
							ref={fileInputRef}
							type="file"
							name="myfile"
							onChange={(event) => handleChange(event.target.files[0])}
						/>
					</div> */}
				</div>
			</div>

			<div className="lg:hidden flex items-center ml-auto gap-5 w-full">
				<div className="flex bg-slate-100 ring-1 ring-gray-300 rounded-full w-full">
					<input
						className="w-full p-2 pl-5 focus:outline-none bg-transparent"
						type="text"
						name="search"
						aria-label="Search"
						placeholder="Search"
						onChange={(e) => searchFunc(e.target.value)}
					/>

					<button className="text-gray-50 bg-teal-900 p-2 px-5 rounded-full rounded-l-none text-xl">
						<BiSearch />
					</button>
				</div>
			</div>

			{
				searching ? (
					<div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5 py-10">
						{
							Array.from({ length: skeletonCount }).map((_, key) => (
								<div key={key} className="text-start">
									<div
										className={`bg-slate-100 flex h-full shadow-md ring-1 ring-slate-200 rounded-2xl p-2 md:p-2 gap-2`}
									>
										<div className="flex w-full">
											<div className="flex items-center w-full px-2 py-2 rounded-2xl">
												<div
													className={`bg-teal-900 p-2 mr-3 inline-flex items-center justify-center rounded-full text-white text-lg sm:text-2xl`}
												>
													<BiUser />
												</div>
												<div className="flex flex-col">
													<Skeleton animation="wave" width={100} height={18} />
													<Skeleton animation="wave" width={60} height={14} />
												</div>
											</div>
											<button
												className={`text-teal-900 ml-auto my-auto rounded-xl px-5 py-2 text-xl`}
												disabled
											>
												<HiPencilAlt />
											</button>
										</div>
									</div>
								</div>
							))}
					</div>
				) :
					voters?.length > 0 ?
						<div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 2xl:gap-5 py-10">
							{
								voters.map((voter, key) => {
									return (
										<div key={key} className="text-start">
											<div
												className={`${voter.surveyTaken ? "ring-emerald-300 bg-emerald-200" : "ring-slate-200 bg-slate-100"
													} flex h-full shadow-md ring-1 rounded-2xl p-2 md:p-2 gap-2`}
											>
												<div className="flex w-full">
													<div className="flex items-center w-full px-2 py-2 rounded-2xl">
														<div
															className={` ${voter.surveyTaken
																? "bg-emerald-600"
																: "bg-slate-500"
																} p-2 mr-3 inline-flex items-center justify-center rounded-full  text-white text-lg sm:text-2xl`}
														>
															<BiUser />
														</div>

														<div className="flex flex-col">
															<h2 className="text-gray-900 text-sm sm:text-base font-semibold">
																{voter.name}
															</h2>
															<p className="text-sm">{voter.voterId}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
						:
						<div
							className={`flex w-max h-full m-auto rounded-2xl gap-4 items-center justify-center text-gray-200 focus:outline-none lg:text-7xl px-8 xl:text-8xl font-black`}
						>
							No voters Found :/
						</div>
			}
		</section>
	);
};

export default UploadedVotersList