import { BiErrorCircle } from "react-icons/bi";
import { BiSearch, BiUser } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import VoterCard from "./VoterCard";
import { useState } from "react";
import toast from "react-hot-toast";
import { getCompletedVoters } from "../../../../../store/extraReducers";

const VotersList = ({ setKeyword }) => {
    const dispatch = useDispatch()
    const [searching, setSearching] = useState(false)

    const skeletonCount = 12; // Adjust this as needed

    const { voters } = useSelector((state) => state.voters);

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    const searchFunc = debounce((keyword) => {
        setKeyword(keyword)
        setSearching(true)

        dispatch(getCompletedVoters({ page: 1, keyword })).then(action => {
            setSearching(false)

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }, 1000)

    return (
        <section className="container mt-10 mx-auto h-full">
            <div className="flex flex-col sm:flex-row w-full gap-5">
                <h1 className="sm:text-3xl text-2xl font-semibold text-gray-900">
                    Voter List
                </h1>

                <div className="flex items-center sm:ml-auto gap-4">
                    <div className="flex w-full sm:w-96 bg-slate-100 ring-1 ring-gray-300 rounded-full">
                        <input
                            className="w-full p-2 pl-5 focus:outline-none bg-transparent"
                            type="text"
                            name="search"
                            aria-label="Search"
                            placeholder="Search"
                            onChange={(e) => searchFunc(e.target.value)}
                        />

                        <button className="text-gray-400 p-2 px-5 rounded-full rounded-l-none text-xl">
                            <BiSearch />
                        </button>
                    </div>
                </div>
            </div>

            {
                searching ? (
                    <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5 py-10">
                        {
                            Array.from({ length: skeletonCount }).map((_, key) => (
                                <div key={key} className="text-start">
                                    <div
                                        className={`bg-slate-100 flex h-full shadow-md ring-1 ring-slate-200 rounded-2xl p-2 md:p-2 gap-2`}
                                    >
                                        <div className="w-full">
                                            <div className="flex items-center w-full px-2 py-2 rounded-2xl">
                                                <div
                                                    className={`bg-slate-700 p-2 mr-3 inline-flex items-center justify-center rounded-full text-white text-lg sm:text-2xl`}
                                                >
                                                    <BiUser />
                                                </div>


                                                <div className="flex flex-col">
                                                    <Skeleton animation="wave" width={100} height={18} />
                                                    <Skeleton animation="wave" width={60} height={14} />
                                                </div>

                                            </div>

                                            <div className="flex flex-col mt-4">
                                                <Skeleton animation="wave" width={100} height={18} />
                                                <Skeleton animation="wave" width={150} height={18} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                ) :
                    voters.length > 0 ?
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 2xl:gap-5 py-10">
                            {
                                voters.map((voter, key) =>
                                    <VoterCard key={key} voter={voter} />
                                )}
                        </div>
                        :
                        <div
                            className="flex gap-2 w-max mt-32 m-auto rounded-2xl items-center justify-center text-gray-300 focus:outline-none text-3xl lg:text-7xl px-8 xl:text-8xl font-black"
                        >
                            No voters Found <BiErrorCircle />
                        </div>
            }
        </section>
    );
};

export default VotersList