import { BiEditAlt, BiUser } from "react-icons/bi";
import { HiOutlineUserRemove } from "react-icons/hi";
import { formateDataAndTime } from "../../../../../Utils/dateAndTimeFormatter";
import { Tooltip } from "@mui/material";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const AgentDetailsCard = ({ agent, getFormDatafunc, removeUserFunc, loading, anchorEl }) => {
    const progress = agent.surveysTaken

    return (
        <div
            className={`${progress >= 0 ? "bg-white" : "bg-orange-50"} flex h-full shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 flex-col gap-2 relative`}
        >
            <div className="flex">
                <div className="flex items-center w-full sm:w-max rounded-2xl">
                    <div className={`${progress >= 0 ? "bg-violet-700" : "bg-orange-600"} p-2 mr-3 inline-flex items-center justify-center rounded-full text-white text-lg sm:text-2xl`}>
                        <BiUser />
                    </div>

                    <div className="flex flex-col">
                        <span className="text-gray-900 text-sm sm:text-lg first-letter:uppercase font-bold">
                            {agent?.name || "Agent Name"}
                        </span>

                        <span className="text-sm">+91 {agent?.mobile || 1234567890}</span>
                    </div>
                </div>


                <div className="flex gap-2 ml-auto mb-auto">
                    <Tooltip title="Edit" arrow>
                        <button
                            id={agent?._id}
                            name="edit"
                            disabled={loading}
                            onClick={(e) => getFormDatafunc(e.currentTarget)}>
                            <div className="p-2 bg-orange-100 rounded-full text-lg text-orange-500">
                                {
                                    loading && (anchorEl?.id === agent._id && anchorEl?.name === "edit") ?
                                        <AiOutlineLoading3Quarters className="animate-spin" />
                                        :
                                        <BiEditAlt />
                                }
                            </div>
                        </button>
                    </Tooltip>

                    <Tooltip title="Delete" arrow>
                        <button
                            id={agent?._id}
                            name="del"
                            disabled={loading}
                            onClick={(e) => removeUserFunc(e.currentTarget)}
                        >
                            <div className="p-2 bg-red-100 rounded-full text-lg text-red-500">
                                {
                                    loading && anchorEl?.id === agent._id && anchorEl?.name === "del" ?
                                        <AiOutlineLoading3Quarters className="animate-spin" />
                                        :
                                        <HiOutlineUserRemove />
                                }
                            </div>
                        </button>
                    </Tooltip>
                </div>
            </div>

            {/* Agent Details */}
            <div className="flex flex-col flex-grow leading-relaxed">
                <p>
                    <span className="font-semibold">Polling Stations Assigned: </span>

                    <span>{agent?.assigned.pollingStations.join(", ")}</span>
                </p>

                <p>
                    <span className="font-semibold">Created At: </span>

                    <span>{formateDataAndTime(agent?.createdAt)}</span>
                </p>

                <p className="mt-5">
                    <span className="font-bold">Total Surveys Taken: {agent.surveysTaken} </span>
                </p>
            </div>
        </div>
    )
}

export default AgentDetailsCard