import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form"
import toast from "react-hot-toast";
import { DialogActions, Divider } from "@mui/material";
import { voterSurveyForm } from "../../../../../assets/data/forms";
import { addVoterSurvey } from "../../../../../store/extraReducers";
import CustomTextField from "../../../../../components/inputs/TextField";
import NumField from "../../../../../components/inputs/NumField";
import FreeField from "../../../../../components/inputs/FreeField";
import CustomSelect from "../../../../../components/inputs/Select";
import ChipSelect from "../../../../../components/inputs/ChipSelect";
import AutoComplete from "../../../../../components/inputs/AutoComplete";
import DependentSelect from "../../../../../components/inputs/DependentSelect";

const VoterForm = ({ formOpen, setFormOpen, getVotersFunc, rtc }) => {
    const dispatch = useDispatch();

    const { voter } = useSelector((store) => store.voters);

    const [loading, setLoading] = useState(false);

    const formHook = useForm({ mode: 'onSubmit' });

    const { handleSubmit, reset, watch } = formHook

    const { stopRecording, setFormData } = rtc

    const addSurveyFunc = async (formData) => {
        setLoading(true);
        try {
            const finalData = await setFormData(formData);

            dispatch(addVoterSurvey(finalData)).then(action => {
                setLoading(false);
                if (action.payload.success) {
                    setFormOpen(false);
                    getVotersFunc(1)
                    toast.success("Survey Details Added");
                } else {
                    toast.error(action.payload);
                }
            });
        } catch (error) {
            setLoading(false);
            toast.error("An error occurred while processing the recording");
        }
    };

    const onSubmit = (details) => {
        try {
            setLoading(true);

            const formData = new FormData();

            if ("geolocation" in navigator) {
                navigator?.geolocation?.getCurrentPosition(
                    (position) => {
                        const data = { id: voter._id, survey: details, location: { lat: position?.coords?.latitude, lng: position?.coords?.longitude } }

                        formData.append('data', JSON.stringify(data));

                        addSurveyFunc(formData);
                    },
                    () => {
                        toast.error("Geolocation is not available.", { duration: 4000 });
                        setLoading(false);
                    }
                );
            } else {
                toast.error("Geolocation is not available.");
            }
        } catch (err) {
            toast.error(err)
        }

    }

    const questions = voterSurveyForm(voter, watch())

    const { name, voterId, fathersName, mothersName, husbandsName, others, age, gender, houseNumber } = voter

    return (
        questions?.length > 0 ?
            <Dialog
                open={formOpen}
                onClose={() => {
                    setFormOpen(false)
                    stopRecording()
                    reset()
                }}
                PaperProps={{
                    style: {
                        width: "100%",
                        margin: "1rem",
                        borderRadius: "1rem",
                        padding: "1rem 0 0 0",
                    },
                }}
            >
                <DialogTitle className="border-b">
                    <p className="text-slate-800 font-bold first-letter:uppercase mulish">
                        Voter Survey Form
                    </p>
                </DialogTitle>

                <DialogContent>
                    <p className="flex items-center gap-1.5 my-2 font-semibold">
                        <span>Voter Details</span> <AiOutlineInfoCircle />
                    </p>

                    <div className="flex flex-col items-start text-sm first-letter:uppercase font-semibold mt-2 mb-3 bg-gray-100 rounded-xl p-4">

                        <p>
                            <span className='font-bold'>
                                Name:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {name}
                            </span>
                        </p>

                        <p>
                            <span className='font-bold'>
                                Voter ID:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {
                                    voterId
                                }
                            </span>
                        </p>

                        <p>
                            <span className='text-sm font-bold'>
                                {
                                    (fathersName && "Fathers") ||
                                    (mothersName && "Mothers") ||
                                    (husbandsName && "Husbands") ||
                                    (others && "Others")
                                } Name:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {
                                    fathersName || mothersName || husbandsName || others || "Not Available"
                                }
                            </span>
                        </p>

                        <p>
                            <span className='font-bold'>
                                Age:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {age}
                            </span>
                        </p>

                        <p>
                            <span className='font-bold'>
                                Gender:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {gender}
                            </span>
                        </p>

                        <p>
                            <span className='font-bold'>
                                House Number:
                            </span>
                            {" "}
                            <span className='text-gray-500'>
                                {
                                    houseNumber && houseNumber !== "" ?
                                        houseNumber : "Not available"
                                }
                            </span>
                        </p>
                    </div>

                    <form className="space-y-6 mt-6" onSubmit={handleSubmit(onSubmit)}>
                        {
                            questions.map((obj, key) => {
                                switch (obj.type) {
                                    case "TextField":
                                        return <CustomTextField key={key} formHook={formHook} fieldData={obj} />

                                    case "NumField":
                                        return <NumField key={key} formHook={formHook} fieldData={obj} />

                                    case "FreeField":
                                        return <FreeField key={key} formHook={formHook} fieldData={obj} />

                                    case "Select":
                                        return <CustomSelect key={key} formHook={formHook} fieldData={obj} />

                                    case "ChipSelect":
                                        return <ChipSelect key={key} formHook={formHook} fieldData={obj} />

                                    case "AutoComplete":
                                        return <AutoComplete key={key} formHook={formHook} fieldData={obj} />

                                    case "DependentSelect":
                                        return <DependentSelect key={key} formHook={formHook} fieldData={obj} />
                                }
                            })
                        }

                        <Divider className="pt-4" />
                        {/* Action buttons */}
                        <div className="flex gap-5">
                            <button
                                type="button"
                                onClick={() => {
                                    setFormOpen(false)
                                    stopRecording()
                                    reset()
                                }}
                                className="flex-1 w-full sm:w-auto justify-center rounded-md border border-slate-600 px-3 py-2 text-sm font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                            >
                                Cancel
                            </button>

                            <button
                                disabled={loading}
                                type="submit"
                                className="flex-1 flex w-full sm:w-auto justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
                            >
                                {
                                    loading ?
                                        <>
                                            Submitting {" "}
                                            <BiLoaderAlt className="animate-spin" />
                                        </> :
                                        "Submit"
                                }
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            :
            <Dialog
                open={formOpen}
                onClose={() => {
                    setFormOpen(false)
                }}
                PaperProps={{
                    style: {
                        width: "100%",
                        margin: "1rem",
                        borderRadius: "1rem",
                        padding: "1rem 0 0 0",
                    },
                }}
            >
                <DialogTitle className="flex">
                    <p className="text-slate-800 font-bold first-letter:uppercase mulish">
                        Add New Survey
                    </p>
                </DialogTitle>

                <DialogContent dividers>
                    <p className="text-slate-800 first-letter:uppercase mulish">
                        Ops, there are no questions for your survey! Please contact your admin.
                    </p>
                </DialogContent>

                <DialogActions>
                    <button
                        type="button"
                        onClick={() => {
                            setFormOpen(false)
                        }}
                        className="flex-1 flex sm:w-auto justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
                    >
                        Okay
                    </button>
                </DialogActions>
            </Dialog>
    )

};

export default VoterForm;
