import { processSurveyData } from "../../Utils/processSurveyData";

const Percentage = ({ labels, data, totalDocs }) => {
    const processedData = processSurveyData(labels, data);

    return (
        <div className={`my-2 grid grid-cols-2 xl:grid-cols-5`}>
            {
                processedData.map((obj, key) => {
                    return (
                        <div key={key} className='text-base'>
                            <p>
                                <span className='font-bold'>{key + 1}{")"} {obj.option}: </span>

                                {Math.round(obj.count / totalDocs * 100) || 0}%
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Percentage