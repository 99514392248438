import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import userSlice from "./slices/userSlice";
import analyticsSlice from "./slices/analyticsSlice";
import recordingSlice from "./slices/recordingSlice";
import sidebarSlice from "./slices/sidebarSlice";
import agentSlice from "./slices/agentSlice";
import surveySlice from "./slices/surveySlice";
import adminManagerSlice from "./slices/adminSlice";
import voterSlice from "./slices/voterSlice";
import constituencySlice from "./slices/constituencySlice";

export const store = configureStore({
	reducer: {
		user: userSlice,
		agents: agentSlice,
		admins: adminManagerSlice,
		voters: voterSlice,
		surveys: surveySlice,
		recordings: recordingSlice,
		analytics: analyticsSlice,
		constituency: constituencySlice,
		sidebar: sidebarSlice,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
