import { Skeleton } from "@mui/material";
import { FiRefreshCw } from 'react-icons/fi';
import Header from '../global/Header';

const AnalyticsSkl = () => {
    return (
        <section className="flex flex-col max-h-[100dvh]">
            <Header />

            <div className='overflow-y-scroll px-4 pt-6 h-full'>

                <h1 className='text-2xl font-bold flex justify-between items-center'>
                    🚀 Survey Analytics

                    <button className="p-2 bg-green-200 rounded-full flex gap-2 items-center">
                        <FiRefreshCw className={`$ text-lg`} /> <span className="text-sm">Refresh</span>
                    </button>
                </h1>

                <div className="bg-slate-100 border shadow-sm rounded-xl py-4 text-center mt-8 flex justify-center items-center">
                    <Skeleton className='mx-1' variant="text" width={240} height={30} />
                </div>

                <div className='my-8 grid xl:grid-cols-3 xl:flex-row gap-8 text-lg font-semibold'>
                    {
                        Array(6).fill().map((_, i) => {
                            return (
                                <div key={i} className='flex flex-col justify-between xl:border xl:p-4 xl:rounded-lg'>
                                    <div>
                                        <Skeleton className='mx-1' variant="text" width={"80%"} height={30} />

                                        <div className='flex mt-2'>
                                            <div className='w-full '>
                                                <Skeleton className='mx-1' variant="text" width={"70%"} height={20} />
                                                <Skeleton className='mx-1' variant="text" width={"30%"} height={20} />
                                            </div>

                                            <div className='w-full'>
                                                <Skeleton className='mx-1' variant="text" width={"50%"} height={20} />
                                                <Skeleton className='mx-1' variant="text" width={"40%"} height={20} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        <Skeleton className='my-5 rounded-xl shadow-md' variant="rectangular" width={"100%"} height={400} />

                                        <div className='flex bg-slate-100 w-full p-8 rounded-xl'>
                                            <Skeleton className='rounded-xl shadow-md m-auto' variant="circular" width={"35dvh"} height={"35dvh"} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default AnalyticsSkl