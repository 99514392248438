import { useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux";
import { RiUserAddLine } from "react-icons/ri";
import { getAgentForm, getAgents, removeAgent } from "../../../../../store/extraReducers";
import toast from "react-hot-toast";
import AgentForm from "./AgentForm";
import AgentDetailsCard from "./AgentDetailsCard";

const AgentList = () => {
    const dispatch = useDispatch();

    const { agents } = useSelector((store) => store.agents);

    const [loading, setLoading] = useState(false)
    const [formOpen, setFormOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const getFormDatafunc = (e) => {
        setLoading(true)
        setAnchorEl(e)
        !e?.id && toast.loading("Genrating Agent Form")

        dispatch(getAgentForm(e?.id)).then(action => {
            setLoading(false)
            toast.dismiss()

            if (action.payload?.success) {
                setFormOpen(true);
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    const removeUserFunc = (e) => {
        setLoading(true)
        setAnchorEl(e)

        toast.loading("Updating List")

        dispatch(removeAgent(e.id)).then(action => {
            toast.dismiss()

            setLoading(false)

            if (action.payload.success) {
                refresh()
                const name = action.payload.removedUser.name
                toast.success(`Agent - ${name}, was  removed`)
            } else {
                toast.error(action.payload)
            }
        })
    }

    const refresh = (e) => {
        setLoading(true)
        setAnchorEl(e)

        dispatch(getAgents()).then(action => {
            setLoading(false)

            if (!action.payload.success) {
                toast.error(action.payload)
            }
        })
    }

    return (
        <div className="flex flex-col p-4 sm:p-8 h-full overflow-y-scroll">
            <div className="flex justify-between items-center">
                <h1 className='text-2xl font-bold'>
                    Agent Manager
                </h1>

                <button id="refresh"
                    onClick={(e) => refresh(e.currentTarget)}
                    disabled={loading && anchorEl?.id === "refresh"}
                    className="p-2 px-4 bg-violet-200 text-violet-900 font-bold rounded-full flex gap-2 items-center"
                >
                    <FiRefreshCw className={`${loading && anchorEl?.id === "refresh" && "animate-spin"} text-lg`} /> <span className="text-sm">Refresh</span>
                </button>
            </div>

            <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5 py-8">
                <button
                    onClick={() => getFormDatafunc()}
                    className={`flex w-full h-full lg:min-h-[14rem] shadow-md border-2 border-dashed border-slate-500 rounded-2xl gap-2 bg-slate-50 items-center justify-center text-slate-500 p-2 focus:outline-none xl:h-58`}
                >
                    <div
                        className={`p-2 mr-3 inline-flex items-center justify-center rounded-full text-lg sm:text-2xl`}
                    >
                        <RiUserAddLine />
                    </div>
                    <div className="font-semibold">Add New Agent</div>
                </button>

                {
                    agents.map((agent, key) => {
                        return (
                            <AgentDetailsCard key={key} agent={agent} getFormDatafunc={getFormDatafunc} removeUserFunc={removeUserFunc} loading={loading} anchorEl={anchorEl} refresh={refresh} />
                        );
                    })
                }
            </div>

            <AgentForm formOpen={formOpen} setFormOpen={setFormOpen} refresh={refresh} />
        </div>
    )
}

export default AgentList