import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { BiLoaderAlt } from "react-icons/bi"
import CustomTextField from "../../../../../components/inputs/TextField"
import NumField from "../../../../../components/inputs/NumField"
import FreeField from "../../../../../components/inputs/FreeField"
import CustomSelect from "../../../../../components/inputs/Select"
import ChipSelect from "../../../../../components/inputs/ChipSelect"
import AutoComplete from "../../../../../components/inputs/AutoComplete"
import DependentSelect from "../../../../../components/inputs/DependentSelect"
import { adminForm } from "../../../../../assets/data/forms"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { addAdmin, editAdmin } from "../../../../../store/extraReducers"
import toast from "react-hot-toast"

const AdminForm = ({ setFormOpen, formOpen }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const { formData } = useSelector((state) => {
        return state.admins
    })

    const formHook = useForm({
        mode: 'onSubmit'
    })

    const { handleSubmit, reset } = formHook

    const questions = adminForm(formData)

    const onSubmit = (data) => {
        setLoading(true)

        const { constituencies, ...other } = data
        data = { ...other, assigned: { constituencies } }

        if (!formData?.user) {

            dispatch(addAdmin(data)).then(action => {
                setLoading(false)

                if (action.payload.success) {
                    setFormOpen(false)
                } else {
                    toast.error(action.payload)
                }
            })
        } else {
            data.id = formData.user._id

            dispatch(editAdmin(data)).then(action => {
                setLoading(false)

                if (action.payload.success) {
                    setFormOpen(false)
                } else {
                    toast.error(action.payload)
                }
            })
        }

    }

    useEffect(() => {
        reset()
    }, [formOpen, reset])

    return (
        <Dialog
            open={formOpen}
            onClose={() => {
                setFormOpen(false)
            }}
            PaperProps={{
                style: {
                    width: "100%",
                    margin: "1rem",
                    borderRadius: "1rem",
                    padding: "1rem 0 0 0",
                },
            }}
        >
            <DialogTitle className="flex">
                <p className="text-slate-800 font-bold first-letter:uppercase mulish">
                    {
                        formData?.user ? "Edit Admin Details" :
                            "Add New Admin"
                    }
                </p>
            </DialogTitle>

            <DialogContent dividers>
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        questions.map((obj, key) => {
                            switch (obj.type) {
                                case "TextField":
                                    return <CustomTextField key={key} formHook={formHook} fieldData={obj} />

                                case "NumField":
                                    return <NumField key={key} formHook={formHook} fieldData={obj} />

                                case "FreeField":
                                    return <FreeField key={key} formHook={formHook} fieldData={obj} />

                                case "Select":
                                    return <CustomSelect key={key} formHook={formHook} fieldData={obj} />

                                case "ChipSelect":
                                    return <ChipSelect key={key} formHook={formHook} fieldData={obj} />

                                case "AutoComplete":
                                    return <AutoComplete key={key} formHook={formHook} fieldData={obj} />

                                case "DependentSelect":
                                    return <DependentSelect key={key} formHook={formHook} fieldData={obj} />
                            }
                        })
                    }

                    {/* Action buttons */}
                    <div className="flex gap-5 pt-4 border-t">
                        <button
                            disabled={loading}
                            type="button"
                            onClick={() => {
                                setFormOpen(false)
                            }}
                            className="flex-1 w-full sm:w-auto justify-center rounded-md border border-slate-600 px-3 py-2 text-sm font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                            Cancel
                        </button>

                        <button
                            disabled={loading}
                            type="submit"
                            className="flex-1 flex w-full sm:w-auto justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 items-center gap-2"
                        >
                            {
                                loading ?
                                    <>
                                        {
                                            formData?.user ? "Editing" :
                                                "Creating"
                                        }{" "}
                                        <BiLoaderAlt className="animate-spin" />
                                    </>
                                    :
                                    <>
                                        {
                                            formData?.user ? "Edit" :
                                                "Create"
                                        }
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AdminForm