import { Skeleton } from "@mui/material";
import { RiUserAddLine } from 'react-icons/ri';
import { BiDotsVerticalRounded } from 'react-icons/bi';

const SurveyDetailsSkl = () => {
    return (
        <section className="container mx-auto relative">
            <h1 className="sm:text-3xl text-2xl font-semibold text-gray-900 mb-10">
                Survey List
            </h1>

            <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5">
                <button
                    disabled
                    className={`flex w-full h-full shadow-md border-2 border-dashed border-slate-500 rounded-2xl gap-2 bg-slate-50 items-center justify-center text-slate-500 p-2 focus:outline-none`}
                >
                    <div
                        className={`p-2 mr-3 inline-flex items-center justify-center rounded-full text-lg sm:text-2xl`}
                    >
                        <RiUserAddLine />
                    </div>
                    <div className="font-semibold">Add New Survey</div>
                </button>

                {
                    Array(8).fill().map((i, key) => (
                        <div key={key}>
                            <div className="flex h-full bg-white shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 gap-2 items-center">
                                <div className="flex gap-2">
                                    <Skeleton variant="circular" width={40} height={40} />

                                    <div className="flex flex-col">
                                        <Skeleton variant="text" width={120} height={20} />
                                        <Skeleton variant="text" width={80} height={16} />
                                    </div>
                                </div>

                                <div className='ml-auto text-xl'>
                                    <BiDotsVerticalRounded />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </section>
    );
}

export default SurveyDetailsSkl