import { Skeleton } from '@mui/material'

const RecordingListSkl = () => {
    return (
        < div className='p-4 flex flex-col gap-1 bg-gray-100 rounded-2xl'>
            <Skeleton variant="text" width={"90%"} height={30} />
            <Skeleton variant="text" width={"60%"} height={30} />
            <Skeleton variant="rounded" className='mt-2' width={"90%"} height={40} />
        </div>
    )
}

export default RecordingListSkl