import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

const AutoComplete = ({ fieldData, formHook }) => {
    const { register, setValue: setFormValue, formState: { errors }, clearErrors, unregister } = formHook;

    const { id, options, question, defaultValue, errorMsg } = fieldData;

    const [value, setValue] = useState(defaultValue)

    const handleChange = (_, newValue) => {
        setValue(newValue);

        if (newValue && newValue !== "Select") {
            clearErrors(id)
        }

        if (!(id in errors)) {
            setFormValue(id, newValue, { shouldDirty: true });
        }
    };

    useEffect(() => {
        register(id, {
            validate: () => (value && value !== "Select") || errorMsg
        });
    }, [id, value, register, errorMsg]);

    useEffect(() => {
        return () => {
            unregister(id); // Unregister the field when the component is unmounted
        };
    }, [id, unregister])

    return (
        <div>
            <label
                htmlFor={id}
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {question}
            </label>

            <div className="mt-2">
                <Autocomplete
                    ListboxProps={{
                        sx: {
                            maxHeight: "200px",
                        },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            minHeight: "50px",
                            padding: "5px 0 5px 10px",
                        },
                        '& .MuiAutocomplete-popper': {
                            marginTop: '10px', // Adjust this value as needed
                        },
                        '& .MuiAutocomplete-option': {
                            height: '50px', // Increase the height of each option
                        },
                        "& .MuiAutocomplete-option:hover": {
                            backgroundColor: "#f5f3ff", // Hover background color
                        },
                        borderRadius: '8px',
                        outline: 'none',
                        border: '2px solid rgb(209 213 219)',
                    }}
                    options={options}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                            <MenuItem
                                key={key}
                                disabled={option?.available || false}
                                {...optionProps}
                                sx={{
                                }}
                            >
                                {option}
                            </MenuItem>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                height: '50px',
                                '& fieldset': { border: 'none' },
                            }}
                            placeholder='Select'
                            inputProps={{
                                ...params.inputProps,
                            }}
                            error={id in errors}
                            helperText={id in errors && (errorMsg ?? "Please select an option")}
                        />
                    )}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default AutoComplete;
