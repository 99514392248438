import { useSelector } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
import React from "react"
import PageNotFound from "../../PageNotFound"
import Login from "./Login"

const ProtectedRoutes = ({ children }) => {
    const { userDetails, isAuthenticated } = useSelector((state) => {
        return state.user
    })

    return (
        <Routes>
            <Route path='/login' element={<Login />} />

            {React.Children.map(children, (child, index) => {
                return (
                    <Route key={index} path={child.props.path} element={
                        (isAuthenticated && child.props.roles.includes(userDetails?.role)) ? child.props.element : <Navigate to='/login' />
                    } />
                )
            }
            )}

            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
}

export default ProtectedRoutes