import { createSlice } from "@reduxjs/toolkit";
import {
	getBasicSurveyRecordings,
	getVoterSurveyRecordings,
} from "../extraReducers";

const recordingSlice = createSlice({
	name: "analytics",
	initialState: {
		data: {},
		recordings: [],
		status: "idle",
		error: "",
	},
	reducers: {
		clearRecordings(state) {
			state.recordings = [];
			state.data = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getVoterSurveyRecordings.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getVoterSurveyRecordings.fulfilled, (state, action) => {
				state.status = action.payload.success;

				const { data: newRecordings, ...remainingInfo } = action.payload;

				state.recordings =
					remainingInfo.currentPage !== 1
						? [...state.recordings, ...newRecordings]
						: [...newRecordings];

				state.data = remainingInfo;
			})
			.addCase(getVoterSurveyRecordings.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})

			.addCase(getBasicSurveyRecordings.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getBasicSurveyRecordings.fulfilled, (state, action) => {
				state.status = action.payload.success;

				const { data: newRecordings, ...remainingInfo } = action.payload;

				state.recordings =
					remainingInfo.currentPage !== 1
						? [...state.recordings, ...newRecordings]
						: [...newRecordings];

				state.data = remainingInfo;
			})
			.addCase(getBasicSurveyRecordings.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const { clearRecordings } = recordingSlice.actions;
export default recordingSlice.reducer;
