import { Skeleton } from "@mui/material";
import { FiRefreshCw } from "react-icons/fi";
import { RiUserAddLine } from "react-icons/ri";

const AgentListSkl = () => {
    // Create an array to mimic the loading state with a certain number of items
    const skeletonCount = 11; // Adjust this as needed

    return (
        <section className="flex flex-col p-4 sm:p-8 h-full">
            <div className="flex justify-between items-center">
                <h1 className='text-2xl font-bold'>
                    Agent Manager
                </h1>

                <button id="refresh" disabled className="p-2 px-4 bg-violet-200 text-violet-900 font-bold rounded-full flex gap-2 items-center">
                    <FiRefreshCw className="text-lg" /> <span className="text-sm">Refresh</span>
                </button>
            </div>

            <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-5 py-8">
                <button
                    disabled
                    className={`flex w-full max-h-full lg:h-42 shadow-md border-2 border-dashed border-slate-500 rounded-2xl gap-2 bg-slate-50 items-center justify-center text-slate-500 p-2 focus:outline-none`}
                >
                    <div
                        className={`p-2 mr-3 inline-flex items-center justify-center rounded-full text-lg sm:text-2xl`}
                    >
                        <RiUserAddLine />
                    </div>
                    <div className="font-semibold">Add New Agent</div>
                </button>

                {Array.from({ length: skeletonCount }).map((_, key) => (
                    <div key={key} className="text-start">
                        <div className="flex h-full bg-slate-50 shadow-md ring-1 ring-slate-200 rounded-2xl p-3.5 md:p-5 flex-col">
                            <div className="flex">
                                <div className="flex items-center mb-3 w-full sm:w-maxrounded-2xl">
                                    <div className="p-2 mr-3 inline-flex items-center justify-center rounded-full bg-slate-700 text-white text-lg sm:text-2xl">
                                        <Skeleton
                                            variant="circular"
                                            width={32}
                                            height={32}
                                            animation="wave"
                                        />
                                    </div>

                                    <div className="flex flex-col w-40">
                                        <Skeleton width="80%" height={16} animation="wave" />
                                        <Skeleton width="60%" height={14} animation="wave" />
                                    </div>
                                </div>

                                <div className="ml-auto">
                                    <Skeleton
                                        variant="rounded"
                                        width={12}
                                        height={30}
                                        animation="wave"
                                    />
                                </div>
                            </div>

                            <div className="flex-grow leading-relaxed">
                                <Skeleton width="60%" height={24} animation="wave" />
                                <Skeleton width="80%" height={24} animation="wave" />
                                <Skeleton width="80%" height={24} animation="wave" />
                            </div>

                            <div className="flex-grow leading-relaxed mt-4">
                                <Skeleton width="60%" height={34} animation="wave" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default AgentListSkl