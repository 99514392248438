import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../assets/images/png/logo.png"
import { getOtp, loginUser } from '../../store/extraReducers';
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import vector from "../../assets/images/svg/vector.svg"
import vector2 from "../../assets/images/svg/vector-2.svg"

const Login = () => {
    const [isTyping, setIsTyping] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(null);

    const { status, isAuthenticated } =
        useSelector((state) => {
            return state.user;
        });

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const loginUserFunc = (creds) => {
        setLoading(true)
        dispatch(loginUser(creds)).then(action => {
            setLoading(false)

            if (action.payload.success) {
                Cookies.set("token", action.payload.token, { expires: 7 })
                toast(`Hi ${action.payload.user.name}, welcome to Open Survey 🚀`)
            } else toast.error(action.payload)
        })
    }

    const getOtpFunc = (phoneNumber) => {
        setLoading(true)

        dispatch(getOtp(phoneNumber)).then(action => {
            setLoading(false)

            if (action.payload.success) {
                toast.success(action.payload.message)
                setOtpSent(true)
                setIsTyping(false)
            } else {
                toast.error(action.payload)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const phoneNumber = formData?.get("phoneNumber");
        const otp = parseInt(formData?.get("otp"));
        setMobile(phoneNumber)

        otpSent ? loginUserFunc({ mobile: phoneNumber, otp }) : getOtpFunc(phoneNumber)
    };

    const handleResend = () => {
        dispatch(getOtp(mobile)).then(action => {
            if (action.payload.success) toast.success(action.payload.message)
            else toast.error(action.payload)
        })
    }

    // useEffect(() => {

    // }, [])

    useEffect(() => {
        isTyping && setOtpSent(false)
    }, [isTyping])

    useEffect(() => {
        isAuthenticated && navigate('/')
    }, [navigate, isAuthenticated])

    return (
        <section className="h-screen relative bg-gray-50">
            <header className='flex py-3 border-b-2 border-b-slate-200 px-5 rounded-b-3xl sm:rounded-b-xl shadow-lg z-10 w-full h-20 fixed top-0 bg-white/70 backdrop-blur-sm'>
                <div className='w-36 m-auto'>
                    <img src={logo} alt="logo" />
                </div>
            </header>

            <div className="flex flex-col xl:justify-center items-center gap-10 m-auto h-full">
                <div className="absolute top-0 left-0 -translate-y-[12%] -translate-x-1/3">
                    <img src={vector} alt="" />
                </div>

                <div className="absolute bottom-0 right-0 translate-y-[30%] translate-x-1/4 rotate-6">
                    <img src={vector2} alt="" />
                </div>

                <div className="mt-auto mb-32 xl:m-0 z-10 backdrop-blur-sm p-8 xl:p-12 bg-whit/70 bg-white/20 xl:bg-slate-100 xl:border rounded-2xl shadow-lg">
                    <form onSubmit={(e) => handleSubmit(e)} className='flex flex-col text-center'>
                        <div>
                            <h1 className="text-gray-800 poppins font-semibold text-2xl mb-1">
                                Welcome to <span className="font-bold">Open Survey</span>
                            </h1>

                            <p className="text-sm font-normal text-gray-600 mb-7">
                                Let&apos;s get you in quick
                            </p>

                            <div className="flex items-center px-3 rounded-xl mb-4 bg-gray-50 border">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clipRule="evenodd" />
                                </svg>

                                <input name="phoneNumber" disabled={status === "loading"} required autoComplete='true' className="pl-2 py-3 outline-none border-none w-full bg-slate-50" type="tel" minLength={10} maxLength={10} placeholder="Enter Phone Number" onChange={() => setIsTyping(true)} />
                            </div>
                        </div>

                        {
                            otpSent &&
                            <>
                                <div className="flex items-center px-3 rounded-xl bg-slate-50 border">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fillRule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clipRule="evenodd" />
                                    </svg>

                                    <input name="otp" autoComplete="one-time-code" type="text" inputMode="numeric" disabled={status === "loading"} autoFocus required className="pl-2 py-3 bg-slate-50 outline-none border-none w-full" placeholder="Enter OTP" minLength={6} maxLength={6} />
                                </div>

                                <button type='button' className="ml-auto text-sm text-blue-900 my-2 font-semibold" onClick={handleResend}>
                                    Resend OTP
                                </button>
                            </>
                        }


                        {
                            otpSent ?

                                <button type="submit" disabled={status === "loading"} className="flex items-center justify-center gap-2 w-full bg-blue-900 mt-2 py-3 rounded-xl text-white font-semibold mb-2">
                                    {
                                        loading ?
                                            <AiOutlineLoading3Quarters className="animate-spin my-1" /> : "Sign in"
                                    }
                                </button>

                                :

                                <button type="submit" disabled={status === "loading"} className="flex items-center justify-center gap-2 w-full bg-blue-900 mt-2 py-3 rounded-xl text-white font-semibold mb-2">
                                    {
                                        loading ?
                                            <AiOutlineLoading3Quarters className="animate-spin my-1" /> : "Get OTP"
                                    }
                                </button>
                        }
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Login